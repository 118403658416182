import { createApp } from 'vue';
import { sync } from 'vuex-router-sync';
import '@/styles/core/config.scss';

import AuthDataProvider from '@/app/data-flow/auth/AuthDataProvider';
import {TOKEN_KEY} from '@/app/core/constants';
import App from './app/App.vue';
import './registerServiceWorker';
import store from './app/data-flow';
import router from './app/router';
import '@/app/core/interceptors';

createApp(App)
  .use(router)
  .use(store)
  .mount('#app');

sync(store, router, { moduleName: 'RouteModule' });

const auth = new AuthDataProvider();

auth.setupToken(localStorage.getItem(TOKEN_KEY) || '');
