import axios from 'axios';

import {BASE_URL} from '@/app/core/constants';
import store from '../data-flow';

const HTTP = axios.create({
  baseURL: BASE_URL,
});

HTTP.interceptors.response.use((response) => response, async (error) => {
  if (error.response.status === 401) {
    const action = 'AuthModule/logout';
    await store.dispatch(action).then(() => console.error('message', error.response.message));
  }
  return Promise.reject(error);
});
