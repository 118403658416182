import {State} from '@/app/data-flow/incident/state';
import {IncidentListItem} from '@/app/core/interfaces';
import * as _ from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const mutations = {
  loadIncidentCurrent(state: State) {
    state.loading = true;
    state.error = undefined;
  },
  loadIncidentCurrentSuccess(state: State, payload: {data: IncidentListItem}) {
    state.loading = false;
    state.error = undefined;
    if (!_.isEqual(state.incidentCurrent, payload.data)) {
      state.incidentCurrent = payload.data;
    }
  },
  loadIncidentCurrentFail(state: State, payload: {error: Error}) {
    state.loading = false;
    state.error = payload.error;
    state.incidentCurrent = undefined;
  },
};
