
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

export default defineComponent({
  name: 'fire-filter',
  props: {
    data: {
      type: String,
    },
  },
  components: {
    RuiIcon,
  },
});
