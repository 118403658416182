<template>
  <div class="fire-note__title">History</div>

  <div class="fire-note"
       v-for="(item, index) in data"
       :key="index">
    <div class="fire-note__head">
      <h3>
        <span>{{ item.subject }}</span>
        <rui-date :text="item.date"/>
      </h3>
      <rui-button :buttonType="buttonTypeIcon"
                  @clickFromButtonComponent="onClick(item)">
        <template v-slot:icon>
          <rui-icon icon="delete"/>
        </template>
      </rui-button>
    </div>
    <p>{{ item.description }}</p>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import RuiDate from '@/retinus-ui/components/date/RuiDate.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

import {NoteListItem} from '@/app/core/interfaces';
import {BUTTON_TYPE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'fire-note-list',
  emits: ['deleteNoteItem'],
  components: {
    RuiDate,
    RuiButton,
    RuiIcon,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      buttonTypeIcon: BUTTON_TYPE.icon,
    };
  },
  methods: {
    onClick(value: NoteListItem) {
      this.$emit('deleteNoteItem', value);
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'note-list';
</style>
