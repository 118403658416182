
import {defineComponent} from 'vue';

import RuiSpinner from '@/retinus-ui/components/spinner/RuiSpinner.vue';
import RuiBackdrop from '@/retinus-ui/components/backdrop/RuiBackdrop.vue';

export default defineComponent({
  name: 'rui-spinner-wrapper',
  components: {
    RuiSpinner,
    RuiBackdrop,
  },
  props: {
    size: {
      type: String,
      default: '68',
    },
    text: {
      type: String,
    },
  },
});
