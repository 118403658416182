
import {defineComponent} from 'vue';
import RuiInput from '@/retinus-ui/components/input/RuiInput.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiAuthWrapper from '@/retinus-ui/components/auth/RuiAuthWrapper.vue';

import {ALERT_TEXT, AUTH_TEXT, BUTTON_TEXT, PLACEHOLDER_TEXT} from '@/app/core/constants';

import {BUTTON_TYPE, VALIDATE_HINT} from '@/retinus-ui/core/constants';

import {mapActions} from 'vuex';
import {AuthModuleKey} from '@/app/data-flow/auth';

import RuiAlert from '@/retinus-ui/components/alert/RuiAlert.vue';
import Validator from '@/retinus-ui/core/validator';

export default defineComponent({
  name: 'forgot-password',
  components: {
    RuiButton,
    RuiInput,
    RuiAlert,
    RuiAuthWrapper,
  },
  data() {
    return {
      email: '',
      alert: ALERT_TEXT,
      hint: VALIDATE_HINT,
      btnColor: BUTTON_TYPE.primary,
      toLogin: AUTH_TEXT.returnToLogin,
      title: AUTH_TEXT.forgotPassword,
      label: PLACEHOLDER_TEXT,
      buttonText: BUTTON_TEXT.submit,
      formVisible: true,
      invalidField: false,
    };
  },
  methods: {
    ...mapActions(AuthModuleKey, ['resetPassword']),

    changeEmail(value: string) {
      this.invalidField = false;
      this.email = value;
    },

    submitForm() {
      if (!Validator.emailValid(this.email)) {
        this.invalidField = true;
        return;
      }

      this.resetPassword({email: this.email}).then(() => {
        this.formVisible = false;
        this.invalidField = false;
      });
    },
  },
});
