
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'rui-spinner',
  props: {
    size: {
      type: String,
      default: '40',
    },
  },
});
