<template>
  <path
    d="M9.52832 13.2331C9.90947 13.3371 10.2906 13.4063 10.6718 13.4341C10.6582 13.3856 10.6514 13.3371 10.6514 13.2816C10.6514 13.053 10.7807 12.859 10.9781 12.762V10.5518H10.3315V11.1892L9.68486 11.8405V12.1453C9.87544 12.2354 10.0116 12.4363 10.0116 12.665C10.0048 12.9491 9.79376 13.1846 9.52832 13.2331Z"
    fill="#71B2D9"/>
  <path
    d="M12.7546 12.1453V11.8405L12.108 11.1892V10.5518H11.4614V12.762C11.652 12.8521 11.7881 13.053 11.7881 13.2816C11.7881 13.3301 11.7813 13.3786 11.7677 13.4271C12.1557 13.3925 12.53 13.3232 12.8975 13.2262C12.6321 13.1777 12.4347 12.9421 12.4347 12.6581C12.4279 12.4363 12.564 12.2354 12.7546 12.1453Z"
    fill="#71B2D9"/>
  <path
    d="M12.9247 2.17465C12.5368 2.07073 12.1488 2.00144 11.7609 1.9668C11.7745 2.0153 11.7813 2.0638 11.7813 2.11923C11.7813 2.34787 11.652 2.54187 11.4546 2.63887V4.95995H12.1012V4.21859L12.7478 3.5673V3.26244C12.5572 3.17237 12.4211 2.97144 12.4211 2.7428C12.4279 2.4518 12.6457 2.2093 12.9247 2.17465Z"
    fill="#71B2D9"/>
  <path
    d="M9.67803 3.26137V3.56623L10.3246 4.21752V4.95888H10.9712V2.6378C10.7806 2.54773 10.6445 2.3468 10.6445 2.11816C10.6445 2.06966 10.6513 2.02116 10.6649 1.97266C10.2702 2.0073 9.88902 2.07659 9.52148 2.18051C9.79374 2.22209 9.99792 2.45766 9.99792 2.74866C10.0047 2.97037 9.8686 3.16437 9.67803 3.26137Z"
    fill="#71B2D9"/>
  <path
    d="M19.5676 7.39126C17.5122 4.70296 15.4294 2.99852 13.3535 2.30566C13.4761 2.40959 13.5577 2.56895 13.5577 2.74909C13.5577 2.97774 13.4284 3.17174 13.231 3.26874V3.78146L12.5844 4.43274V4.98703C13.2515 5.11175 13.7823 5.63139 13.9185 6.30347H14.7352L15.375 5.64525H15.8787C15.9671 5.45125 16.1645 5.31268 16.3891 5.31268C16.7022 5.31268 16.954 5.56904 16.954 5.88775C16.954 6.20647 16.7022 6.46283 16.3891 6.46283C16.1645 6.46283 15.9739 6.33118 15.8787 6.13025H15.5792L14.9394 6.78847H13.9525V7.44669H17.3965C17.4849 7.25269 17.6823 7.11411 17.9069 7.11411C18.22 7.11411 18.4718 7.37047 18.4718 7.68919C18.4718 8.0079 18.22 8.26426 17.9069 8.26426C17.6823 8.26426 17.4917 8.13262 17.3965 7.93169H13.9525V8.58991H14.9394L15.5792 9.24812H15.8787C15.9671 9.05412 16.1645 8.91555 16.3891 8.91555C16.7022 8.91555 16.954 9.17191 16.954 9.49062C16.954 9.80934 16.7022 10.0657 16.3891 10.0657C16.1645 10.0657 15.9739 9.93405 15.8787 9.73313H15.375L14.7352 9.07491H13.9321C13.83 9.79548 13.2787 10.3706 12.5776 10.5022V10.9595L13.2242 11.6108V12.1235C13.4148 12.2136 13.5509 12.4145 13.5509 12.6431C13.5509 12.8094 13.4829 12.9549 13.374 13.0589C15.9059 12.1859 18.0022 9.92713 19.7786 7.67533C19.7174 7.58526 19.6425 7.48826 19.5676 7.39126Z"
    fill="#71B2D9"/>
  <path
    d="M8.87516 12.6639C8.87516 12.4352 9.00448 12.2412 9.20186 12.1442V11.6384L9.84846 10.9871V10.5298C9.14741 10.3982 8.59611 9.82313 8.49401 9.10255H7.69087L7.05109 9.76077H6.54742C6.45894 9.95477 6.26156 10.0933 6.03695 10.0933C5.72386 10.0933 5.47203 9.83699 5.47203 9.51827C5.47203 9.19955 5.72386 8.9432 6.03695 8.9432C6.26156 8.9432 6.45213 9.07484 6.54742 9.27577H6.8469L7.48669 8.61755H8.47359V7.95933H5.02282C4.93434 8.15334 4.73696 8.29191 4.51235 8.29191C4.19926 8.29191 3.94743 8.03555 3.94743 7.71683C3.94743 7.39812 4.19926 7.14176 4.51235 7.14176C4.73696 7.14176 4.92753 7.2734 5.02282 7.47433H8.46679V6.81612H7.48669L6.8469 6.1579H6.54742C6.45894 6.3519 6.26156 6.49047 6.03695 6.49047C5.72386 6.49047 5.47203 6.23411 5.47203 5.9154C5.47203 5.59668 5.72386 5.34032 6.03695 5.34032C6.26156 5.34032 6.45213 5.47197 6.54742 5.6729H7.05109L7.69087 6.33111H8.50763C8.65056 5.65904 9.18145 5.13939 9.84165 5.01468V4.45346L9.19506 3.80217V3.28946C9.00448 3.19939 8.86836 2.99846 8.86836 2.76981C8.86836 2.60353 8.94323 2.4511 9.05213 2.34717C6.527 3.18553 4.41706 5.44425 2.64062 7.70991C2.71549 7.81383 2.79036 7.91083 2.86523 8.01476C4.91392 10.6961 6.99663 12.4006 9.06574 13.1004C8.95003 12.9895 8.87516 12.8371 8.87516 12.6639Z"
    fill="#71B2D9"/>
  <path
    d="M13.4832 8.85361V6.65724C13.4832 5.99209 12.9523 5.45166 12.2989 5.45166H10.1413C9.48792 5.45166 8.95703 5.99209 8.95703 6.65724V8.85361C8.95703 9.51875 9.48792 10.0592 10.1413 10.0592H12.2989C12.9455 10.0592 13.4832 9.51875 13.4832 8.85361ZM12.9115 8.85361C12.9115 9.26239 12.6733 9.48411 12.2921 9.48411H10.1413C9.76698 9.48411 9.52195 9.23468 9.52195 8.85361V6.65724C9.52195 6.24845 9.76017 6.02673 10.1413 6.02673H12.2989C12.6733 6.02673 12.9183 6.27616 12.9183 6.65724V8.85361H12.9115Z"
    fill="#71B2D9"/>
  <path
    d="M22.3246 7.44132C22.1952 7.2681 22.0659 7.10182 21.9366 6.9286L21.8753 6.85239C18.4654 2.18944 15.0827 0.0138572 11.244 0H11.2303C11.2167 0 11.2031 0 11.1895 0H11.1214C6.5136 0.0554288 2.98795 3.78302 0.136125 7.43439L0.00680627 7.60068H0L0.265444 7.96096C0.387957 8.13418 0.517276 8.29354 0.639789 8.45982L0.707852 8.5499C4.1246 13.2128 7.50731 15.3884 11.346 15.4023H11.3597C11.3733 15.4023 11.3869 15.4023 11.4005 15.4023H11.4686C16.0696 15.3469 19.602 11.6193 22.4539 7.96789L22.5832 7.80161H22.59L22.3246 7.44132ZM21.4329 7.68382C18.7445 11.0858 15.4366 14.5362 11.2099 14.5362C7.89527 14.5362 4.53297 12.3329 1.21152 8.00254L1.10942 7.87089C1.07539 7.82239 1.03455 7.76696 1.00052 7.71846C3.689 4.31652 6.99684 0.866075 11.2235 0.866075C14.5382 0.866075 17.9005 3.06937 21.2151 7.39975L21.3104 7.53139C21.3581 7.58682 21.3921 7.63532 21.4329 7.68382Z"
    fill="#71B2D9"/>
</template>
