<template>
  <button class="fire-button-back"
          @click.prevent="onClick()">
    <rui-icon icon="arrow-back"/>
  </button>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

export default defineComponent({
  name: 'fire-back-button',
  emits: ['clickBack'],
  components: {
    RuiIcon,
  },
  methods: {
    onClick() {
      this.$emit('clickBack');
    },
  },
});
</script>
<style lang="scss" scoped>
@import 'back';
</style>
