<template>
  <div class="rui-sidebar">
    <div class="rui-sidebar__header"
         v-if="$slots.header">
      <slot name="header"/>
    </div>
    <div class="rui-sidebar__alert"
         v-if="$slots.alert">
      <slot name="alert"/>
    </div>
    <nav class="rui-sidebar__body">
      <slot name="navigation"/>
    </nav>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'rui-sidebar',
});
</script>

<style lang="scss" scoped>
@import 'sidebar';
</style>
