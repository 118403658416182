
import { defineComponent } from 'vue';
import {ASSIGNMENTS} from '@/app/core/constants';

export default defineComponent({
  name: 'fire-assignment-list',
  emits: ['selectItem'],
  props: {
    assignmentName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      assignmentList: ASSIGNMENTS,
    };
  },
  methods: {
    onClick(value: string) {
      this.$emit('selectItem', value);
    },
  },
});
