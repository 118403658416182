import {MemberListItem} from '@/app/core/interfaces';
import axios from 'axios';

import {BASE_URL} from '@/app/core/constants';

// TODO: mock data - const newID = Math.random();

export default class MemberListDataProvider {
  /**
   * GET incident/:id/members.
   */
  // TODO: mock data.
  // getMemberList(): Promise<MemberListItem[]> {
  //   return new Promise((resolve) => {
  //     setTimeout(() => resolve(MEMBER_LIST_DATA), 2000);
  //   });
  // }
  async getMemberList(incidentId: string): Promise<MemberListItem[]> {
    const res = await axios.get<MemberListItem[]>(`${BASE_URL}/incidents/${incidentId}/members`);

    // const data = res.data.filter((a, index, self) => index === self.findIndex((b) => (b.id === a.id)));

    return res.data || [];
  }

  /**
   * POST incident/:id/members.
   */
  // TODO: mock data.
  // addMember(member: { fullName: string; rank: string }): Promise<MemberListItem> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve({
  //       fullName: member.fullName,
  //       rank: member.rank,
  //       id: `${newID}`,
  //       location: '',
  //       vehicleId: '',
  //       vehicleName: '',
  //     }), 1000);
  //   });
  // }
  async addMember(member: { vehicleName: string; vehicleId: string; fullName: string; rank: string }, incidentId: string): Promise<MemberListItem> {
    const res = await axios.post<MemberListItem>(`${BASE_URL}/incidents/${incidentId}/members`, member);

    return res.data || {};
  }

  /**
   * POST incidents/:id/members/:memberId.
   */
  async moveMember(memberId: string, moveMember: { oldVehicleId: string; newVehicleId: string }, incidentId: string): Promise<void> {
    await axios.post<MemberListItem>(`${BASE_URL}/incidents/${incidentId}/members/${memberId}/moveMember`, moveMember);
  }

  /**
   * PUT incidents/:id/members/:id.
   */
  // TODO: mock data.
  // updateMember(member: MemberListItem): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve(), 1000);
  //   });
  // }
  async updateMember(member: MemberListItem, incidentId: string): Promise<void> {
    await axios.put<MemberListItem>(`${BASE_URL}/incidents/${incidentId}/members/${member.id}`, member);
  }

  /**
   * DELETE incident/:id/members/:id.
   */
  // TODO: mock data.
  // deleteMember(memberId: string): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve(), 1000);
  //   });
  // }
  async deleteMember(memberId: string, incidentId: string): Promise<void> {
    await axios.delete<MemberListItem>(`${BASE_URL}/incidents/${incidentId}/members/${memberId}`);
  }
}
