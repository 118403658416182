import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';

import FireProfilePage from '@/app/views/FireProfilePage.vue';
import FireNotesPage from '@/app/views/notes/FireNotesPage.vue';
import FireRosterDetailPage from '@/app/views/vital-watch/roster-detail-page/FireRosterDetailPage.vue';
import FireOnScenePage from '@/app/views/on-scene/on-scene-page/FireOnScenePage.vue';
import FireRosterPage from '@/app/views/vital-watch/roster-page/FireRosterPage.vue';
import FireNewPassword from '@/app/components/auth/FireNewPassword.vue';
import FireLogin from '@/app/components/auth/FireLogin.vue';
import FireForgotPassword from '@/app/components/auth/FireForgotPassword.vue';
import FireSupportPage from '@/app/views/support/FireSupportPage.vue';

import {AuthModuleKey, GetterTypesFromAuth} from '@/app/data-flow/auth';

import store from '../data-flow';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/on-scene',
    meta: {requiresAuth: true},
  },
  {
    path: '/login',
    name: 'login',
    component: FireLogin,
  },
  {
    path: '/new-password/:token',
    name: 'new-password',
    component: FireNewPassword,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: FireForgotPassword,
  },
  {
    path: '/on-scene',
    name: 'on-scene',
    component: FireOnScenePage,
    meta: {requiresAuth: true},
  },
  {
    path: '/vital-watch',
    component: FireRosterPage,
    children: [
      {
        path: 'rosters',
        redirect: '/vital-watch',
      },
      {
        path: 'rosters/:id',
        name: 'RosterDetail',
        component: FireRosterDetailPage,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/notes',
    name: 'Notes',
    component: FireNotesPage,
    meta: {requiresAuth: true},
  },
  {
    path: '/profile',
    name: 'Profile',
    component: FireProfilePage,
    meta: {requiresAuth: true},
  },
  {
    path: '/support',
    name: 'Support',
    component: FireSupportPage,
    meta: {requiresAuth: true},
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const getterLoggedInState = store.getters[`${AuthModuleKey}/${GetterTypesFromAuth.LOGGED_IN_STATE}`];

  if (to.matched.some((record) => record.meta.requiresAuth) && !getterLoggedInState) {
    next({
      name: 'login',
      params: {nextUrl: to.fullPath},
    });
  } else {
    next();
  }
});

export default router;
