import {PATTERN_VALIDATE} from './constants';

export default class Validator {
  public static emailValid(value: string) {
    const pattern = PATTERN_VALIDATE.email;
    return pattern.test(value);
  }

  public static passwordValid(value: string) {
    const pattern = PATTERN_VALIDATE.password;
    return pattern.test(value);
  }
}
