
import {defineComponent} from 'vue';

import RuiNavigation from '@/retinus-ui/components/layout/navigation/RuiNavigation.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

import {BUTTON_TYPE, HOME} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'rui-header',
  emits: ['clickLogout'],
  components: {
    RuiNavigation,
    RuiButton,
    RuiIcon,
  },
  props: {
    navigationMenu: {
      type: Array,
      required: true,
    },
    userPhotoMin: {
      type: String,
    },
    disableNavbar: {
      type: Boolean,
      default: false,
    },
    logoutButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenNavigation: false,
      logoLinkText: HOME,
      buttonTypeIcon: BUTTON_TYPE.icon,
    };
  },
  methods: {
    onToggleNavigation() {
      this.isOpenNavigation = !this.isOpenNavigation;
    },

    away() {
      this.isOpenNavigation = false;
    },

    logout() {
      this.$emit('clickLogout');
    },
  },
});
