
import {defineComponent} from 'vue';

import {BUTTON_TYPE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'rui-button',
  emits: ['clickFromButtonComponent'],
  props: {
    buttonText: {
      type: String,
    },
    buttonType: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      classType: BUTTON_TYPE,
    };
  },
  methods: {
    checkType(type: string) {
      switch (type) {
        case this.classType.primaryO:
          return '--primary-o';
        case this.classType.accent:
          return '--accent';
        case this.classType.primary:
          return '--primary';
        case this.classType.icon:
          return '--icon';
        default:
          return '--primary-o';
      }
    },
    onClick(value: string) {
      this.$emit('clickFromButtonComponent', value);
    },
  },
});
