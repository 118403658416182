import _ from 'lodash';
import {VehicleGroup, VehicleListItem} from '@/app/core/interfaces';
import {GetterTypesFromVehicles} from '@/app/data-flow/vehicles/types';
import {State} from '@/app/data-flow/vehicles/state';

// eslint-disable-next-line import/prefer-default-export
export const getters = {
  [GetterTypesFromVehicles.VEHICLE_LIST_LOADING_STATE](state: State) {
    return state.loading;
  },
  [GetterTypesFromVehicles.VEHICLE_LIST_ERROR_STATE](state: State) {
    return state.error;
  },
  [GetterTypesFromVehicles.VEHICLE_LIST](state: State) {
    return state.vehicleList;
  },
  [GetterTypesFromVehicles.VEHICLE_LIST_MEMBERS_NUM](state: State) {
    if (state.vehicleList) {
      return state.vehicleList.reduce((a, b) => b.team.length + a, 0);
    }
    return undefined;
  },

  [GetterTypesFromVehicles.VEHICLE_GROUPS](state: State) {
    const count = 3;

    // Don't show empty cars.
    const vehicleListItems = _.cloneDeep(state.vehicleList)?.filter((v) => v.team.length > 0);

    if (!vehicleListItems) {
      return [];
    }

    return vehicleListItems.reduce((res, item: VehicleListItem) => {
      const ln = res.length;
      const lastGroup = res[ln - 1];

      if (lastGroup === undefined || lastGroup.items.length >= count) {
        res.push({
          expanded: false,
          items: [item],
        });
      } else {
        lastGroup.items.push(item);
      }
      return res;
    }, [] as VehicleGroup[]);
  },
  [GetterTypesFromVehicles.VEHICLE_START_PAR_STATE](state: State) {
    return state.parState;
  },

  [GetterTypesFromVehicles.ARRIVED_VEHICLE_LOADING_STATE](state: State) {
    return state.vehicleArrivedLoading;
  },
  [GetterTypesFromVehicles.ARRIVED_VEHICLE_ERROR_STATE](state: State) {
    return state.vehicleArrivedError;
  },
};
