import _ from 'lodash';
import {State} from '@/app/data-flow/vehicles/state';
import {ActionContext} from 'vuex';
import {VehicleListItem} from '@/app/core/interfaces';
import VehicleListDataProvider from '@/app/data-flow/vehicles/VehicleListDataProvider';
import {GetterTypesFromIncidentList} from '@/app/data-flow/incident/types';

import {IncidentListModuleKey} from '@/app/data-flow/incident';
import Sorter from '@/app/core/sorter';

const vehicleListDataProvider = new VehicleListDataProvider();

/**
 * Get Current IncidentId.
 */
const getterKey = `${IncidentListModuleKey}/${GetterTypesFromIncidentList.CURRENT_INCIDENT_ID}`;

// eslint-disable-next-line import/prefer-default-export
export const actions = {
  loadVehicleList(context: ActionContext<State, unknown>) {
    context.commit('loadVehicleList');

    const incidentId = context.rootGetters[getterKey];

    return vehicleListDataProvider.getVehicleList(incidentId)
      .then((response) => {
        // eslint-disable-next-line no-return-assign,no-param-reassign
        const data = [...response].filter((v) => v.team = Sorter.sortByRank(v.team));

        context.commit('loadVehicleListSuccess', {data});
      })
      .catch((error) => {
        context.commit('loadVehicleListFail', {error});
      });
  },

  setStartParState(context: ActionContext<State, unknown>) {
    context.commit('setParState', {parState: 'START'});
  },

  setPauseParState(context: ActionContext<State, unknown>) {
    context.commit('setParState', {parState: 'PAUSE'});
  },

  setCancelParState(context: ActionContext<State, unknown>) {
    context.commit('setParState', {parState: undefined});
  },

  updateVehiclePar(
    context: ActionContext<State, unknown>,
    {vehicleId, par}: {
      vehicleId: string;
      par: number;
    },
  ) {
    context.commit('updateVehiclePar');

    const incidentId = context.rootGetters[getterKey];

    return vehicleListDataProvider.updateVehiclePar(vehicleId, par, incidentId)
      .then(() => {
        context.commit('updateVehicleParSuccess', {vehicleId, par});
      })
      .catch((error) => {
        context.commit('updateVehicleParFail', {error});
      });
  },

  updateVehicle(
    context: ActionContext<State, unknown>,
    {vehicle}: {
      vehicle: VehicleListItem;
    },
  ) {
    context.commit('updateVehicle');

    const incidentId = context.rootGetters[getterKey];

    return vehicleListDataProvider.updateVehicle(vehicle, incidentId)
      .then(() => {
        context.commit('updateVehicleSuccess');
        context.commit('updateVehicleListItem', {vehicle});
      })
      .catch((error) => {
        context.commit('updateVehicleFail', {error});
      });
  },

  deleteVehicle(
    context: ActionContext<State, unknown>,
    {vehicleId}: {
      vehicleId: string;
    },
  ) {
    context.commit('deleteVehicle');

    const incidentId = context.rootGetters[getterKey];

    return vehicleListDataProvider.deleteVehicle(vehicleId, incidentId)
      .then(() => {
        context.commit('deleteVehicleSuccess');
        context.commit('deleteVehicleListItem', {vehicleId});
      })
      .catch((error) => {
        context.commit('deleteVehicleFail', {error});
      });
  },

  addVehicle(context: ActionContext<State, unknown>, {name}: { name: string }) {
    const newVehicle = {
      name,
    };

    context.commit('addVehicle');

    const incidentId = context.rootGetters[getterKey];

    vehicleListDataProvider.addVehicle(newVehicle, incidentId)
      .then((vehicle) => {
        context.commit('addVehicleSuccess', {vehicle});
      })
      .catch((error) => {
        context.commit('addVehicleFail', {error});
      });
  },

  addArrivedVehicleToList(context: ActionContext<State, unknown>, {vehicle}: { vehicle: VehicleListItem }) {
    context.commit('addVehicleSuccess', {vehicle});
  },

  onRemoveHighlightFromVehicle(context: ActionContext<State, unknown>, {vehicle}: { vehicle: VehicleListItem }) {
    context.commit('onRemoveHighlightFromVehicleSuccess', {vehicle});
  },

  loadArrivedVehicles(
    context: ActionContext<State, unknown>,
    {vehicleId}: { vehicleId: string },
  ) {
    context.commit('loadArrivedVehicle');

    const incidentId = context.rootGetters[getterKey];

    return vehicleListDataProvider.getArrivedVehicle(vehicleId, incidentId)
      .then((data) => {
        const clonedData = _.cloneDeep(data);

        context.commit('loadArrivedVehicleSuccess');
        clonedData.team = Sorter.sortByRank(clonedData.team);

        return clonedData;
      })
      .catch((error) => {
        context.commit('loadArrivedVehicleListFail', {error});
      });
  },
};
