import * as _ from 'lodash';

import {ActionContext} from 'vuex';
import {AnalyticalListItem} from '@/app/core/interfaces';
import {IncidentListModuleKey} from '@/app/data-flow/incident';
import {GetterTypesFromIncidentList} from '@/app/data-flow/incident/types';
import AnalyticListDataProvider from '@/app/data-flow/analytic/AnalyticListDataProvider';

const analyticListDataProvider = new AnalyticListDataProvider();

/**
 * Get Current IncidentId.
 */
const getterKey = `${IncidentListModuleKey}/${GetterTypesFromIncidentList.CURRENT_INCIDENT_ID}`;

export const AnalyticsModuleKey = 'AnalyticsModule';

export const GetterTypesFromAnalytics = {
  ROSTER_ANALYTIC_DATA: 'ROSTER_ANALYTIC_DATA',
  ROSTER_CURRENT_CARDIO_BASELINE: 'ROSTER_CURRENT_CARDIO_BASELINE',
  ROSTER_CURRENT_CARDIO_MEAN_BASELINE: 'ROSTER_CURRENT_CARDIO_MEAN_BASELINE',
  ROSTER_CURRENT_CARDIO_TIME: 'ROSTER_CURRENT_CARDIO_TIME',
  ROSTER_BASELINE_CARDIO_BASELINE: 'ROSTER_BASELINE_CARDIO_BASELINE',
  ROSTER_BASELINE_CARDIO_MEAN_BASELINE: 'ROSTER_BASELINE_CARDIO_MEAN_BASELINE',
  ROSTER_BASELINE_CARDIO_TIME: 'ROSTER_BASELINE_CARDIO_TIME',
  ROSTER_FIT_O2_SCATTER: 'ROSTER_FIT_O2_SCATTER',
  ROSTER_FIT_O2_LINE: 'ROSTER_FIT_O2_LINE',
  ROSTER_FIT_HEART_RATE_SCATTER: 'ROSTER_FIT_HEART_RATE_SCATTER_X',
  ROSTER_FIT_HEART_RATE_LINE: 'ROSTER_FIT_HEART_RATE_LINE',
};

export interface State {
  loading: boolean;
  error?: Error;
  analyticData?: AnalyticalListItem;
}

const initialState = (): State => ({
  loading: false,
  error: undefined,
  analyticData: undefined,
});

const timestampFormat = (timestampList: any[]) => {
  const convert: string[] = [];
  timestampList.map((el) => {
    const date = new Date(el);
    const hours = date.getHours();
    const minutes = `0${date.getMinutes()}`;
    const seconds = `0${date.getSeconds()}`;
    const formattedTime = `${hours}:${minutes.substr(
      -2,
    )}:${seconds.substr(-2)}`;
    return convert.push(formattedTime);
  });
  return convert;
};

const getters = {
  [GetterTypesFromAnalytics.ROSTER_ANALYTIC_DATA](state: State) {
    return state.analyticData;
  },

  [GetterTypesFromAnalytics.ROSTER_CURRENT_CARDIO_BASELINE](state: State) {
    const heartRateList: number[] = [];
    if (state.analyticData) {
      state.analyticData.currentCardioVar.map((element) => heartRateList.push(element.heartRate));
      return heartRateList;
    }
    return [];
  },

  [GetterTypesFromAnalytics.ROSTER_CURRENT_CARDIO_MEAN_BASELINE](state: State) {
    const heartRateList: number[] = [];
    if (state.analyticData) {
      state.analyticData.currentCardioVar.map((element) => heartRateList.push(element.rolling_heartRate));
      return heartRateList;
    }
    return [];
  },

  [GetterTypesFromAnalytics.ROSTER_CURRENT_CARDIO_TIME](state: State) {
    const timestampList: string[] = [];
    if (state.analyticData) {
      state.analyticData.currentCardioVar.map((element) => timestampList.push(element.timestamp));
      const convert: string[] = [];
      timestampList.map((el) => {
        const date = new Date(el);
        const hours = date.getHours();
        const minutes = `0${date.getMinutes()}`;
        const seconds = `0${date.getSeconds()}`;
        const formattedTime = `${hours}:${minutes.substr(
          -2,
        )}:${seconds.substr(-2)}`;
        return convert.push(formattedTime);
      });
      return convert;
    }
    return [];
  },

  [GetterTypesFromAnalytics.ROSTER_BASELINE_CARDIO_BASELINE](state: State) {
    const heartRateList: number[] = [];
    if (state.analyticData) {
      state.analyticData.baselineCardioVar.map((element) => heartRateList.push(element.heartRate));
      return heartRateList;
    }
    return [];
  },

  [GetterTypesFromAnalytics.ROSTER_BASELINE_CARDIO_MEAN_BASELINE](state: State) {
    const heartRateList: number[] = [];
    if (state.analyticData) {
      state.analyticData.baselineCardioVar.map((element) => heartRateList.push(element.rolling_heartRate));
      return heartRateList;
    }
    return [];
  },

  [GetterTypesFromAnalytics.ROSTER_BASELINE_CARDIO_TIME](state: State) {
    const timestampList: number[] = [];
    // TODO: duplicate of GetterTypesFromRosters.ROSTER_CURRENT_CARDIO_TIME.
    if (state.analyticData) {
      state.analyticData.baselineCardioVar.map((element) => timestampList.push(element.timestamp));
      const convert: string[] = [];
      timestampList.map((el) => {
        const date = new Date(el);
        const hours = date.getHours();
        const minutes = `0${date.getMinutes()}`;
        const seconds = `0${date.getSeconds()}`;
        const formattedTime = `${hours}:${minutes.substr(
          -2,
        )}:${seconds.substr(-2)}`;
        return convert.push(formattedTime);
      });
      return convert;
    }
    return [];
  },

  [GetterTypesFromAnalytics.ROSTER_FIT_O2_SCATTER](state: State) {
    if (state.analyticData) {
      return state.analyticData.fit02Scatter;
    }
    return [];
  },

  [GetterTypesFromAnalytics.ROSTER_FIT_O2_LINE](state: State) {
    if (state.analyticData) {
      return state.analyticData.fit02Line;
    }
    return [];
  },

  [GetterTypesFromAnalytics.ROSTER_FIT_HEART_RATE_SCATTER](state: State) {
    if (state.analyticData) {
      return state.analyticData.fitHRScatter;
    }
    return [];
  },

  [GetterTypesFromAnalytics.ROSTER_FIT_HEART_RATE_LINE](state: State) {
    if (state.analyticData) {
      return state.analyticData.fitHRLine;
    }
    return [];
  },
};

const actions = {
  loadAnalyticData(
    context: ActionContext<State, unknown>,
    {watchId}: { watchId: string },
  ) {
    context.commit('loadAnalyticData');

    const incidentId = context.rootGetters[getterKey];

    return analyticListDataProvider
      .getAnalyticalData(watchId, incidentId)
      .then((data) => {
        context.commit('loadAnalyticDataSuccess', {data});
      })
      .catch((error) => {
        context.commit('loadAnalyticDataFail', {error});
        return error;
      });
  },
};

const mutations = {
  loadAnalyticData(state: State) {
    state.loading = true;
    state.error = undefined;
  },

  loadAnalyticDataSuccess(state: State, payload: { data: AnalyticalListItem }) {
    state.loading = false;
    state.error = undefined;

    if (!_.isEqual(state.analyticData, payload.data)) {
      state.analyticData = payload.data;
    }
  },

  loadAnalyticDataFail(state: State, payload: { error: Error }) {
    state.loading = false;
    state.error = payload.error;
    state.analyticData = undefined;
  },
};

export const AnalyticsModule = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialState,
};
