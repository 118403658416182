<template>
  <div class="rui-tabs">
    <div class="rui-tabs__toolbar">
      <div class="rui-tabs__tab"
           v-for="(tab, index) in tabList"
           :key="index">
        <rui-button @clickFromButtonComponent="$emit('clickTab', tab)"
                    class="rui-btn"
                    :class="activeTabClass === tab.key ? 'is-active': ''"
                    :disabled="tab.disabled"
                    :buttonText="tab.title"/>
      </div>
    </div>
    <div v-if="$slots.header"
         class="rui-tabs__header">
      <slot name="header" />
    </div>
    <div class="rui-tabs__body">
      <slot name="body" />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';

export default defineComponent({
  name: 'rui-tabs',
  props: {
    tabList: Array,
    activeTabClass: String,
  },
  components: {
    RuiButton,
  },
});
</script>

<style lang="scss" scoped>
@import 'tabs';
</style>
