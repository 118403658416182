<template>
  <div class="rui-modal__container"
       :class="{ 'rui-modal--confirm': confirmation }">
    <rui-backdrop @click.prevent="$emit('cancel')"/>
    <div class="rui-modal"
         role="dialog">
      <div class="rui-modal__header">
        <div class="rui-modal__title">
          <h1>{{ title }}</h1>
          <slot v-if="$slots.head"
                name="head"/>
        </div>
        <slot v-if="$slots.close"
              name="close"/>
      </div>
      <div v-if="$slots.img"
           class="rui-modal__img">
        <slot name="img"/>
      </div>
      <div v-if="$slots.body"
           class="rui-modal__body">
        <slot name="body"/>
      </div>
      <div v-if="$slots.footer"
           class="rui-modal__footer"
           :class="{'is-right-direction': footerTextDirection}">
        <slot name="footer" ref="trapFocus"/>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import RuiModalTools from '@/retinus-ui/components/modal/RuiModalTools';
import RuiBackdrop from '@/retinus-ui/components/backdrop/RuiBackdrop.vue';

export default defineComponent({
  name: 'rui-modal',
  components: {
    RuiBackdrop,
  },
  props: {
    title: {
      type: String,
    },
    confirmation: {
      type: Boolean,
    },
    footerTextDirection: {
      type: Boolean,
    },
  },
  data() {
    return {
      RuiModalTools,
    };
  },
  mounted() {
    this.RuiModalTools.focusTrap();
  },
});
</script>

<style lang="scss" scoped>
@import "modal";
</style>
