import {VehicleListItem, ParState} from '@/app/core/interfaces';

export interface State {
  vehicleList?: VehicleListItem[];
  loading: boolean;
  error?: Error;

  parState: ParState;

  parUpdateLoading: boolean;
  parUpdateError?: Error;

  vehicle?: VehicleListItem;
  vehicleUpdateLoading: boolean;
  vehicleUpdateError?: Error;

  vehicleArrivedLoading: boolean;
  vehicleArrivedError?: Error;
}

export const initialState = (): State => ({
  vehicleList: undefined,
  loading: false,
  error: undefined,

  parState: undefined,

  parUpdateLoading: false,
  parUpdateError: undefined,

  vehicle: undefined,
  vehicleUpdateLoading: false,
  vehicleUpdateError: undefined,

  vehicleArrivedLoading: false,
  vehicleArrivedError: undefined,
});
