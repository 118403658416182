import {MemberListItem} from '@/app/core/interfaces';

export interface State {
  memberList?: MemberListItem[];

  memberId?: string;
  loading: boolean;
  error?: Error;

  member?: MemberListItem;
  memberUpdateLoading: boolean;
  memberUpdateError?: Error;
  memberDeleteLoading: boolean;
  memberDeleteError?: Error;
}

export const initialState = (): State => ({
  memberList: undefined,

  memberId: undefined,
  loading: false,
  error: undefined,

  member: undefined,
  memberUpdateLoading: false,
  memberUpdateError: undefined,
  memberDeleteLoading: false,
  memberDeleteError: undefined,
});
