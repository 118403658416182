import {getters} from '@/app/data-flow/vehicles/getters';
import {actions} from '@/app/data-flow/vehicles/actions';
import {mutations} from '@/app/data-flow/vehicles/mutations';
import {initialState} from '@/app/data-flow/vehicles/state';

export const VehiclesModuleKey = 'VehiclesModule';

export const VehiclesModule = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialState,
};
