<template>
  <div class="fire-card">
    <div class="fire-card__header">
      <div class="fire-card__title">
        <rui-icon class="fire-ico"
                  v-if="icon"
                  :icon="icon"></rui-icon>
        <span>{{ title }}</span>
      </div>
      <span :class="`fire-card__level${checkAlertColor(level)}`"
            v-if="level">{{ formatLevel() }}</span>
    </div>
    <div class="fire-card__body">
      <slot name="body"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import VitalWatchTools from '@/app/views/vital-watch/VitalWatchTools';

export default defineComponent({
  name: 'fire-roster-health-card',
  props: {
    title: String,
    icon: String,
    level: [String, Number],
  },
  components: {
    RuiIcon,
  },
  methods: {
    checkAlertColor(type: number | string) {
      if (typeof type !== 'number') {
        return VitalWatchTools.setColorStyle(type.toLowerCase());
      }
      return '';
    },

    formatLevel() {
      if (typeof this.level === 'number' && this.level % 1 !== 0) {
        return this.level.toFixed(3);
      }
      return this.level;
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'roster-health-card';
</style>
