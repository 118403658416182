<template>
  <rui-notify :icon="getArrivedVehicles > 0"
              :text="getArrivedVehicles"
              :type="color.danger">
    <template v-slot:body>
      <rui-button :buttonType="buttonType"
                  :disabled="disable || !getArrivedVehicles"
                  @clickFromButtonComponent="checkArrivedVehicle()"
                  class="fire-bell">
        <template v-slot:icon>
          <rui-icon icon="bell-add"/>
        </template>
      </rui-button>
    </template>
  </rui-notify>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiNotify from '@/retinus-ui/components/notify/RuiNotify.vue';
import {mapActions} from 'vuex';
import {VehiclesModuleKey} from '@/app/data-flow/vehicles';
import {COLOR_TYPE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'fire-vehicle-arrived',
  emits: ['vehicleArrived'],
  components: {
    RuiIcon,
    RuiButton,
    RuiNotify,
  },
  props: {
    disable: Boolean,
    data: Array,
    buttonType: String,
  },
  data() {
    return {
      arrivedVehicleCount: 0,
      color: COLOR_TYPE,
    } as {
      arrivedVehicleCount: number;
      color: {};
    };
  },
  computed: {
    getArrivedVehicles(): number {
      return (this.data?.length || 0) - this.arrivedVehicleCount;
    },
  },
  methods: {
    ...mapActions(VehiclesModuleKey, ['loadArrivedVehicles']),

    checkArrivedVehicle() {
      if (this.data && this.getArrivedVehicles) {
        this.loadArrivedVehicles({
          vehicleId: this.data[this.arrivedVehicleCount],
        }).then((res) => {
          this.arrivedVehicleCount += 1;
          this.$emit('vehicleArrived', res);
        });
      }
    },
  },
});
</script>
