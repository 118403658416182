<template>
  <rui-page-layout class="fire-page-spaces">
    <template v-slot:head>
      Notes
    </template>
    <template v-slot:body>
      <fire-notes-form @changeInput="changeInput($event)"
                       :disableSubmit="disableFormSubmit()"
                       :descriptionValue="formDescription"
                       @submitForm="submitNote()"
                       @resetForm="resetNote()"
                       :subjectValue="formSubject"
                       @changeTextarea="changeTextarea($event)"/>
      <fire-note-list :data="noteList"
                      v-if="noteList && noteList.length"
                      @deleteNoteItem="deleteNoteItem($event)"/>
    </template>
  </rui-page-layout>

  <rui-spinner-wrapper v-if="noteListLoading"/>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import {NoteListItem} from '@/app/core/interfaces';

import RuiPageLayout from '@/retinus-ui/components/layout/pageLayout/RuiPageLayout.vue';
import FireNotesForm from '@/app/views/notes/components/FireNotesForm.vue';
import FireNoteList from '@/app/views/notes/components/history/FireNoteList.vue';
import RuiSpinnerWrapper from '@/retinus-ui/components/spinner/spinner-wrapper/RuiSpinnerWrapper.vue';

import {mapActions, mapGetters} from 'vuex';
import {GetterTypesFromNotes, NotesModuleKey} from '@/app/data-flow/notes';

export default defineComponent({
  name: 'fire-notes-page',
  components: {
    RuiPageLayout,
    FireNotesForm,
    FireNoteList,
    RuiSpinnerWrapper,
  },
  data() {
    return {
      noteList: [],
      formDescription: '',
      formSubject: '',
    } as {
      noteList: NoteListItem[];
      formDescription: string;
      formSubject: string;
    };
  },
  computed: {
    ...mapGetters(NotesModuleKey, {
      noteListLoading: GetterTypesFromNotes.NOTE_LIST_LOADING_STATE,
      noteListState: GetterTypesFromNotes.NOTE_LIST,
    }),
  },
  methods: {
    ...mapActions(NotesModuleKey, ['addNote', 'deleteNote', 'loadNoteList']),

    watchNoteListState(val: NoteListItem[]) {
      this.noteList = val;
    },

    changeInput(val: string) {
      this.formSubject = val;
    },

    changeTextarea(val: string) {
      this.formDescription = val;
    },

    disableFormSubmit() {
      return !(this.formDescription && this.formSubject);
    },

    submitNote() {
      if (this.formDescription && this.formSubject) {
        this.addNote({
          subject: this.formSubject,
          description: this.formDescription,
        })
          .then(() => {
            this.resetNote();
          });
      }
    },

    resetNote() {
      this.formDescription = '';
      this.formSubject = '';
    },

    deleteNoteItem(val: NoteListItem) {
      if (val) {
        this.deleteNote({
          noteId: val.id as string,
        });
      }
    },
  },
  watch: {
    noteListState: 'watchNoteListState',
  },
  mounted() {
    this.loadNoteList();
  },
});
</script>

<style lang="scss" scoped>
@import '../../../styles/components/form';
</style>
