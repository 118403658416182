import axios from 'axios';

import {NoteListItem, VehicleListItem} from '@/app/core/interfaces';
import {BASE_URL} from '@/app/core/constants';

// TODO: mock data - const newID = Math.random();

const checkDate = (dateString: string | number | Date) => new Intl.DateTimeFormat('en-GB', {
  year: 'numeric',
  month: 'long',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  day: '2-digit',
}).format(new Date(dateString));

export default class NoteListDataProvider {
  /**
   * GET incidents/:id/notes.
   */
  // TODO: mock data.
  // getNoteList(): Promise<NoteListItem[]> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve(NOTE_LIST), 1000);
  //   });
  // }
  async getNoteList(incidentId: string): Promise<NoteListItem[]> {
    const res = await axios.get<NoteListItem[]>(`${BASE_URL}/incidents/${incidentId}/notes`);

    const data = res.data.map((item) => ({
      id: item.id,
      subject: item.subject,
      description: item.description,
      date: checkDate(item.date),
    }));

    return data || [];
  }

  /**
   * DELETE incidents/:id/notes/:noteId.
   */
  // TODO: mock data.
  // deleteNote(noteId: string): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve(), 1000);
  //   });
  // }
  async deleteNote(noteId: string, incidentId: string): Promise<void> {
    await axios.delete<VehicleListItem>(`${BASE_URL}/incidents/${incidentId}/notes/${noteId}`);
  }

  /**
   * POST incidents/:id/notes.
   */
  // TODO: mock data.
  // addNote(note: { subject: string; description: string }): Promise<NoteListItem> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve({
  //       noteId: `${newID}`,
  //       date: '2021-02-26T22:49:56+00:00',
  //       subject: note.subject,
  //       description: note.description,
  //     }), 1000);
  //   });
  // }
  async addNote(note: { subject: string; description: string }, incidentId: string): Promise<NoteListItem> {
    const res = await axios.post<NoteListItem>(`${BASE_URL}/incidents/${incidentId}/notes`, note);

    const data = {
      id: res.data.id,
      subject: res.data.subject,
      description: res.data.description,
      date: checkDate(res.data.date),
    };

    return data || {};
  }
}
