<template>
  <form class="fire-form">
    <div class="fire-form__row">
      <rui-input type="text"
                 required
                 :modelValue="subjectValue"
                 @changeFromInputComponent="$emit('changeInput', $event)"
                 :placeholder="placeholderText.subject"/>
    </div>
    <div class="fire-form__row">
      <rui-dynamic-input :placeholder="placeholderText.description"
                         :modelValue="descriptionValue"
                         @grabText="$emit('changeTextarea', $event)"/>
    </div>
    <div class="fire-form__actions">
      <rui-list-flat>
        <rui-button class="fire-btn"
                    @clickFromButtonComponent="$emit('resetForm')"
                    :buttonText="buttonText.reset"/>

        <rui-button class="fire-btn"
                    type="submit"
                    :disabled="disableSubmit"
                    @clickFromButtonComponent="$emit('submitForm')"
                    :buttonText="buttonText.submit"/>
      </rui-list-flat>
    </div>
  </form>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiInput from '@/retinus-ui/components/input/RuiInput.vue';
import RuiDynamicInput from '@/retinus-ui/components/dynamic-Input/RuiDynamicInput.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiListFlat from '@/retinus-ui/components/list-flat/RuiListFlat.vue';

import {BUTTON_TEXT, PLACEHOLDER_TEXT} from '../../../core/constants';

export default defineComponent({
  name: 'fire-support-form',
  props: {
    disableSubmit: Boolean,
    descriptionValue: String,
    subjectValue: String,
  },
  components: {
    RuiInput,
    RuiDynamicInput,
    RuiButton,
    RuiListFlat,
  },
  data() {
    return {
      buttonText: BUTTON_TEXT,
      placeholderText: PLACEHOLDER_TEXT,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '../../../../styles/components/form';
</style>
