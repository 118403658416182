<template>
  <div :class="`fire-assignment ${!text ? 'fire-gray' : ''}`">
    {{ text || unassigned }}
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import {UNASSIGNED} from '@/app/core/constants';

export default defineComponent({
  name: 'fire-assignment-label',
  props: {
    text: String,
  },
  data() {
    return {
      unassigned: UNASSIGNED as string,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'assignment';
</style>
