import axios from 'axios';

import {RosterListItem} from '@/app/core/interfaces';
import {BASE_URL} from '@/app/core/constants';

export default class RosterListDataProvider {
  /**
   * GET v1/members.
   */
  // TODO: mock data.
  // getRosterList(): Promise<RosterListItem[]> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve(ROSTER_LIST_DATA), 1000);
  //     // setTimeout(() => reject(new Error()), 2000);
  //   });
  // }
  async getRosterList(): Promise<RosterListItem[]> {
    const res = await axios.get<RosterListItem[]>(`${BASE_URL}/members`);

    return res.data || [];
  }
}
