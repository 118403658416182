export const BASE_URL = process.env.VUE_APP_ROOT_API;

export const NAVIGATION_MENU = [
  {
    name: 'On Scene',
    link: '/on-scene',
    icon: 'task',
  },
  {
    name: 'Vital Watch',
    link: '/vital-watch',
    icon: 'health',
  },
  {
    name: 'Notes',
    link: '/notes',
    icon: 'notes',
  },
  {
    name: 'support',
    link: '/support',
    icon: 'support',
  },
];

export const SIDEBAR_RANK = {
  captain: 'captain',
  lieutenant: 'lieutenant',
  firefighter: 'firefighter',
  fireChief: 'fire chief',
};

export const RANK = {
  captain: 'cpt',
  lieutenant: 'lt',
  firefighter: 'ff',
};

export const RANK_ORDER = ['cpt', 'lt', 'ff'];

export const BUTTON_TEXT = {
  reset: 'reset',
  submit: 'submit',
  collapse: 'collapse',
  expand: 'expand',
  startPar: 'start par',
  cancelPar: 'cancel par',
  pausePar: 'pause par',
};

export const PLACEHOLDER_TEXT = {
  subject: 'Enter Subject',
  description: 'Enter Description',
  memberName: 'Please enter the member name',
  truckName: 'Please enter the truck name',
  editMemberName: 'Please enter the member name',
  editVehicleName: 'Please enter the vehicle name',
  userEmail: 'Please enter your Email',
  userName: 'Please enter your Name',
  userPassword: 'Please enter your Password',
  userPasswordConfirm: 'Please confirm your Password',
};

export const ALERT_TEXT = {
  supportSuccess: `Thank you for your request!
  Your message has been successfully sent.`,
  oxygenLevel: 'oxygen level',
  forgotPassword: 'If you are a registered user, you will receive a recovery email soon.',
  newPassword: `Your password has been successfully changed.
   You can now log in using your new password`,
  passwordNotMach: 'Password does not mach. Please re-enter a new password again.',
};

export const OXYGEN_LEVEL = {
  medium: 'medium',
  low: 'low',
  high: 'high',
};
export const HAZMAT = {
  ok: 'ok',
  tolerable: 'tolerable',
};

export const ASSIGNMENTS = [
  {
    id: 'regregrsg',
    name: 'fire attack',
  },
  {
    id: 'regreghjhbrsg',
    name: 'search',
  },
  {
    id: 'regreghjrgseer',
    name: 'ventilation',
  },
  {
    id: 'regreghjrgfgdcrseer',
    name: 'investigation',
  },
  {
    id: 'regreghjrgfgrrdcrseer',
    name: 'water supply',
  },
  {
    id: 'regreghjrgfgrrdcrseer',
    name: 'division a',
  },
];

export const MSQ_REMOVE_CONFIRM = 'Are you sure you want to remove ';

export const MSQ_ASSIGN_CONFIRM = {
  assignedAlready: 'is already assigned to',
  assigned: 'is assigned to',
  wantToMove: 'Are you sure you want to move them',
};

export const VITAL_WATCH_TABS = [
  {
    title: 'roster dashboard',
    key: 'rosterDashboard',
    disabled: false,
  },
  {
    title: 'building tracking',
    key: 'buildingTracking',
    disabled: true,
  },
  {
    title: 'aerial tracking',
    key: 'aerialTracking',
    disabled: false,
  },
];

export const TOKEN_KEY = 'fire_token';

export const AUTH_TEXT = {
  returnToLogin: 'Return to Log In form',
  forgotPassword: 'Forgot Password',
  logIn: 'Log In',
  newPassword: 'New Password',
};

export const UNASSIGNED = 'unassigned';
export const NEW_VEHICLE = 'New Vehicle';
export const VITAL_WATCH = 'Vital Watch';
export const SUPPORT = 'Support';
