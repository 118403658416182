<template>
  <button :class="`rui-btn${checkType(buttonType)}`"
          @click.prevent="onClick"
          :disabled="disabled">
    <span v-if="buttonType !== classType.icon">{{buttonText}}</span>
    <slot name="icon"/>
  </button>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import {BUTTON_TYPE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'rui-button',
  emits: ['clickFromButtonComponent'],
  props: {
    buttonText: {
      type: String,
    },
    buttonType: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      classType: BUTTON_TYPE,
    };
  },
  methods: {
    checkType(type: string) {
      switch (type) {
        case this.classType.primaryO:
          return '--primary-o';
        case this.classType.accent:
          return '--accent';
        case this.classType.primary:
          return '--primary';
        case this.classType.icon:
          return '--icon';
        default:
          return '--primary-o';
      }
    },
    onClick(value: string) {
      this.$emit('clickFromButtonComponent', value);
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'button';
</style>
