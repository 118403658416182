<template>
  <div class="fire-filter">
    <rui-icon icon="location"/>
    <span class="fire-filter__location">{{data}}</span>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

export default defineComponent({
  name: 'fire-filter',
  props: {
    data: {
      type: String,
    },
  },
  components: {
    RuiIcon,
  },
});
</script>

<style lang="scss" scoped>
@import 'filter';
</style>
