
import {defineComponent} from 'vue';

import {mapActions, mapGetters} from 'vuex';
import {VehiclesModuleKey} from '@/app/data-flow/vehicles';
import {GetterTypesFromVehicles} from '@/app/data-flow/vehicles/types';
import {IncidentListModuleKey} from '@/app/data-flow/incident';
import {GetterTypesFromIncidentList} from '@/app/data-flow/incident/types';
import {MembersModuleKey} from '@/app/data-flow/members';

import {BUTTON_TEXT} from '@/app/core/constants';

import {
  IncidentListItem,
  ParState,
  VehicleGroup,
  VehicleListItem,
} from '@/app/core/interfaces';

import RuiPageLayout from '@/retinus-ui/components/layout/pageLayout/RuiPageLayout.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiBadge from '@/retinus-ui/components/badge/RuiBadge.vue';
import RuiNotify from '@/retinus-ui/components/notify/RuiNotify.vue';
import FireVehicleCard from '@/app/views/on-scene/components/vehicle-card/FireVehicleCard.vue';
import FireEditModal from '@/app/views/on-scene/components/vehicle-modal/FireEditModal.vue';
import FireVehicleArrived from '@/app/views/on-scene/components/vehicle-arrived/FireVehicleArrived.vue';
import OnSceneTools from '@/app/views/on-scene/OnSceneTools';
import {BUTTON_TYPE, LIST_SPACES} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'fire-on-scene-page',
  components: {
    RuiPageLayout,
    RuiIcon,
    RuiBadge,
    RuiButton,
    RuiNotify,
    FireEditModal,
    FireVehicleCard,
    FireVehicleArrived,
  },
  computed: {
    ...mapGetters(VehiclesModuleKey, {
      vehicleListState: GetterTypesFromVehicles.VEHICLE_LIST,
      vehicleGroupsState: GetterTypesFromVehicles.VEHICLE_GROUPS,
      membersNumState: GetterTypesFromVehicles.VEHICLE_LIST_MEMBERS_NUM,
      vehiclesParState: GetterTypesFromVehicles.VEHICLE_START_PAR_STATE,
    }),
    ...mapGetters(IncidentListModuleKey, {
      currentIncidentState: GetterTypesFromIncidentList.CURRENT_INCIDENT,
    }),
  },
  data() {
    return {
      expandStatus: false,
      vehicleGroups: [],
      buttonText: BUTTON_TEXT,
      startParBtnType: BUTTON_TYPE.primary,
      buttonTypeIcon: BUTTON_TYPE.icon,
      modalVisible: false,
      vehicleListItem: undefined,
      activeCard: undefined,
      currentIncident: this.currentIncidentState as unknown as IncidentListItem,
      listSpaces: LIST_SPACES.sm,
      checkedVehicle: 0,
      parState: undefined,
      arrivedVehicle: '',
    } as {
      expandStatus: boolean;
      vehicleGroups: VehicleGroup[];
      vehicleListItem?: VehicleListItem;
      currentIncident?: IncidentListItem;
      parState: ParState;
      activeCard?: VehicleListItem;
      checkedVehicle: number;
      modalVisible: boolean;
      arrivedVehicle: string;
    };
  },
  methods: {
    ...mapActions(VehiclesModuleKey, [
      'setStartParState',
      'setCancelParState',
      'setPauseParState',
      'loadVehicleList',
      'addArrivedVehicleToList',
      'onRemoveHighlightFromVehicle',
    ]),
    ...mapActions(MembersModuleKey, ['loadMemberList']),

    watchVehicleGroupsState(val: VehicleGroup[]): void {
      this.vehicleGroups = val;

      if (!this.vehicleListItem) {
        return;
      }

      this.vehicleListItem = this.vehicleGroups.reduce(
        (res: VehicleListItem | undefined, group: VehicleGroup) => {
          const item = group.items.find((v: VehicleListItem) => v.vehicleId === this.vehicleListItem?.vehicleId);

          if (item) {
            return item;
          }

          return res;
        },
        undefined,
      );
    },

    watchCurrentIncidentState(val: IncidentListItem) {
      this.currentIncident = val;
    },

    watchParState(val: ParState) {
      this.parState = val;
    },

    /**
     * Toggle for each vehicleGroups.
     * @param {string} vehicleId - group index.
     */
    isActiveCard(vehicleId: string) {
      if (this.activeCard) {
        return this.activeCard.vehicleId === vehicleId;
      }
      return false;
    },

    /**
     * Flag indicating active/inactive vehicle..
     * @param {string} vehicleId - vehicleId in a vehicleList arr split by group.
     * @return {boolean} - "true" if the PAR state.ts(startParState)
     * in the state.ts is equal "true".
     */
    isActive(vehicleId: string): boolean {
      return this.parState !== 'START' || this.isActiveCard(vehicleId);
    },

    /**
     * Processing a click on the "START PAR" button.
     * - Vuex: sends information to the state.ts that the PAR process has started;
     * - Expands all groups of cards;
     * - Changes icons in vehicle to disabled checkboxes;
     * - Darkens all cards except the first in the list of the first group;
     */
    startParVehicleList(): void {
      this.setStartParState()
        .then(() => {
          this.expandStatus = false;
          this.toggleGroupAll();
        });
      const firstVehicleListItem = this.vehicleGroups[0].items[0];
      this.activeCard = firstVehicleListItem;
    },

    cancelParVehicleList(): void {
      this.setCancelParState();
      this.expandStatus = false;
      this.toggleGroupAll();
    },

    clickStarParButton() {
      const allChecked = this.checkedVehicle === this.vehicleListState?.length;

      if (!this.parState) {
        this.startParVehicleList();
      }

      if (this.parState === 'START' && this.checkedVehicle > 0 && !allChecked) {
        this.setPauseParState();
      }

      if (this.parState === 'PAUSE') {
        this.startParVehicleList();
      }

      if (this.parState === 'START' && this.checkedVehicle === 0) {
        this.cancelParVehicleList();
        this.toggleGroupAll();
      }
    },

    getButtonParText() {
      const allChecked = this.checkedVehicle === this.vehicleListState?.length;

      if (this.checkedVehicle > 0 && !allChecked && this.parState === 'START') {
        return BUTTON_TEXT.pausePar;
      }

      if (this.parState === 'START' && this.checkedVehicle === 0) {
        return BUTTON_TEXT.cancelPar;
      }

      return BUTTON_TEXT.startPar;
    },

    /**
     * Gets data about the Active card-border.
     * @param {Object} item - active card-border.
     */
    prepareStartParForVehicle(item: VehicleListItem) {
      if (this.parState) {
        this.activeCard = item;
      }
    },

    checkParComplete() {
      this.expandStatus = false;
      this.toggleGroupAll();
      this.checkedVehicle += 1;
      const noEmptyVehicleList = this.vehicleListState.filter(
        (v: VehicleListItem) => v.team.length > 0,
      );
      const checkedAllVehicles = this.checkedVehicle === noEmptyVehicleList.length;

      if (checkedAllVehicles) {
        this.checkedVehicle = 0;
        this.setCancelParState();
        this.loadVehicleList();
      }
    },

    showModal(vehicle: VehicleListItem) {
      this.vehicleListItem = vehicle;
      this.modalVisible = true;
      this.loadMemberList();
      this.onRemoveHighlightFromVehicle({vehicle});
    },

    closeModal() {
      this.vehicleListItem = undefined;
      this.modalVisible = false;
      this.arrivedVehicle = '';
    },

    vehicleArrived(vehicle: VehicleListItem) {
      this.showModal(vehicle);
      this.addArrivedVehicleToList({vehicle});
      this.arrivedVehicle = `This is a new vehicle.
      This was added to the vehicle list automatically, but you can remove it.`;
    },

    toggleGroup(groupIndex: number) {
      this.expandStatus = OnSceneTools.toggleGroup(groupIndex, this.vehicleGroups);
    },

    toggleGroupAll() {
      const {
        groupData,
        expand,
      } = OnSceneTools.toggleGroupAll(
        this.vehicleGroups,
        this.expandStatus,
      );
      this.expandStatus = expand;
      this.vehicleGroups = groupData;
    },
  },
  watch: {
    vehicleGroupsState: 'watchVehicleGroupsState',
    currentIncidentState: 'watchCurrentIncidentState',
    vehiclesParState: 'watchParState',
  },
  beforeMount() {
    this.watchCurrentIncidentState(this.currentIncidentState);
    this.watchVehicleGroupsState(this.vehicleGroupsState);
  },
});
