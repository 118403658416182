
import {defineComponent} from 'vue';

import {GOOGLE_MAPS_API_KEY} from '@/app/core/mockData';
import {GoogleMap, Marker} from 'vue3-google-map';
import {RosterOnMapItem} from '@/app/core/interfaces';

export default defineComponent({
  name: 'fire-map',
  components: {
    GoogleMap,
    Marker,
  },
  props: {
    rosterOnMapState: Object,
  },
  data() {
    return {
      googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    };
  },
  methods: {
    rosterMapOptions(roster: RosterOnMapItem) {
      const iconSize = 40;

      return {
        position: {
          lat: roster.lat,
          lng: roster.lng,
        },
        label: {
          text: roster.fullName,
          color: document.documentElement.style.getPropertyValue('--rui-color-white'),
          fontFamily: document.documentElement.style.getPropertyValue('--rui-font-family-botanika-medium'),
        },
        title: roster.fullName,
        icon: {
          url: roster.photo,
          scaledSize: {
            height: iconSize,
            width: iconSize,
          },
        },
      };
    },
  },
});
