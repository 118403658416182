// eslint-disable-next-line import/prefer-default-export
export const GetterTypesFromVehicles = {
  VEHICLE_LIST_MEMBERS_NUM: 'VEHICLE_LIST_MEMBERS_NUM',
  VEHICLE_LIST: 'VEHICLE_LIST',
  VEHICLE_LIST_LOADING_STATE: 'VEHICLE_LIST_LOADING_STATE',
  VEHICLE_LIST_ERROR_STATE: 'VEHICLE_LIST_ERROR_STATE',
  VEHICLE_GROUPS: 'VEHICLE_GROUPS',
  VEHICLE_START_PAR_STATE: 'ENGINES_START_PAR_STATE',

  ARRIVED_VEHICLE: 'ARRIVED_VEHICLE',
  ARRIVED_VEHICLE_LOADING_STATE: 'ARRIVED_VEHICLE_LOADING_STATE',
  ARRIVED_VEHICLE_ERROR_STATE: 'ARRIVED_VEHICLE_ERROR_STATE',
};
