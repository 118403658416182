import {IncidentListItem} from '@/app/core/interfaces';

export interface State {
  incidentCurrent?: IncidentListItem;
  loading: boolean;
  error?: Error;
}

export const initialState = (): State => ({
  incidentCurrent: undefined,
  loading: false,
  error: undefined,
});
