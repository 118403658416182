
import {defineComponent, ref} from 'vue';

export default defineComponent({
  name: 'rui-dynamic-input',
  emits: ['grabText'],
  props: {
    modelValue: String,
  },
  setup() {
    const dynamicInput = ref(null);
    return {dynamicInput};
  },
  updated() {
    if (!this.modelValue) {
      (this.dynamicInput as unknown as HTMLElement).style.height = 'var(--rui-input-height)';
    }
  },
  methods: {
    onChange(target: HTMLTextAreaElement) {
      this.$emit('grabText', target.value);
      (this.dynamicInput as unknown as HTMLElement).style.height = `${target.scrollHeight}px`;
    },
  },
});
