import {COLOR_TYPE} from '@/retinus-ui/core/constants';

export default class RuiTools {
  public static setColorStyle(type: string) {
    switch (type) {
      case COLOR_TYPE.success:
        return '--success';
      case COLOR_TYPE.danger:
        return '--danger';
      case COLOR_TYPE.info:
        return '--info';
      case COLOR_TYPE.warning:
        return '--warning';
      default:
        return '';
    }
  }
}
