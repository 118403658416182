import {IncidentListItem} from '@/app/core/interfaces';
import axios from 'axios';

import {BASE_URL} from '@/app/core/constants';

export default class IncidentListDataProvider {
  /**
   * GET incident/current.
   */
  // TODO: mock data.
  // getCurrentIncident(): Promise<IncidentListItem> {
  //   return new Promise((resolve) => {
  //     setTimeout(() => resolve(CURRENT_INCIDENT_DATA), 2000);
  //   });
  // }
  async getCurrentIncident(): Promise<IncidentListItem> {
    const res = await axios.get<IncidentListItem>(`${BASE_URL}/incidents/current`);

    return res.data || {};
  }
}
