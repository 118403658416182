import axios from 'axios';

import {SupportListItem} from '@/app/core/interfaces';
import {BASE_URL} from '@/app/core/constants';

// TODO: mock data - const newID = Math.random();

export default class SupportListDataProvider {
  /**
   * POST incidents/:id/support.
   */
  // TODO: mock data.
  // addMessage(message: { subject: string; description: string }): Promise<SupportListItem> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve({
  //       id: `${newID}`,
  //       date: '2021-02-26T22:49:56+00:00',
  //       subject: message.subject,
  //       description: message.description,
  //     }), 1000);
  //   });
  // }
  async addMessage(member: { subject: string; description: string }, incidentId: string): Promise<void> {
    await axios.post<SupportListItem>(`${BASE_URL}/incidents/${incidentId}/support`, member);
  }
}
