import {ActionContext} from 'vuex';
import {State} from '@/app/data-flow/incident/state';
import IncidentListDataProvider from '@/app/data-flow/incident/IncidentListDataProvider';

const incidentListDataProvider = new IncidentListDataProvider();

// eslint-disable-next-line import/prefer-default-export
export const actions = {
  loadIncidentCurrent(context: ActionContext<State, unknown>): Promise<void> {
    context.commit('loadIncidentCurrent');

    return incidentListDataProvider.getCurrentIncident()
      .then((response) => {
        context.commit('loadIncidentCurrentSuccess', {data: response});
        return response;
      })
      .catch((error) => {
        context.commit('loadIncidentCurrentFail', {error});
        return error;
      });
  },
};
