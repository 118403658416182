<template>
  <div class="rui-input"
       :class="{'is-invalid': invalid}">
    <div class="rui-input__dropdown"
         v-if="$slots.dropdown">
      <slot name="dropdown"/>
    </div>
    <div class="rui-input__field">
      <input v-bind="$attrs"
             @input="onChange($event.target.value)"
             :value="modelValue"/>
      <div class="rui-clear"
           v-if="modelValue"
           @click="clear()">
        <rui-icon icon="close"/>
      </div>
    </div>
    <div class="rui-input__buttons"
         v-if="$slots.buttons">
      <slot name="buttons"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

export default defineComponent({
  name: 'rui-input',
  emits: ['changeFromInputComponent'],
  components: {
    RuiIcon,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(value: string) {
      this.$emit('changeFromInputComponent', value);
    },

    clear() {
      this.$emit('changeFromInputComponent', '');
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'input';
</style>
