<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 24 24"
       :class="className">
    <keep-alive>
      <component :is="dynamicIcon"></component>
    </keep-alive>
  </svg>
</template>

<script>
import icons from './svg/index';

export default {
  name: 'rui-icon',
  components: {
    ...icons,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    className: {
      type: String,
    },
  },
  computed: {
    dynamicIcon() {
      return `rui-${this.icon}`;
    },
  },
};
</script>

<style scoped>
svg {
  display: block;
  width: var(--rui-svg-size-base);
  min-width: var(--rui-svg-size-base);
  height: var(--rui-svg-size-base);
  overflow: hidden;
  vertical-align: middle;
  fill: currentColor;
}
</style>
