import {COLOR_TYPE} from '@/retinus-ui/core/constants';
import {HAZMAT, OXYGEN_LEVEL} from '@/app/core/constants';

export default class VitalWatchTools {
  public static setColorStyle(type: string) {
    switch (type) {
      case COLOR_TYPE.success:
      case OXYGEN_LEVEL.high:
      case HAZMAT.ok:
        return '--success';
      case COLOR_TYPE.danger:
      case OXYGEN_LEVEL.low:
        return '--danger';
      case COLOR_TYPE.warning:
      case OXYGEN_LEVEL.medium:
      case HAZMAT.tolerable:
        return '--warning';
      default:
        return '';
    }
  }
}
