import {getters} from '@/app/data-flow/members/getters';
import {actions} from '@/app/data-flow/members/actions';
import {mutations} from '@/app/data-flow/members/mutations';
import {initialState} from '@/app/data-flow/members/state';

export const MembersModuleKey = 'MembersModule';

export const MembersModule = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialState,
};
