
import { defineComponent } from 'vue';

import RuiModal from '@/retinus-ui/components/modal/RuiModal.vue';

import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiListFlat from '@/retinus-ui/components/list-flat/RuiListFlat.vue';
import {BUTTON_TYPE, MODAL_CONFIRM_BTN, MODAL_CONFIRM_TITLE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'fire-modal-confirm',
  components: {
    RuiModal,
    RuiButton,
    RuiListFlat,
  },
  props: {
    name: {
      type: String,
      default: MODAL_CONFIRM_TITLE,
    },
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      confirmBtn: MODAL_CONFIRM_BTN,
      buttonTypeConfirm: BUTTON_TYPE.primary,
    };
  },
});
