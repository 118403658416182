
import { Options, Vue } from 'vue-class-component';
import Chart from 'chart.js';
import { CO2Data } from '@/app/core/mockData';

@Options({
  name: 'co2',
  data() {
    return {
      CO2Data,
    };
  },
  methods: {
    createChart(
      chartId: HTMLCanvasElement,
      chartData: { type: string; data: object; options: object },
    ) {
      // eslint-disable-next-line no-unused-vars
      const myChart = new Chart(chartId, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
      });
    },
  },
  mounted() {
    this.createChart('co2', this.CO2Data);
  },
})
export default class CO2 extends Vue {}
