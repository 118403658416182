<template>
  <svg class="rui-spinner"
       viewBox="0 0 50 50"
       :height="size"
       :width="size"
       xmlns="http://www.w3.org/2000/svg">
    <circle class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-linecap="round"
            stroke-width="4"></circle>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'rui-spinner',
  props: {
    size: {
      type: String,
      default: '40',
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'spinner';
</style>
