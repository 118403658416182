import {GetterTypesFromIncidentList} from '@/app/data-flow/incident/types';
import {State} from '@/app/data-flow/incident/state';

// eslint-disable-next-line import/prefer-default-export
export const getters = {
  [GetterTypesFromIncidentList.CURRENT_INCIDENT_LOADING_STATE](state: State) {
    return state.loading;
  },
  [GetterTypesFromIncidentList.CURRENT_INCIDENT_ERROR_STATE](state: State) {
    return state.error;
  },
  [GetterTypesFromIncidentList.CURRENT_INCIDENT](state: State) {
    return state.incidentCurrent;
  },
  [GetterTypesFromIncidentList.CURRENT_INCIDENT_ID](state: State) {
    return state.incidentCurrent?.incidentId;
  },
};
