<template>
  <div class="rui-backdrop"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'rui-backdrop',
});
</script>

<style lang="scss" scoped>
@import "backdrop";
</style>
