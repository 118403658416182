
import {defineComponent} from 'vue';
import RuiDate from '@/retinus-ui/components/date/RuiDate.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

import {NoteListItem} from '@/app/core/interfaces';
import {BUTTON_TYPE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'fire-note-list',
  emits: ['deleteNoteItem'],
  components: {
    RuiDate,
    RuiButton,
    RuiIcon,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      buttonTypeIcon: BUTTON_TYPE.icon,
    };
  },
  methods: {
    onClick(value: NoteListItem) {
      this.$emit('deleteNoteItem', value);
    },
  },
});
