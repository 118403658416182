<template>
  <section>
    <h1>{{ userName }} | {{ userRole }}</h1>
    <img :src=userPhoto
         alt="Profile Photo"
         style="width: 200px">
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
      Aliquid asperiores, assumenda, autem corporis cumque cupiditate earum,
      id iste magnam molestias numquam quas reiciendis reprehenderit saepe similique.
      Incidunt odit perspiciatis saepe?
    </p>
  </section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import {mapGetters} from 'vuex';
import {GetterTypes} from '@/app/data-flow/types';
import {UserModuleKey} from '@/app/data-flow/user';

export default defineComponent({
  name: 'fire-profile-page',
  computed: {
    ...mapGetters(UserModuleKey, {
      userPhoto: GetterTypes.USER_PROFILE_PICTURE_BIG,
      userRole: GetterTypes.USER_PROFILE_ROLE,
      userName: GetterTypes.USER_PROFILE_NAME,
    }),
  },
});
</script>
