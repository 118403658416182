<template>
  <rui-alert class="fire-scba__alert"
             :text="`${message.oxygenLevel} ${roster.scba.oxygenLevel}`"
             v-if="alertVisible()"
             :type="setAlertColor(roster.scba.oxygenLevel)"/>
  <div class="fire-scba">
    <div class="fire-scba__col">
      <rui-icon class="fire-scba__ico"
                icon="scba"></rui-icon>
      <div class="fire-scba__info">
        <div class="fire-title">Mask</div>
        <div class="fire-level--success">Normal</div>
      </div>
    </div>
    <div class="fire-scba__col">
      <rui-icon class="fire-scba__ico"
                icon="balon"></rui-icon>
      <div class="fire-scba__info">
        <div class="fire-title">Oxygen</div>
        <div :class="`fire-level--${setAlertColor(roster.scba.oxygenLevel)}`">{{ roster.scba.oxygenLevel }} -
          {{ roster.oxygen }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiAlert from '@/retinus-ui/components/alert/RuiAlert.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

import {ALERT_TEXT, OXYGEN_LEVEL} from '@/app/core/constants';

export default defineComponent({
  name: 'fire-scba-graph',
  props: {
    roster: Object,
  },
  components: {
    RuiAlert,
    RuiIcon,
  },
  data() {
    return {
      message: ALERT_TEXT,
      oxygenLevelType: OXYGEN_LEVEL,
    };
  },
  methods: {
    setAlertColor(type: string) {
      switch (type) {
        case OXYGEN_LEVEL.low:
          return 'danger';
        case OXYGEN_LEVEL.high:
          return 'success';
        case OXYGEN_LEVEL.medium:
          return 'warning';
        default:
          return '';
      }
    },

    alertVisible() {
      return this.roster?.scba.oxygenLevel.toLowerCase() !== this.oxygenLevelType.high;
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'scba';
</style>
