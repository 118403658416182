import axios from 'axios';

import {AnalyticalListItem} from '@/app/core/interfaces';
import {BASE_URL} from '@/app/core/constants';

export default class AnalyticListDataProvider {
  /**
   * GET analytics/:incidentId/:watchId.
   */
  // TODO: mock data.
  // getAnalyticalData(watchId: string, incidentId: string): Promise<AnalyticalListItem> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve(ANALYTICAL_LIST_DATA), 1000);
  //   });
  // }
  async getAnalyticalData(watchId: string, incidentId: string): Promise<AnalyticalListItem> {
    const res = await axios.get<AnalyticalListItem>(`${BASE_URL}/analytics/${incidentId}/${watchId}`);

    return res.data || {};
  }
}
