
import {defineComponent} from 'vue';
import RuiModalTools from '@/retinus-ui/components/modal/RuiModalTools';
import RuiBackdrop from '@/retinus-ui/components/backdrop/RuiBackdrop.vue';

export default defineComponent({
  name: 'rui-modal',
  components: {
    RuiBackdrop,
  },
  props: {
    title: {
      type: String,
    },
    confirmation: {
      type: Boolean,
    },
    footerTextDirection: {
      type: Boolean,
    },
  },
  data() {
    return {
      RuiModalTools,
    };
  },
  mounted() {
    this.RuiModalTools.focusTrap();
  },
});
