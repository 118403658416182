import {createLogger, createStore} from 'vuex';

import {UserModule, UserModuleKey} from '@/app/data-flow/user';
import {VehiclesModuleKey, VehiclesModule} from '@/app/data-flow/vehicles';
import {RostersModule, RostersModuleKey} from '@/app/data-flow/rosters';
import {IncidentListModule, IncidentListModuleKey} from '@/app/data-flow/incident';
import {NotesModule, NotesModuleKey} from '@/app/data-flow/notes';
import {SupportModule, SupportModuleKey} from '@/app/data-flow/support';
import {MembersModule, MembersModuleKey} from '@/app/data-flow/members';
import {AuthModule, AuthModuleKey} from '@/app/data-flow/auth';
import {AnalyticsModule, AnalyticsModuleKey} from './analytic';

import {GetterTypes} from './types';
import {GeoModuleKey, GeoModule} from './geo';

const debug = process.env.NODE_ENV !== 'production';

const getters = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypes.GET_PATH](state, allGetters, rootState) {
    return rootState.RouteModule?.path;
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypes.GET_PARAMS](state, allGetters, rootState) {
    return rootState.RouteModule?.params;
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypes.GET_ROUTER_NAME](state, allGetters, rootState) {
    return rootState.RouteModule?.name;
  },
};

export default createStore({
  getters,
  modules: {
    [UserModuleKey]: UserModule,
    [VehiclesModuleKey]: VehiclesModule,
    [MembersModuleKey]: MembersModule,
    [RostersModuleKey]: RostersModule,
    [IncidentListModuleKey]: IncidentListModule,
    [NotesModuleKey]: NotesModule,
    [SupportModuleKey]: SupportModule,
    [AuthModuleKey]: AuthModule,
    [AnalyticsModuleKey]: AnalyticsModule,
    [GeoModuleKey]: GeoModule,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
