
import {defineComponent} from 'vue';

import RuiInput from '@/retinus-ui/components/input/RuiInput.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiDropdown from '@/retinus-ui/components/dropdown/RuiDropdown.vue';
import {BUTTON_TYPE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'fire-edit-form',
  components: {
    RuiInput,
    RuiIcon,
    RuiButton,
    RuiDropdown,
  },
  props: {
    placeholderText: String,
    modelValue: String,
    disableEdit: Boolean,
    selectedDropdownOption: String,
    options: Array,
  },
  data() {
    return {
      buttonTypeIcon: BUTTON_TYPE.icon,
    };
  },
});
