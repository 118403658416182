<template>
  <rui-auth-wrapper :title="authText.logIn">
    <template v-slot:body>
      <rui-alert v-if="errorState"
                 :text="errorState.message"
                 :hint-mode="true"
                 :fixed="true"
                 type="danger"/>
      <div class="rui-auth__control">
        <rui-input type="email"
                   required
                   :invalid="invalid.email"
                   :modelValue="field.email"
                   @changeFromInputComponent="changeEmail($event)"
                   :placeholder="label.userEmail"/>
        <rui-alert v-if="invalid.email"
                   :text="hint.email"
                   :hint-mode="true"
                   type="danger"/>
      </div>
      <div class="rui-auth__control">
        <rui-input :type="passwordFieldType"
                   required
                   :invalid="invalid.password"
                   :modelValue="field.password"
                   @changeFromInputComponent="changePassword($event)"
                   :placeholder="label.userPassword">
          <template v-slot:buttons>
            <rui-button :buttonType="buttonType.icon"
                        type="password"
                        @click="switchVisibility">
              <template v-slot:icon>
                <rui-icon :icon="passwordFieldType === 'password' ? 'eyeClosed' : 'eye'"/>
              </template>
            </rui-button>
          </template>
        </rui-input>
        <rui-alert v-if="invalid.password"
                   :text="hint.password"
                   :hint-mode="true"
                   type="danger"/>
      </div>
      <rui-button class="rui-auth__btn"
                  :buttonText="buttonText.submit"
                  :disabled="disableSubmitButton()"
                  @clickFromButtonComponent="submitForm()"
                  :button-type="buttonType.primary"
                  type="submit"/>
      <rui-button class="rui-auth__btn"
                  :buttonText="buttonText.reset"
                  :disabled="disableResetButton()"
                  @clickFromButtonComponent="resetForm()"/>
      <p>
        <router-link :to="{name: 'forgot-password'}">{{ authText.forgotPassword }} ?</router-link>
      </p>
    </template>
  </rui-auth-wrapper>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiInput from '@/retinus-ui/components/input/RuiInput.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiAlert from '@/retinus-ui/components/alert/RuiAlert.vue';
import RuiAuthWrapper from '@/retinus-ui/components/auth/RuiAuthWrapper.vue';

import {ALERT_TEXT, AUTH_TEXT, BUTTON_TEXT, PLACEHOLDER_TEXT} from '@/app/core/constants';
import {BUTTON_TYPE, VALIDATE_HINT} from '@/retinus-ui/core/constants';
import Validator from '@/retinus-ui/core/validator';

import {mapActions, mapGetters} from 'vuex';
import {AuthModuleKey, GetterTypesFromAuth} from '@/app/data-flow/auth';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

export default defineComponent({
  name: 'login',
  components: {
    RuiButton,
    RuiInput,
    RuiAlert,
    RuiAuthWrapper,
    RuiIcon,
  },
  data() {
    return {
      passwordFieldType: 'password',
      buttonType: BUTTON_TYPE,
      field: {
        email: '',
        password: '',
      },
      alert: ALERT_TEXT,
      hint: VALIDATE_HINT,
      formComplete: false,
      authText: AUTH_TEXT,
      label: PLACEHOLDER_TEXT,
      buttonText: BUTTON_TEXT,
      invalid: {
        email: false,
        password: false,
      },
      previousPassword: '',
      previousEmail: '',
    };
  },
  computed: {
    ...mapGetters(AuthModuleKey, {
      errorState: GetterTypesFromAuth.ERROR,
    }),
  },
  methods: {
    ...mapActions(AuthModuleKey, ['login', 'hideError']),

    changeEmail(value: string) {
      this.invalid.email = false;
      this.field.email = value;
      this.hideServerErrorAlert();
    },
    changePassword(value: string) {
      this.invalid.password = false;
      this.field.password = value;
      this.hideServerErrorAlert();

      // Hiding the password after clearing the field.
      if (this.field.password === '') {
        this.passwordFieldType = 'password';
      }
    },
    resetForm() {
      this.field.password = '';
      this.field.email = '';
      this.previousEmail = '';
      this.previousPassword = '';
      this.invalid.password = false;
      this.invalid.email = false;
      this.passwordFieldType = 'password';
    },
    submitForm() {
      this.previousEmail = this.field.email;
      this.previousPassword = this.field.password;
      if (!Validator.passwordValid(this.field.password)) {
        this.invalid.password = true;
      }
      if (!Validator.emailValid(this.field.email)) {
        this.invalid.email = true;
      }

      if (Validator.passwordValid(this.field.password) && Validator.emailValid(this.field.email)) {
        const user = {
          email: this.field.email,
          password: this.field.password,
        };
        this.login({user})
          .then(() => {
            this.formComplete = true;
            this.$router.push({name: 'on-scene'});
          });
      }
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    hideServerErrorAlert() {
      if (this.errorState) {
        this.hideError();
      }
    },
    disableSubmitButton() {
      const anyFieldEmpty = !this.field.email || !this.field.password;
      const formPristine = !this.passwordChanged() && !this.emailChanged();

      return (anyFieldEmpty || this.formComplete) && (anyFieldEmpty || formPristine);
    },
    passwordChanged() {
      return this.previousPassword !== this.field.password;
    },
    emailChanged() {
      return this.previousEmail !== this.field.email;
    },
    disableResetButton() {
      return !this.field.email && !this.field.password;
    },
  },
});
</script>
