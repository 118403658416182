
import {defineComponent} from 'vue';

import RuiAlert from '@/retinus-ui/components/alert/RuiAlert.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

import {ALERT_TEXT, OXYGEN_LEVEL} from '@/app/core/constants';

export default defineComponent({
  name: 'fire-scba-graph',
  props: {
    roster: Object,
  },
  components: {
    RuiAlert,
    RuiIcon,
  },
  data() {
    return {
      message: ALERT_TEXT,
      oxygenLevelType: OXYGEN_LEVEL,
    };
  },
  methods: {
    setAlertColor(type: string) {
      switch (type) {
        case OXYGEN_LEVEL.low:
          return 'danger';
        case OXYGEN_LEVEL.high:
          return 'success';
        case OXYGEN_LEVEL.medium:
          return 'warning';
        default:
          return '';
      }
    },

    alertVisible() {
      return this.roster?.scba.oxygenLevel.toLowerCase() !== this.oxygenLevelType.high;
    },
  },
});
