import {VehicleListItem} from '@/app/core/interfaces';
import axios from 'axios';

import {BASE_URL} from '@/app/core/constants';

// TODO: mock data - const newID = Math.random();

export default class VehicleListDataProvider {
  /**
   * GET incidents/:id/vehicles.
   */
  // TODO: mock data.
  // getVehicleList(): Promise<VehicleListItem[]> {
  //   return new Promise((resolve) => {
  //     setTimeout(() => resolve(VEHICLE_LIST_DATA), 2000);
  //   });
  // }
  async getVehicleList(incidentId: string): Promise<VehicleListItem[]> {
    const res = await axios.get<VehicleListItem[]>(`${BASE_URL}/incidents/${incidentId}/vehicles`);

    return res.data || [];
  }

  /**
   * PUT incidents/:id/vehicles/:vehicleId.
   */
  // TODO: mock data.
  // updateVehicle(vehicle: VehicleListItem): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve(), 1000);
  //   });
  // }
  async updateVehicle(vehicle: VehicleListItem, incidentId: string): Promise<void> {
    await axios.put<VehicleListItem>(`${BASE_URL}/incidents/${incidentId}/vehicles/${vehicle.vehicleId}`, vehicle);
  }

  /**
   * DELETE incidents/:id/vehicles/:id.
   */
  // TODO: mock data.
  // deleteVehicle(vehicleId: string): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve(), 1000);
  //   });
  // }
  async deleteVehicle(vehicleId: string, incidentId: string): Promise<void> {
    await axios.delete<VehicleListItem>(`${BASE_URL}/incidents/${incidentId}/vehicles/${vehicleId}`);
  }

  /**
   * POST incidents/:id/vehicles.
   */
  // TODO: mock data.
  // addVehicle(vehicle: { name: string }): Promise<VehicleListItem> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve({
  //       vehicleId: `${newID}`,
  //       assignment: '',
  //       name: vehicle.name,
  //       par: 0,
  //       lastPar: '',
  //       team: [],
  //     }), 1000);
  //   });
  // }
  async addVehicle(vehicle: { name: string }, incidentId: string): Promise<VehicleListItem> {
    const res = await axios.post<VehicleListItem>(`${BASE_URL}/incidents/${incidentId}/vehicles`, vehicle);

    return res.data || {};
  }

  /**
   * POST incidents/:id/vehicles/:id/par.
   */
  // TODO: mock data.
  // updateVehiclePar(vehicleId: string, par: number): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve(), 1000);
  //   });
  // }
  async updateVehiclePar(vehicleId: string, par: number, incidentId: string): Promise<void> {
    await axios.post<VehicleListItem>(`${BASE_URL}/incidents/${incidentId}/vehicles/${vehicleId}/par`, {
      incidentId,
      vehicleId,
      par,
    });
  }

  /**
   * GET incidents/:id/vehicles/:vid/addVehicle.
   */
  // TODO: mock data.
  // getArrivedVehicle(vehicleId: string, incidentId: string): Promise<VehicleListItem> {
  //   return new Promise((resolve) => {
  //     setTimeout(() => resolve(ARRIVED_VEHICLE), 1000);
  //   });
  // }
  async getArrivedVehicle(vehicleId: string, incidentId: string): Promise<VehicleListItem> {
    const res = await axios.get<VehicleListItem>(`${BASE_URL}/incidents/${incidentId}/vehicles/${vehicleId}/pullVehicle`);

    return res.data || {};
  }
}
