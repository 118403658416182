
import {defineComponent} from 'vue';

import RuiModal from '@/retinus-ui/components/modal/RuiModal.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiListFlat from '@/retinus-ui/components/list-flat/RuiListFlat.vue';
import RuiAlert from '@/retinus-ui/components/alert/RuiAlert.vue';

import {
  MSQ_ASSIGN_CONFIRM,
  MSQ_REMOVE_CONFIRM,
  PLACEHOLDER_TEXT,
  RANK_ORDER,
} from '@/app/core/constants';

import OnSceneTools from '@/app/views/on-scene/OnSceneTools';

import {MemberListItem, VehicleListItem} from '@/app/core/interfaces';

import {mapActions, mapGetters} from 'vuex';
import {VehiclesModuleKey} from '@/app/data-flow/vehicles';
import {MembersModuleKey} from '@/app/data-flow/members';
import {GetterTypesFromMembers} from '@/app/data-flow/members/types';

import FireMemberList from '@/app/views/on-scene/components/vehicle-modal/FireMemberList.vue';
import FireModalConfirm from '@/app/components/modal/FireModalConfirm.vue';
import FireVehicleList from '@/app/views/on-scene/components/vehicle-modal/FireVehicleList.vue';
import FireEditForm from '@/app/views/on-scene/components/vehicle-modal/FireEditForm.vue';
import FireAssignmentList from '@/app/views/on-scene/components/vehicle-modal/FireAssignmentList.vue';
import RuiNotify from '@/retinus-ui/components/notify/RuiNotify.vue';
import FireAssignmentLabel from '@/app/views/on-scene/components/assignment-label/FireAssignmentLabel.vue';

import {BTN_CONTENT, BUTTON_TYPE, DIRECTION} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'fire-edit-modal',
  components: {
    RuiModal,
    RuiIcon,
    RuiAlert,
    RuiButton,
    RuiListFlat,
    RuiNotify,
    FireAssignmentList,
    FireMemberList,
    FireModalConfirm,
    FireVehicleList,
    FireEditForm,
    FireAssignmentLabel,
  },
  props: {
    item: Object,
    itemList: Array,
    message: String,
  },
  data() {
    return {
      btnContent: BTN_CONTENT,
      listFlatDir: DIRECTION.right,
      buttonTypeIcon: BUTTON_TYPE.icon,

      placeholderMember: PLACEHOLDER_TEXT.editMemberName,
      placeholderVehicle: PLACEHOLDER_TEXT.editVehicleName,

      memberChangedName: '',
      vehicleChangedName: '',
      vehicleNewName: '',
      showConfirmation: false,

      deleteMemberId: '',
      msqConfirm: '',
      memberDeleteConfirm: false,
      vehicleDeleteConfirm: false,

      editMemberMode: [],
      editVehicleMode: false,
      moveMemberMode: false,
      addMemberMode: false,
      addNewMemberMode: false,
      addNewVehicleMode: false,

      currentMember: undefined,
      currentMemberToMove: undefined,
      currentMemberToMoveNewVehicle: undefined,

      newMemberName: '',
      newMemberRank: '',

      disableEditName: true,
      dropdownMemberRank: '',
      dropdownOptions: RANK_ORDER,
    } as {
      currentMember?: MemberListItem;
      currentMemberToMove?: MemberListItem;
      currentMemberToMoveNewVehicle?: VehicleListItem;
      addMemberMode: boolean;
      editMemberMode: boolean[];
      editVehicleMode: boolean;
      showConfirmation: boolean;
      vehicleDeleteConfirm: boolean;
      memberDeleteConfirm: boolean;
      addNewMemberMode: boolean;
      moveMemberMode: boolean;
      addNewVehicleMode: boolean;
      newMemberName: string;
      newMemberRank: string;
      memberChangedName: string;
      vehicleChangedName: string;
      vehicleNewName: string;
      msqConfirm: string;
      deleteMemberId: string;
      disableEditName: boolean;
      dropdownMemberRank: string;
      dropdownOptions: string[];
    };
  },

  methods: {
    ...mapActions(VehiclesModuleKey, [
      'updateVehicle',
      'deleteMember',
      'deleteVehicle',
      'addVehicle',
    ]),
    ...mapActions(MembersModuleKey, [
      'loadMemberList',
      'updateVehicleMember',
      'deleteMember',
      'assignMember',
      'addMember',
    ]),

    checkRank(rank: string) {
      return OnSceneTools.checkRank(rank);
    },

    vehicleEdit() {
      this.vehicleChangedName = this.item?.name;
      this.editVehicleMode = true;
    },

    vehicleEditCancel() {
      this.editVehicleMode = false;
      this.vehicleChangedName = '';
    },

    vehicleEditDone() {
      const changedVehicle = {
        ...this.item,
        name: this.vehicleChangedName,
      };
      this.updateVehicle({vehicle: changedVehicle})
        .then(() => {
          this.editVehicleMode = false;
          this.vehicleChangedName = '';
          this.disableEditName = true;
        });
    },

    vehicleAddCancel() {
      this.addNewVehicleMode = false;
      this.vehicleNewName = '';
    },

    vehicleAddDone() {
      if (this.vehicleNewName) {
        this.addVehicle({
          name: this.vehicleNewName,
        })
          .then(() => {
            this.addNewVehicleMode = false;
            this.disableEditName = true;
            this.vehicleNewName = '';
          });
      }
    },

    vehicleDelete() {
      this.msqConfirm = `${MSQ_REMOVE_CONFIRM} ${this.item?.name} ?`;
      this.showConfirmation = true;
      this.vehicleDeleteConfirm = true;
    },

    modalConfirm() {
      this.showConfirmation = false;

      // Delete Member.
      if (this.memberDeleteConfirm) {
        this.memberDeleteConfirm = false;
        this.deleteMember({
          memberId: this.deleteMemberId,
          vehicleId: this.item?.vehicleId as string,
        })
          .then(() => {
            this.loadMemberList();
            this.deleteMemberId = '';
          });
      }

      // Delete Vehicle.
      if (this.vehicleDeleteConfirm) {
        this.vehicleDeleteConfirm = false;
        this.deleteVehicle({
          vehicleId: this.item?.vehicleId as string,
        });
      }

      // Move Member from MemberList to current Vehicle.
      if (this.currentMember) {
        this.moveMemberToVehicle(this.currentMember)
          .then(() => {
            this.currentMember = undefined;
          });
      }

      // Move Member from current Vehicle to a new.
      if (this.currentMemberToMove) {
        this.moveMemberToAnyVehicle(this.currentMemberToMove, this.currentMemberToMoveNewVehicle)
          .then(() => {
            this.currentMemberToMoveNewVehicle = undefined;
            this.currentMemberToMove = undefined;
            this.moveMemberMode = false;
          });
      }
    },

    modalConfirmCancel() {
      this.showConfirmation = false;
      this.msqConfirm = '';

      // Member.
      if (this.memberDeleteConfirm) {
        this.deleteMemberId = '';
      }

      // Move Member from MemberList to current vehicle.
      if (this.currentMember) {
        this.currentMember = undefined;
      }

      // Move Member from current vehicle to a new one.
      if (this.currentMemberToMove) {
        this.currentMemberToMove = undefined;
        this.moveMemberMode = false;
      }
    },

    memberEdit(index: number) {
      this.editMemberMode.forEach((item, idx) => {
        this.editMemberMode[idx] = false;
      });
      this.editMemberMode[index] = true;
      this.memberChangedName = this.item?.team[index]?.fullName;
    },

    memberEditCancel(index: number) {
      this.editMemberMode[index] = false;
      this.dropdownMemberRank = '';
      this.memberChangedName = '';
      this.disableEditName = true;
    },

    memberEditDone(member: MemberListItem, index: number) {
      const changedMember = {
        ...member,
        fullName: this.memberChangedName,
        rank: this.dropdownMemberRank ? this.dropdownMemberRank : member.rank,
      };
      this.updateVehicleMember({
        member: changedMember,
        vehicleId: this.item?.vehicleId as string,
      })
        .then(() => {
          this.editMemberMode[index] = false;
          this.disableEditName = true;
          this.dropdownMemberRank = '';
          this.memberChangedName = '';
        });
    },

    memberDelete(member: MemberListItem) {
      this.msqConfirm = `${MSQ_REMOVE_CONFIRM}
      ${this.checkRank(member.rank)}
      ${member.fullName} ?`;

      this.deleteMemberId = member.id;
      this.showConfirmation = true;
      this.memberDeleteConfirm = true;
    },

    updateIncident(assignment: string) {
      // Checking if an assignment exists in the current vehicle - nothing to do.
      if (this.item?.assignment === assignment) {
        return;
      }
      const changedVehicle = {
        ...this.item,
        assignment,
      };
      this.updateVehicle({vehicle: changedVehicle});
    },

    /**
     * Handler - change member name.
     * @param {String} value - input event.
     * @param {String} prevValue - previous member name.
     */
    handleMemberEditChange(value: string, prevValue: string) {
      this.disableEditName = !value || value === prevValue;
      this.memberChangedName = value;
    },

    /**
     * Handler - change vehicle name.
     * @param {String} value - input event.
     */
    handleVehicleEditChange(value: string) {
      this.disableEditName = !value || value === this.item?.name;
      this.vehicleChangedName = value;
    },

    /**
     * Handler - change member rank.
     * @param {String} value - dropdown event.
     * @param {String} prevValue - previous member rank.
     */
    handleSelectRank(value: string, prevValue: string) {
      this.disableEditName = value === prevValue;
      this.dropdownMemberRank = value;
    },

    /**
     * Handler - add new vehicle name.
     * @param {String} value - input event.
     */
    handleVehicleAddChange(value: string) {
      if (!value) {
        return;
      }
      this.vehicleNewName = value;
      this.disableEditName = false;
    },

    handleSwitcherClick(value: string) {
      this.newMemberRank = value;
    },

    handleNewMemberNameChange(value: string) {
      this.newMemberName = value;
    },

    commitAddingNewMember() {
      if (this.newMemberName && this.newMemberRank) {
        this.addMember({
          vehicleName: this.item?.name,
          vehicleId: this.item?.vehicleId,
          fullName: this.newMemberName,
          rank: this.newMemberRank,
        });
        this.cancelAddingNewMember();
      }
    },

    cancelAddingNewMember() {
      this.newMemberRank = '';
      this.newMemberName = '';
      this.addNewMemberMode = false;
    },

    moveMemberToNewVehicle(newVehicle: VehicleListItem) {
      if (newVehicle.vehicleId === this.item?.vehicleId) {
        return;
      }

      this.msqConfirm = `${this.checkRank(this.currentMemberToMove?.rank || '')}
      ${this.currentMemberToMove?.fullName || ''}
      ${MSQ_ASSIGN_CONFIRM.assigned || ''} ${this.currentMemberToMove?.vehicleName || ''}.
      ${MSQ_ASSIGN_CONFIRM.wantToMove || ''} to ${newVehicle?.name || ''}?`;

      this.currentMemberToMoveNewVehicle = newVehicle;
      this.showConfirmation = true;
    },

    // Show list of vehicle by click 'move'.
    showVehicleNamesList(member: MemberListItem) {
      this.moveMemberMode = true;
      this.addMemberMode = false;
      this.currentMemberToMove = member;
    },

    // Show list of Members from MemberList by click 'add new person'.
    showMemberList() {
      this.moveMemberMode = false;
      this.addMemberMode = true;
    },

    // Hide list of Members from MemberList by click 'Cancel'.
    hideMemberList() {
      this.moveMemberMode = false;
      this.addMemberMode = false;
    },

    /**
     * Assign a member to vehicle.
     * If the user is already on the list of one of the vehicle - Show confirm.
     * If the member is not in the list of Vehicles - Move to the vehicle;
     * @param {Object} member - member from GET:memberList.
     */
    assignToCurrentVehicle(member: MemberListItem) {
      this.currentMember = member;
      // Checking if a member exists in the current vehicle - nothing to do.
      if (this.currentMember.vehicleId === this.item?.vehicleId) {
        return;
      }

      // If the user is already on the list of one of the vehicle - Show confirm.
      if (this.currentMember.vehicleId) {
        this.msqConfirm = `${this.checkRank(this.currentMember?.rank || '')}
        ${this.currentMember?.fullName} ${MSQ_ASSIGN_CONFIRM.assignedAlready}
        ${this.currentMember?.vehicleName}. ${MSQ_ASSIGN_CONFIRM.wantToMove} ?`;

        this.showConfirmation = true;
      } else {
        this.moveMemberToVehicle(this.currentMember)
          .then(() => {
            this.currentMember = undefined;
          });
      }
    },

    async moveMemberToVehicle(member: MemberListItem): Promise<VehicleListItem> {
      return this.moveMemberToAnyVehicle(member, this.item as VehicleListItem | undefined);
    },

    async moveMemberToAnyVehicle(member?: MemberListItem, vehicleForMember?: VehicleListItem) {
      const oldVehicleId = member?.vehicleId;
      const changedMember = {
        ...member,
        vehicleId: vehicleForMember?.vehicleId,
        vehicleName: vehicleForMember?.name,
      } as MemberListItem;

      return this.assignMember({
        member: changedMember,
        oldVehicleId,
      });
    },

    addNewMember() {
      this.addNewMemberMode = true;
    },
    addNewVehicle() {
      this.addNewVehicleMode = true;
    },
  },
  computed: {
    ...mapGetters(MembersModuleKey, {
      memberListState: GetterTypesFromMembers.MEMBER_LIST,
    }),
  },
});
