import * as _ from 'lodash';
import RosterListDataProvider from '@/app/data-flow/rosters/RosterListDataProvider';
import {ActionContext} from 'vuex';
import {RosterListItem} from '@/app/core/interfaces';

const rosterListDataProvider = new RosterListDataProvider();

/**
 * Get Current IncidentId.
 */
export const RostersModuleKey = 'RostersModule';

export const GetterTypesFromRosters = {
  ROSTER_LIST: 'ROSTER_LIST',
  ROSTER_LIST_LOADING_STATE: 'ROSTER_LIST_LOADING_STATE',
  ROSTER_LIST_ERROR_STATE: 'ROSTER_LIST_ERROR_STATE',
  CURRENT_ROSTER: 'CURRENT_ROSTER',
  ROSTER_TEMP_LIST: 'ROSTER_TEMP_LIST',
  ROSTER_HEARTRATE_LIST: 'ROSTER_HEARTRATE_LIST',
  ROSTER_OXYGEN_LIST: 'ROSTER_OXYGEN_LIST',
  ROSTER_TIMESTAMP_LIST: 'ROSTER_TIMESTAMP_LIST',
  ROSTER_MAX_HEARTRATE: 'ROSTER_HIGHEST_Max',
  ROSTER_MAX_TEMP: 'ROSTER_MAX_TEMP',
  ROSTER_MIN_OXYGEN: 'ROSTER_MIN_OXYGEN',
};

export interface State {
  rosterList?: RosterListItem[];
  loading: boolean;
  error?: Error;
}

const initialState = (): State => ({
  rosterList: undefined,
  loading: false,
  error: undefined,
});

const getters = {
  [GetterTypesFromRosters.ROSTER_LIST_LOADING_STATE](state: State) {
    return state.loading;
  },
  [GetterTypesFromRosters.ROSTER_LIST_ERROR_STATE](state: State) {
    return state.error;
  },
  [GetterTypesFromRosters.ROSTER_LIST](state: State) {
    return state.rosterList;
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypesFromRosters.CURRENT_ROSTER](state: State, _getters, rootState) {
    return (state.rosterList || []).find(
      (roster) => roster.id === rootState.RouteModule?.params?.id,
    );
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypesFromRosters.ROSTER_TEMP_LIST](state: State, _getters, rootState) {
    const tempList: number[] = [];
    if (state.rosterList) {
      const person = (state.rosterList || []).find(
        (roster) => roster.id === rootState.RouteModule?.params?.id,
      );
      if (person) {
        // eslint-disable-next-line no-mixed-operators
        person.currentBiometrics.map((element) => tempList.push((element.temp * 9) / 5 + 32));
        return tempList;
      }
    }
    return [];
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypesFromRosters.ROSTER_MAX_TEMP](state: State, _getters, rootState) {
    const tempList: number[] = [];
    let maxTempRate: number;
    if (state.rosterList) {
      const person = (state.rosterList || []).find(
        (roster) => roster.id === rootState.RouteModule?.params?.id,
      );
      if (person) {
        // eslint-disable-next-line no-mixed-operators
        person.currentBiometrics.map((element) => tempList.push((element.temp * 9) / 5 + 32));
        maxTempRate = Math.max(...tempList);
        return maxTempRate;
      }
    }
    return [];
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypesFromRosters.ROSTER_HEARTRATE_LIST](state: State, _getters, rootState) {
    const heartRateList: number[] = [];
    if (state.rosterList) {
      const person = (state.rosterList || []).find(
        (roster) => roster.id === rootState.RouteModule?.params?.id,
      );
      if (person) {
        person.currentBiometrics.map((element) => heartRateList.push(element.heartRate));
        return heartRateList;
      }
    }
    return [];
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypesFromRosters.ROSTER_MAX_HEARTRATE](state: State, _getters, rootState) {
    const heartRateList: number[] = [];
    let maxHeartRate: number;
    if (state.rosterList) {
      const person = (state.rosterList || []).find(
        (roster) => roster.id === rootState.RouteModule?.params?.id,
      );
      if (person) {
        person.currentBiometrics.map((element) => heartRateList.push(element.heartRate));
        maxHeartRate = Math.max(...heartRateList);
        return maxHeartRate;
      }
    }
    return [];
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypesFromRosters.ROSTER_OXYGEN_LIST](state: State, _getters, rootState) {
    const oxygenList: number[] = [];
    if (state.rosterList) {
      const person = (state.rosterList || []).find(
        (roster) => roster.id === rootState.RouteModule?.params?.id,
      );
      if (person) {
        person.currentBiometrics.map((element) => oxygenList.push(element.oxygen));
        return oxygenList;
      }
    }
    return [];
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypesFromRosters.ROSTER_MIN_OXYGEN](state: State, _getters, rootState) {
    const oxygenList: number[] = [];
    let minOxygen: number;
    if (state.rosterList) {
      const person = (state.rosterList || []).find(
        (roster) => roster.id === rootState.RouteModule?.params?.id,
      );
      if (person) {
        person.currentBiometrics.map((element) => oxygenList.push(element.oxygen));
        minOxygen = Math.min(...oxygenList);
        return minOxygen;
      }
    }
    return [];
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  [GetterTypesFromRosters.ROSTER_TIMESTAMP_LIST](state: State, _getters, rootState) {
    const timestampList: number[] = [];
    if (state.rosterList) {
      const person = (state.rosterList || []).find(
        (roster) => roster.id === rootState.RouteModule?.params?.id,
      );
      if (person) {
        person.currentBiometrics.map((element) => timestampList.push(element.timestamp));
        const convert: string[] = [];
        timestampList.map((el) => {
          const date = new Date(el);
          const hours = date.getHours();
          const minutes = `0${date.getMinutes()}`;
          const seconds = `0${date.getSeconds()}`;
          const formattedTime = `${hours}:${minutes.substr(
            -2,
          )}:${seconds.substr(-2)}`;
          return convert.push(formattedTime);
        });
        return convert;
      }
    }
    return [];
  },
};

const actions = {
  loadRosterList(context: ActionContext<State, unknown>) {
    context.commit('loadRosterList');

    return rosterListDataProvider
      .getRosterList()
      .then((response) => {
        const data = [...response];
        context.commit('loadRosterListSuccess', {data});
      })
      .catch((error) => {
        context.commit('loadRosterListFail', {error});
        return error;
      });
  },
};

const mutations = {
  loadRosterList(state: State) {
    state.loading = true;
    state.error = undefined;
  },

  loadRosterListSuccess(state: State, payload: { data: RosterListItem[] }) {
    state.loading = false;
    state.error = undefined;

    if (!_.isEqual(state.rosterList, payload.data)) {
      state.rosterList = payload.data;
    }
  },

  loadRosterListFail(state: State, payload: { error: Error }) {
    state.loading = false;
    state.error = payload.error;
    state.rosterList = undefined;
  },
};

export const RostersModule = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialState,
};
