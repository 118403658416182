export const POWERED_BY = {
  name: 'powered by retinus',
  link: 'https://www.retinus.io',
};

export const COLOR_TYPE = {
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  info: 'info',
  notice: 'notice',
};

export const BUTTON_TYPE = {
  primary: 'primary',
  primaryO: 'primary-o',
  accent: 'accent',
  icon: 'icon',
};

export const LIST_SPACES = {
  sm: 'sm',
};

export const DIRECTION = {
  right: 'right',
};

export const HOME = 'Home';

export const MODAL_CONFIRM_BTN = {
  confirm: 'Ok',
  cancel: 'No',
};

export const MODAL_CONFIRM_TITLE = 'Confirm';

export const MSQ_CONFIRM = {
  logout: 'Are you sure you want to Logout?',
};

export const BTN_CONTENT = {
  cancel: 'cancel',
};

export const PATTERN_VALIDATE = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
};

export const VALIDATE_HINT = {
  email: 'Enter a valid email in the joe@abc.com format.',
  password: 'Minimum eight characters, at least one letter, one number and one special character.',
};
