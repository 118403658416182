<template>
  <rui-sidebar class="fire-sidebar">
    <template v-slot:header>
      <div>{{ vitalWatch }}</div>
    </template>
    <template v-slot:navigation>
      <a v-bind:key="index"
         v-for="(member, index) in rosterListState"
         @click.prevent="onSelectMember(member.id)"
         class="fire-item rui-sidebar__item"
         :class="isActive(member.id)">
        <fire-avatar :online="member.liveTracker"
                     :photo="member.photo"/>
        <div class="fire-item__body">
          <div class="fire-title">{{ member.firstName }}&nbsp;{{ member.lastName }}</div>
          <div class="fire-rank">
            <span>{{ member.rank }}</span>
          </div>
        </div>
      </a>
    </template>
  </rui-sidebar>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiSidebar from '@/retinus-ui/components/layout/sidebar/RuiSidebar.vue';
import FireAvatar from '@/app/components/avatar/FireAvatar.vue';

import {mapGetters} from 'vuex';
import {GetterTypesFromRosters, RostersModuleKey} from '@/app/data-flow/rosters';

import {VITAL_WATCH, SIDEBAR_RANK} from '@/app/core/constants';
import FireSidebarTools from '@/app/components/layout/sidebar/FireSidebarTools';

export default defineComponent({
  name: 'fire-sidebar',
  emits: ['onSelectMember'],
  components: {
    RuiSidebar,
    FireAvatar,
  },
  data() {
    return {
      captainRole: SIDEBAR_RANK.captain,
      fireChiefRole: SIDEBAR_RANK.fireChief,
      vitalWatch: VITAL_WATCH,
    };
  },
  computed: {
    ...mapGetters(RostersModuleKey, {
      rosterListState: GetterTypesFromRosters.ROSTER_LIST,
      currentRosterState: GetterTypesFromRosters.CURRENT_ROSTER,
    }),
  },
  methods: {
    onSelectMember(memberId: string): void {
      this.$router.push({
        name: 'RosterDetail',
        params: {id: memberId},
      });
      this.$emit('onSelectMember');
    },

    isActive(memberId: string): string {
      return FireSidebarTools.isActive(memberId, this.currentRosterState?.id);
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'sidebar';
</style>
