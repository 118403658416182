<template>
  <button v-for="(item, index) in data"
          class="fire-list-btn"
          @click.prevent="onClick(item)"
          :class="vehicleId === item.vehicleId ? 'is-active' : ''"
          :key="index">
    {{ item.name }}
  </button>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'fire-vehicle-list',
  emits: ['clickItem'],
  props: {
    data: {
      type: Array,
      required: true,
    },
    vehicleId: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClick(value: string) {
      this.$emit('clickItem', value);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../list-btn";
</style>
