
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'fire-vehicle-list',
  emits: ['clickItem'],
  props: {
    data: {
      type: Array,
      required: true,
    },
    vehicleId: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClick(value: string) {
      this.$emit('clickItem', value);
    },
  },
});
