
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

export default defineComponent({
  name: 'fire-back-button',
  emits: ['clickBack'],
  components: {
    RuiIcon,
  },
  methods: {
    onClick() {
      this.$emit('clickBack');
    },
  },
});
