import axios from 'axios';

import {RosterOnMap} from '@/app/core/interfaces';
import {BASE_URL} from '@/app/core/constants';

export default class GeoDataProvider {
  /**
   * GET /v1/geo/incidentId.
   */
  // TODO: mock data.
  // getRostersMapData(incidentId: string): Promise<RosterOnMap> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve(ROSTER_LIST_ON_MAP), 1000);
  //   });
  // }
  async getRostersMapData(incidentId: string): Promise<RosterOnMap> {
    const res = await axios.get<RosterOnMap>(`${BASE_URL}/geo/${incidentId}`);

    return res.data || {};
  }
}
