import {RANK} from '@/app/core/constants';
import {VehicleGroup} from '@/app/core/interfaces';

export default class OnSceneTools {
  public static checkRank(rank: string) {
    return rank.toLowerCase() !== RANK.firefighter ? `${rank}. ` : '';
  }

  public static checkRankIcon(rank: string) {
    const rankLevel = rank.toLowerCase();
    switch (rankLevel) {
      case RANK.captain:
        return '--captain';
      case RANK.lieutenant:
        return '--lieutenant';
      case RANK.firefighter:
        return '--firefighter';
      default:
        return '';
    }
  }

  public static toggleGroupAll(vehicleGroups: VehicleGroup[], expandStatus: boolean) {
    let expand = expandStatus;
    vehicleGroups.forEach((group) => {
      expand = group.expanded && expand;
    });
    expand = !expand;
    const groupData = vehicleGroups.map((group: VehicleGroup) => {
      // eslint-disable-next-line no-param-reassign
      group.expanded = expand;
      return group;
    });
    return {
      groupData,
      expand,
    };
  }

  public static toggleGroup(index: number, vehicleGroups: VehicleGroup[]) {
    const group = vehicleGroups[index];
    group.expanded = !group.expanded;
    let expandChecker = true;
    vehicleGroups.map((item: VehicleGroup) => {
      expandChecker = item.expanded && expandChecker;
      return item;
    });
    return expandChecker;
  }
}
