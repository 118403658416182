<template>
  <section class="rui-page">
    <div class="rui-page__head"
         v-if="$slots.title && $slots.toolbar">
      <div class="rui-title">
        <slot name="title"/>
      </div>
      <slot v-if="$slots.toolbar"
            name="toolbar"/>
    </div>
    <div class="rui-page__head"
         v-if="$slots.head">
      <slot name="head"/>
    </div>
    <div class="rui-page__filter"
         v-if="$slots.filter">
      <slot name="filter"/>
    </div>
    <div class="rui-page__body">
      <slot name="body"/>
    </div>
  </section>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'rui-page-layout',
  props: {
    title: String,
    modelValue: String,
  },
});
</script>

<style lang="scss" scoped>
@import 'page-layout';
</style>
