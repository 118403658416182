
import {defineComponent} from 'vue';
import RuiInput from '@/retinus-ui/components/input/RuiInput.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiListFlat from '@/retinus-ui/components/list-flat/RuiListFlat.vue';
import RuiDynamicInput from '@/retinus-ui/components/dynamic-Input/RuiDynamicInput.vue';

import {BUTTON_TEXT, PLACEHOLDER_TEXT} from '@/app/core/constants';

export default defineComponent({
  name: 'fire-notes-form',
  props: {
    disableSubmit: Boolean,
    descriptionValue: String,
    subjectValue: String,
  },
  components: {
    RuiInput,
    RuiButton,
    RuiListFlat,
    RuiDynamicInput,
  },
  data() {
    return {
      buttonText: BUTTON_TEXT,
      placeholderText: PLACEHOLDER_TEXT,
    };
  },
});
