<template>
  <div class="fire-roster"
       :class="{'is-open-middle': isOpenMiddleContent}">
    <fire-sidebar class="fire-roster__left"
                  @onSelectMember="onOpenMiddleContent()"/>
    <div class="fire-roster__middle">
      <fire-back-button @clickBack="onCloseMiddleContent()"/>
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import {mapGetters} from 'vuex';
import {GetterTypesFromRosters, RostersModuleKey} from '@/app/data-flow/rosters';
import {GetterTypes} from '@/app/data-flow/types';

import FireSidebar from '@/app/components/layout/sidebar/FireSidebar.vue';
import FireBackButton from '@/app/components/back-button/FireBackButton.vue';

export default defineComponent({
  name: 'fire-vital-watch-page',
  beforeMount() {
    let id;

    if (this.routerParams?.id) {
      id = this.routerParams?.id;
    } else {
      id = this.rosterList[0].id;
    }

    this.$router.push({name: 'RosterDetail', params: {id}});
  },
  components: {
    FireSidebar,
    FireBackButton,
  },
  computed: {
    ...mapGetters(RostersModuleKey, {
      rosterList: GetterTypesFromRosters.ROSTER_LIST,
      rosterListLoading: GetterTypesFromRosters.ROSTER_LIST_LOADING_STATE,
    }),
    ...mapGetters({
      routerParams: GetterTypes.GET_PARAMS,
    }),
  },
  data() {
    return {
      isOpenMiddleContent: false as boolean,
    };
  },
  methods: {
    onOpenMiddleContent(): void {
      this.isOpenMiddleContent = true;
    },

    onCloseMiddleContent(): void {
      this.isOpenMiddleContent = false;
    },
  },
});
</script>
<style lang="scss" scoped>
@import 'roster';
</style>
