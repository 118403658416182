<template>
  <div class="rui-spinner__wrapper">
    <rui-backdrop/>
    <div class="rui-spinner__content">
      <rui-spinner :size="size"/>
      <span :v-if="text"
            class="rui-spinner__txt">{{text}}</span>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiSpinner from '@/retinus-ui/components/spinner/RuiSpinner.vue';
import RuiBackdrop from '@/retinus-ui/components/backdrop/RuiBackdrop.vue';

export default defineComponent({
  name: 'rui-spinner-wrapper',
  components: {
    RuiSpinner,
    RuiBackdrop,
  },
  props: {
    size: {
      type: String,
      default: '68',
    },
    text: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'spinner-wrapper';
</style>
