
import {defineComponent} from 'vue';

import {mapGetters} from 'vuex';
import {GetterTypesFromRosters, RostersModuleKey} from '@/app/data-flow/rosters';
import {GeoModuleKey, GetterTypesFromGeo} from '@/app/data-flow/geo';

import FireRosterHealth from '@/app/views/vital-watch/components/roster-health/FireRosterHealth.vue';
import FireFilter from '@/app/views/vital-watch/components/filter/FireFilter.vue';
import FireRosterMap from '@/app/views/vital-watch/components/map/FireMap.vue';
import RuiTabs from '@/retinus-ui/components/tabs/RuiTabs.vue';
import FireAvatar from '@/app/components/avatar/FireAvatar.vue';

import {VITAL_WATCH_TABS} from '@/app/core/constants';

export default defineComponent({
  name: 'fire-roster-detail-page',
  components: {
    FireRosterHealth,
    FireFilter,
    FireRosterMap,
    RuiTabs,
    FireAvatar,
  },
  computed: {
    ...mapGetters(RostersModuleKey, {
      rosterState: GetterTypesFromRosters.CURRENT_ROSTER,
    }),
    ...mapGetters(GeoModuleKey, {
      rosterOnMapState: GetterTypesFromGeo.ROSTER_LIST_ON_MAP,
    }),
  },
  data() {
    return {
      vitalWatchTabs: VITAL_WATCH_TABS,
      tabKey: 'rosterDashboard',
    };
  },
  methods: {
    showTabContent(v: { title: string; key: string }) {
      this.tabKey = v.key;
    },
  },
});
