<template>
  <div class="rui-checkbox">
    <input class="rui-checkbox__input"
           :disabled="disabled"
           @change="$emit('changeCheckbox', $event.target)"
           type="checkbox"/>
    <label v-if="label"
           class="rui-checkbox__label">{{label}}</label>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'rui-checkbox',
  props: {
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'checkbox';
</style>
