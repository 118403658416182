import {ActionContext} from 'vuex';
import {SupportListItem} from '@/app/core/interfaces';
import SupportListDataProvider from '@/app/data-flow/support/SupportListDataProvider';
import {IncidentListModuleKey} from '@/app/data-flow/incident';
import {GetterTypesFromIncidentList} from '@/app/data-flow/incident/types';

const supportListDataProvider = new SupportListDataProvider();

export const SupportModuleKey = 'SupportModule';

/**
 * Get Current IncidentId.
 */
const getterKey = `${IncidentListModuleKey}/${GetterTypesFromIncidentList.CURRENT_INCIDENT_ID}`;

export interface State {
  message?: SupportListItem;
  loading: boolean;
  error?: Error;
}

const initialState = (): State => ({
  message: undefined,
  loading: false,
  error: undefined,
});

const actions = {
  addMessage(context: ActionContext<State, unknown>,
    {subject, description}: {
      subject: string;
      description: string;
    }) {
    const newMessage = {
      subject,
      description,
    };

    context.commit('addMessage');

    const incidentId = context.rootGetters[getterKey];

    supportListDataProvider.addMessage(newMessage, incidentId)
      .then(() => {
        context.commit('addMessageSuccess');
      })
      .catch((error) => {
        context.commit('addMessageFail', {error});
      });
  },
};

const mutations = {
  addMessage(state: State) {
    state.loading = true;
  },

  addMessageSuccess(state: State) {
    state.loading = false;
  },
  addMessageFail(state: State, payload: {error: Error}) {
    console.error('addMessageFail', payload);
    state.loading = false;
  },
};

export const SupportModule = {
  namespaced: true,
  actions,
  mutations,
  state: initialState,
};
