import _ from 'lodash';
import {RANK_ORDER} from '@/app/core/constants';
import {MemberListItem, NoteListItem} from '@/app/core/interfaces';

export default class Sorter {
  public static sortByRank(team: MemberListItem[]) {
    return _.sortBy(team, (elem) => _.indexOf(RANK_ORDER, elem.rank));
  }

  public static sortByDate(notes: NoteListItem[]) {
    return _.orderBy(notes, 'date', 'desc');
  }
}
