
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import VitalWatchTools from '@/app/views/vital-watch/VitalWatchTools';

export default defineComponent({
  name: 'fire-roster-health-card',
  props: {
    title: String,
    icon: String,
    level: [String, Number],
  },
  components: {
    RuiIcon,
  },
  methods: {
    checkAlertColor(type: number | string) {
      if (typeof type !== 'number') {
        return VitalWatchTools.setColorStyle(type.toLowerCase());
      }
      return '';
    },

    formatLevel() {
      if (typeof this.level === 'number' && this.level % 1 !== 0) {
        return this.level.toFixed(3);
      }
      return this.level;
    },
  },
});
