<template>
  <span :class="`fire-tracker-ico${liveTracker ? '--online' : '--offline'}`"></span>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'fire-live-tracker-icon',
  props: {
    liveTracker: Boolean,
  },
});
</script>

<style lang="scss" scoped>
@import 'live-tracker';
</style>
