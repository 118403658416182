
import {defineComponent} from 'vue';

import RuiHeader from '@/retinus-ui/components/layout/header/RuiHeader.vue';
import FireModalConfirm from '@/app/components/modal/FireModalConfirm.vue';

import {mapActions, mapGetters} from 'vuex';
import {GetterTypes} from '@/app/data-flow/types';
import {UserModuleKey} from '@/app/data-flow/user';
import {AuthModuleKey} from '@/app/data-flow/auth';
import {NAVIGATION_MENU} from '@/app/core/constants';
import {MSQ_CONFIRM} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'fire-header',
  components: {
    RuiHeader,
    FireModalConfirm,
  },
  data() {
    return {
      navigationMenu: NAVIGATION_MENU,
      msqConfirm: MSQ_CONFIRM.logout,
      showConfirmation: false,
    };
  },
  methods: {
    ...mapActions(AuthModuleKey, ['logout']),

    modalConfirm() {
      this.showConfirmation = false;
      this.logout().then(() => this.$router.push({name: 'login'}));
    },

    modalConfirmCancel() {
      this.showConfirmation = false;
    },

    logOut() {
      this.showConfirmation = true;
    },
  },
  computed: {
    ...mapGetters(UserModuleKey, {
      userPhotoMin: GetterTypes.USER_PROFILE_PICTURE_MIN,
    }),
  },
});
