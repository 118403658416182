import {ActionContext} from 'vuex';
import {User} from '@/app/core/interfaces';
import AuthDataProvider from '@/app/data-flow/auth/AuthDataProvider';
import {TOKEN_KEY} from '@/app/core/constants';

const authDataProvider = new AuthDataProvider();

export const AuthModuleKey = 'AuthModule';

export const GetterTypesFromAuth = {
  LOGGED_IN_STATE: 'LOGGED_IN_STATE',
  ERROR: 'ERROR',
};

export interface State {
  loading: boolean;
  error?: Error;
  token?: string;
}

const initialState = (): State => ({
  loading: false,
  error: undefined,
  token: localStorage.getItem(TOKEN_KEY) || '',
});

const getters = {
  [GetterTypesFromAuth.LOGGED_IN_STATE](state: State) {
    return state.token;
  },
  [GetterTypesFromAuth.ERROR](state: State) {
    return state.error;
  },
};

const actions = {
  login(context: ActionContext<State, unknown>, {user}: { user: User }): Promise<void> {
    context.commit('auth');

    return authDataProvider.login(user)
      .then((response) => {
        const bearerToken = `Bearer ${response.token}`;

        authDataProvider.setupToken(bearerToken);
        context.commit('authSuccess', {token: bearerToken});
      })
      .catch((error) => {
        authDataProvider.clearToken();
        context.commit('authFail', {error: error.response.data});
      });
  },

  newPassword(context: ActionContext<State, unknown>, {password, token}: {
    password: string; token: string;
  }): Promise<void> {
    context.commit('newPassword');

    return authDataProvider.newPassword({password, token})
      .then(() => {
        context.commit('newPasswordSuccess');
      })
      .catch((error) => {
        context.commit('newPasswordFail', {error});
      });
  },

  logout(context: ActionContext<State, unknown>) {
    authDataProvider.clearToken();
    context.commit('logout');
  },

  resetPassword(context: ActionContext<State, unknown>, {email}: { email: string }): Promise<void> {
    context.commit('resetPassword');

    return authDataProvider.resetPassword({email})
      .then(() => {
        context.commit('resetPasswordSuccess');
      })
      .catch((error) => {
        context.commit('resetPasswordFail', {error});
      });
  },

  hideError(context: ActionContext<State, unknown>) {
    context.commit('hideError', {error: undefined});
  },
};

const mutations = {
  auth(state: State) {
    state.loading = true;
    state.error = undefined;
  },
  authSuccess(state: State, payload: { token: string }) {
    state.loading = false;
    state.error = undefined;
    state.token = payload.token;
  },
  authFail(state: State, payload: { error: Error }) {
    state.loading = false;
    state.error = payload.error;
  },
  logout(state: State) {
    state.loading = false;
    state.error = undefined;
    state.token = '';
  },

  resetPassword(state: State) {
    state.loading = false;
    state.error = undefined;
  },

  resetPasswordSuccess(state: State) {
    state.loading = false;
    state.error = undefined;
  },

  resetPasswordFail(state: State, payload: { error: Error }) {
    state.loading = false;
    state.error = payload.error;
  },

  newPassword(state: State) {
    state.loading = false;
    state.error = undefined;
  },

  newPasswordSuccess(state: State) {
    state.loading = false;
    state.error = undefined;
  },

  newPasswordFail(state: State, payload: { error: Error }) {
    state.loading = false;
    state.error = payload.error;
  },

  hideError(state: State, payload: {error: undefined}) {
    state.error = payload.error;
  },
};

export const AuthModule = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialState,
};
