<template>
  <rui-input type="text"
             :modelValue="modelValue"
             @changeFromInputComponent="$emit('changeInput', $event)"
             :placeholder="placeholderText">
    <template v-slot:dropdown
              v-if="options">
      <rui-dropdown :options="options"
                    :selectedOption="selectedDropdownOption"
                    @selectOption="$emit('selectRank', $event)"/>
    </template>
    <template v-slot:buttons>
      <rui-button :buttonType="buttonTypeIcon"
                  :disabled="disableEdit"
                  @clickFromButtonComponent="$emit('clickDone')">
        <template v-slot:icon>
          <rui-icon icon="done"/>
        </template>
      </rui-button>
      <rui-button :buttonType="buttonTypeIcon"
                  @clickFromButtonComponent="$emit('clickCancel')">
        <template v-slot:icon>
          <rui-icon icon="logout"/>
        </template>
      </rui-button>
    </template>
  </rui-input>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiInput from '@/retinus-ui/components/input/RuiInput.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiDropdown from '@/retinus-ui/components/dropdown/RuiDropdown.vue';
import {BUTTON_TYPE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'fire-edit-form',
  components: {
    RuiInput,
    RuiIcon,
    RuiButton,
    RuiDropdown,
  },
  props: {
    placeholderText: String,
    modelValue: String,
    disableEdit: Boolean,
    selectedDropdownOption: String,
    options: Array,
  },
  data() {
    return {
      buttonTypeIcon: BUTTON_TYPE.icon,
    };
  },
});
</script>
