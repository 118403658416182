import {initialState} from '@/app/data-flow/incident/state';
import {getters} from '@/app/data-flow/incident/getters';
import {mutations} from '@/app/data-flow/incident/mutations';
import {actions} from '@/app/data-flow/incident/actions';

export const IncidentListModuleKey = 'IncidentListModule';

export const IncidentListModule = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialState,
};
