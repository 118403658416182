import {ActionContext} from 'vuex';
import {NoteListItem} from '@/app/core/interfaces';
import NoteListDataProvider from '@/app/data-flow/notes/NoteListDataProvider';
import {IncidentListModuleKey} from '@/app/data-flow/incident';
import {GetterTypesFromIncidentList} from '@/app/data-flow/incident/types';
import Sorter from '@/app/core/sorter';

const noteListDataProvider = new NoteListDataProvider();

export const NotesModuleKey = 'NotesModule';

/**
 * Get Current IncidentId.
 */
const getterKey = `${IncidentListModuleKey}/${GetterTypesFromIncidentList.CURRENT_INCIDENT_ID}`;

export const GetterTypesFromNotes = {
  NOTE_LIST: 'NOTE_LIST',
  NOTE_LIST_LOADING_STATE: 'NOTE_LIST_LOADING_STATE',
  NOTE_LIST_ERROR_STATE: 'NOTE_LIST_ERROR_STATE',
};

export interface State {
  noteList?: NoteListItem[];
  note?: NoteListItem;
  loading: boolean;
  error?: Error;
}

const initialState = (): State => ({
  noteList: undefined,
  note: undefined,
  loading: false,
  error: undefined,
});

const getters = {
  [GetterTypesFromNotes.NOTE_LIST_LOADING_STATE](state: State) {
    return state.loading;
  },
  [GetterTypesFromNotes.NOTE_LIST_ERROR_STATE](state: State) {
    return state.error;
  },
  [GetterTypesFromNotes.NOTE_LIST](state: State) {
    return state.noteList;
  },
};

const actions = {
  loadNoteList(context: ActionContext<State, unknown>) {
    context.commit('loadNoteList');

    const incidentId = context.rootGetters[getterKey];

    return noteListDataProvider
      .getNoteList(incidentId)
      .then((response) => {
        const data = Sorter.sortByDate([...response]);
        context.commit('loadNoteListSuccess', {data});
      })
      .catch((error) => {
        context.commit('loadNoteListFail', {error});
        return error;
      });
  },

  addNote(context: ActionContext<State, unknown>,
    {subject, description}: {
      subject: string;
      description: string;
    }) {
    const newNote = {
      subject,
      description,
    };

    context.commit('addNote');

    const incidentId = context.rootGetters[getterKey];

    noteListDataProvider.addNote(newNote, incidentId)
      .then((note) => {
        context.commit('addNoteSuccess', {note});
      })
      .catch((error) => {
        context.commit('addNoteFail', {error});
      });
  },

  deleteNote(
    context: ActionContext<State, unknown>,
    {noteId}: {
      noteId: string;
    },
  ) {
    context.commit('deleteNote');

    const incidentId = context.rootGetters[getterKey];

    return noteListDataProvider.deleteNote(noteId, incidentId)
      .then(() => {
        context.commit('deleteNoteSuccess');
        context.commit('deleteNoteListItem', {noteId});
      })
      .catch((error) => {
        context.commit('deleteNoteFail', {error});
      });
  },
};

const mutations = {
  loadNoteList(state: State) {
    state.loading = true;
    state.error = undefined;
  },
  loadNoteListSuccess(state: State, payload: { data: NoteListItem[] }) {
    state.loading = false;
    state.error = undefined;
    state.noteList = payload.data;
  },
  loadNoteListFail(state: State, payload: { error: Error }) {
    state.loading = false;
    state.error = payload.error;
    state.noteList = undefined;
  },

  addNote(state: State) {
    state.loading = true;
  },
  addNoteSuccess(state: State, payload: {note: NoteListItem}) {
    if (state.noteList) {
      state.noteList.unshift(payload.note);
    }

    state.loading = false;
  },
  addNoteFail(state: State, payload: {error: Error}) {
    console.error('addNoteFail', payload);
    state.loading = false;
  },

  deleteNote(state: State) {
    state.loading = true;
    state.error = undefined;
  },
  deleteNoteSuccess(state: State) {
    state.loading = false;
    state.error = undefined;
  },
  deleteNoteFail(state: State, payload: { error: Error }) {
    state.loading = false;
    state.error = payload.error;
  },
  deleteNoteListItem(state: State, payload: { noteId: string }) {
    state.noteList = state.noteList?.filter((v) => v.id !== payload.noteId);
  },
};

export const NotesModule = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialState,
};
