
import { defineComponent } from 'vue';
import {Chart} from 'chart.js';

export default defineComponent({
  name: 'fire-temperature',
  props: {
    data: Array,
    timestamp: Array,
  },
  data() {
    return {
      myChart: (Chart as unknown) as Chart,
    };
  },
  methods: {
    createChart(chartId: string) {
      const canvas = document.getElementById(chartId) as HTMLCanvasElement;
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      const gradientFill = ctx.createLinearGradient(0, 0, 0, 120);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const threshold = this.data?.some((v: any) => v >= 88);

      if (threshold) {
        gradientFill.addColorStop(0, 'rgba(232, 50, 35, 0.6)');
      } else {
        gradientFill.addColorStop(0, 'rgba(10, 85, 43, 0.6)');
      }

      this.myChart = new Chart(chartId, {
        type: 'line',
        data: {
          labels: this.timestamp as Array<string>,
          datasets: [
            {
              label: '\u2109',
              data: this.data as Array<number>,
              lineTension: 0,
              borderColor: '#71B2D9',
              borderWidth: 3,
              pointBackgroundColor: '#162026',
              backgroundColor: gradientFill,
              pointRadius: 1,
            },
          ],
        },
        options: {
          events: [],
          animation: {
            duration: 0,
          },
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: false,
                },
                gridLines: {
                  color: 'rgba(113, 178, 217, 0.2)',
                },
              },
            ],
          },
        },
      });
    },
  },
  mounted() {
    this.createChart('temp');
  },
  watch: {
    data() {
      this.myChart.destroy();
      this.createChart('temp');
    },
    timestamp() {
      this.myChart.destroy();
      this.createChart('temp');
    },
  },
});
