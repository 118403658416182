
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'rui-page-layout',
  props: {
    title: String,
    modelValue: String,
  },
});
