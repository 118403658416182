<template>
  <div :class="{ 'is-open': isOpenNavigation }"
       class="rui-navigation">
    <nav class="rui-navigation__menu">
      <div v-for="(item, index) in navigationMenu"
           :key="index"
           class="rui-navigation__menu-item">
        <div v-if="index === navigationMenu.length - 1"
             class="rui-navigation-hr"></div>
        <a :href="item.link"
           @click.prevent="navigateToLink(item.link)"
           :class="checkCurrentPath(item.link) ? 'is-active' : ''">
          <rui-icon :icon="item.icon"
                    class-name="rui-navigation-ico"/>
          <span>{{ item.name }}</span>
        </a>
      </div>
    </nav>
    <rui-footer class="rui-navigation__footer"/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiFooter from '@/retinus-ui/components/layout/footer/RuiFooter.vue';

export default defineComponent({
  name: 'rui-navigation',
  props: ['navigationMenu', 'isOpenNavigation'],
  components: {
    RuiIcon,
    RuiFooter,
  },
  methods: {
    checkCurrentPath(path: string) {
      return this.$route.path.includes(path);
    },
    navigateToLink(link: string) {
      if (!this.checkCurrentPath(link)) {
        this.$router.push(link);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'navigation';
</style>
