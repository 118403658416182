import RuiDashboard from './RuiDashboard.vue';
import RuiSettings from './RuiSettings.vue';
import RuiSupport from './RuiSupport.vue';
import RuiLogo from './RuiLogo.vue';
import RuiWarning from './RuiWarning.vue';
import RuiDanger from './RuiDanger.vue';
import RuiMoreVert from './RuiMoreVert.vue';
import RuiTimer from './RuiTimer.vue';
import RuiClose from './RuiClose.vue';
import RuiOpenInNew from './RuiOpenInNew.vue';
import RuiHealth from './RuiHealth.vue';
import RuiNotes from './RuiNotes.vue';
import RuiTask from './RuiTask.vue';
import RuiArrowDown from './RuiArrowDown.vue';
import RuiEdit from './RuiEdit.vue';
import RuiDelete from './RuiDelete.vue';
import RuiDone from './RuiDone.vue';
import RuiAddPerson from './RuiAddPerson.vue';
import RuiPerson from './RuiPerson.vue';
import RuiSend from './RuiSend.vue';
import RuiStar from './RuiStar.vue';
import RuiAdd from './RuiAdd.vue';
import RuiGroup from './RuiGroup.vue';
import RuiTemperature from './RuiTemperature.vue';
import RuiHeartRate from './RuiHeartRate.vue';
import RuiOxygen from './RuiOxygen.vue';
import RuiScba from './RuiScba.vue';
import RuiHazmat from './RuiHazmat.vue';
import RuiSmoke from './RuiSmoke.vue';
import RuiLocation from './RuiLocation.vue';
import RuiBellAdd from './RuiBellAdd.vue';
import RuiSuccess from './RuiSuccess.vue';
import RuiBalon from './RuiBalon.vue';
import RuiLogout from './RuiLogout.vue';
import RuiEye from './RuiEye.vue';
import RuiEyeClosed from './RuiEyeClosed.vue';
import RuiMenuOpen from './RuiMenuOpen.vue';
import RuiMenuClose from './RuiMenuClose.vue';
import RuiChat from './RuiChat.vue';
import RuiArrowBack from './RuiArrowBack.vue';

export default {
  RuiDashboard,
  RuiSettings,
  RuiSupport,
  RuiLogo,
  RuiWarning,
  RuiDanger,
  RuiMoreVert,
  RuiTimer,
  RuiClose,
  RuiOpenInNew,
  RuiHealth,
  RuiNotes,
  RuiTask,
  RuiArrowDown,
  RuiEdit,
  RuiDelete,
  RuiDone,
  RuiAddPerson,
  RuiSend,
  RuiPerson,
  RuiStar,
  RuiAdd,
  RuiGroup,
  RuiTemperature,
  RuiHeartRate,
  RuiOxygen,
  RuiScba,
  RuiHazmat,
  RuiSmoke,
  RuiLocation,
  RuiBellAdd,
  RuiSuccess,
  RuiBalon,
  RuiLogout,
  RuiEye,
  RuiEyeClosed,
  RuiMenuOpen,
  RuiMenuClose,
  RuiChat,
  RuiArrowBack,
};
