
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

export default defineComponent({
  name: 'rui-dropdown',
  components: {RuiIcon},
  emits: ['selectOption'],
  props: {
    options: Array,
    selectedOption: String,
  },
  data() {
    return {
      isOpen: false,
      changedOption: '',
      optionValue: this.selectedOption,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },

    setCurrentSelectedOption(option: string) {
      this.optionValue = option;
      this.$emit('selectOption', option);
    },
  },
});
