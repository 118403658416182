<template>
  <button v-for="(item, index) in data"
          @click.prevent="onClick(item)"
          class="fire-list-btn"
          :class="vehicleId === item.vehicleId ? 'is-active' : ''"
          :key="index">{{checkRank(item.rank)}}{{ item.fullName }}</button>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

import OnSceneTools from '@/app/views/on-scene/OnSceneTools';

export default defineComponent({
  name: 'fire-member-list',
  emits: ['clickFromComponent'],
  props: {
    data: {
      type: Array,
      required: true,
    },
    vehicleId: {
      type: String,
      required: true,
    },
  },
  methods: {
    checkRank(rank: string) {
      return OnSceneTools.checkRank(rank);
    },
    onClick(value: string) {
      this.$emit('clickFromComponent', value);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../../list-btn";
</style>
