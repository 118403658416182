
import {defineComponent} from 'vue';

import RuiCardBorder from '@/retinus-ui/components/card-border/RuiCardBorder.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiCheckbox from '@/retinus-ui/components/checkbox/RuiCheckbox.vue';
import RuiNotify from '@/retinus-ui/components/notify/RuiNotify.vue';
import FireAssignmentLabel from '@/app/views/on-scene/components/assignment-label/FireAssignmentLabel.vue';

import OnSceneTools from '@/app/views/on-scene/OnSceneTools';
import {ParState} from '@/app/core/interfaces';
import {NEW_VEHICLE} from '@/app/core/constants';
import {mapActions} from 'vuex';
import {VehiclesModuleKey} from '@/app/data-flow/vehicles';

export default defineComponent({
  name: 'fire-vehicle-card',
  components: {
    RuiCardBorder,
    RuiIcon,
    RuiCheckbox,
    RuiNotify,
    FireAssignmentLabel,
  },
  emits: ['parComplete'],
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    activeCardState: Boolean,
    expanded: {
      type: Boolean,
      required: true,
    },
    startPar: String,
  },
  data() {
    return {
      newVehicle: NEW_VEHICLE,
      modelItem: null,
      modalVisible: false as boolean,
      checkedTeam: 0 as number,
      parFinish: false as boolean,
      startParProcess: undefined as ParState,
    };
  },
  methods: {
    ...mapActions(VehiclesModuleKey, [
      'updateVehiclePar',
    ]),

    checkRank(rank: string) {
      return OnSceneTools.checkRank(rank);
    },

    checkRankIcon(rank: string) {
      return OnSceneTools.checkRankIcon(rank);
    },

    checkboxVisible(): boolean {
      const parProcess = this.startParProcess === 'START';
      return parProcess && !this.parFinish;
    },

    checkboxDisabled(): boolean {
      return !this.activeCardState;
    },

    rankIconVisible(): boolean {
      return !this.startParProcess || (this.startParProcess && this.parFinish);
    },

    handleChange(checked: boolean) {
      // The number of PARs for the vehicle card header.
      if (checked) {
        this.checkedTeam += 1;
      } else {
        this.checkedTeam -= 1;
      }

      // Checked All members in the current Vehicle.
      if (this.checkedTeam === this.vehicle?.team.length) {
        const changedPar = this.vehicle.par + 1;
        this.updateVehiclePar({
          vehicleId: this.vehicle.vehicleId as string,
          par: changedPar,
        })
          .then(() => {
            this.parFinish = true;
            this.$emit('parComplete');
          });
      }
    },

    watchStartPar() {
      const fromUndefinedToStart = !this.startParProcess && this.startPar;
      const fromPauseToStart = this.startParProcess === 'PAUSE' && this.startPar === 'START';

      if (this.parFinish && fromUndefinedToStart) {
        this.parFinish = false;
      }

      // If a new PAR process is running (definitely not after a pause).
      if (!this.parFinish && (fromUndefinedToStart || fromPauseToStart)) {
        this.checkedTeam = 0;
      }

      this.startParProcess = this.startPar as ParState;
    },
  },
  watch: {
    startPar: 'watchStartPar',
  },
});
