import {GetterTypesFromMembers} from '@/app/data-flow/members/types';
import {State} from '@/app/data-flow/members/state';

// eslint-disable-next-line import/prefer-default-export
export const getters = {
  [GetterTypesFromMembers.MEMBER_LIST_LOADING_STATE](state: State) {
    return state.loading;
  },
  [GetterTypesFromMembers.MEMBER_LIST_ERROR_STATE](state: State) {
    return state.error;
  },
  [GetterTypesFromMembers.MEMBER_LIST](state: State) {
    return state.memberList;
  },
};
