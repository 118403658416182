
import {defineComponent} from 'vue';
import {DIRECTION, LIST_SPACES} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'rui-list-flat',
  props: {
    direction: {
      type: String,
    },
    spaces: {
      type: String,
    },
  },
  methods: {
    checkTextDirection(direction: string) {
      switch (direction) {
        case DIRECTION.right:
          return 'is-right';
        default:
          return '';
      }
    },
    checkSpacesType(spaces: string) {
      switch (spaces) {
        case LIST_SPACES.sm:
          return 'rui-list-flat--sm';
        default:
          return '';
      }
    },
  },
});
