
import {defineComponent} from 'vue';

import RuiSidebar from '@/retinus-ui/components/layout/sidebar/RuiSidebar.vue';
import FireAvatar from '@/app/components/avatar/FireAvatar.vue';

import {mapGetters} from 'vuex';
import {GetterTypesFromRosters, RostersModuleKey} from '@/app/data-flow/rosters';

import {VITAL_WATCH, SIDEBAR_RANK} from '@/app/core/constants';
import FireSidebarTools from '@/app/components/layout/sidebar/FireSidebarTools';

export default defineComponent({
  name: 'fire-sidebar',
  emits: ['onSelectMember'],
  components: {
    RuiSidebar,
    FireAvatar,
  },
  data() {
    return {
      captainRole: SIDEBAR_RANK.captain,
      fireChiefRole: SIDEBAR_RANK.fireChief,
      vitalWatch: VITAL_WATCH,
    };
  },
  computed: {
    ...mapGetters(RostersModuleKey, {
      rosterListState: GetterTypesFromRosters.ROSTER_LIST,
      currentRosterState: GetterTypesFromRosters.CURRENT_ROSTER,
    }),
  },
  methods: {
    onSelectMember(memberId: string): void {
      this.$router.push({
        name: 'RosterDetail',
        params: {id: memberId},
      });
      this.$emit('onSelectMember');
    },

    isActive(memberId: string): string {
      return FireSidebarTools.isActive(memberId, this.currentRosterState?.id);
    },
  },
});
