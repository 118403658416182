import {State} from '@/app/data-flow/members/state';
import _ from 'lodash';
import {MemberListItem, VehicleListItem} from '@/app/core/interfaces';
import Sorter from '@/app/core/sorter';

// eslint-disable-next-line import/prefer-default-export
export const mutations = {
  loadMemberList(state: State) {
    state.loading = true;
    state.error = undefined;
  },

  loadMemberListSuccess(state: State, payload: { data: MemberListItem[] }) {
    state.loading = false;
    state.error = undefined;
    state.memberList = payload.data;
  },

  loadMemberListFail(state: State, payload: { error: Error }) {
    state.loading = false;
    state.error = payload.error;
    state.memberList = undefined;
  },

  updateVehicleMember(state: State) {
    state.memberUpdateLoading = true;
    state.memberUpdateError = undefined;
  },

  updateVehicleMemberSuccess(state: State) {
    state.memberUpdateLoading = false;
    state.memberUpdateError = undefined;
  },

  updateVehicleMemberFail(state: State, payload: { error: Error }) {
    state.memberUpdateLoading = false;
    state.memberUpdateError = payload.error;
  },

  deleteMember(state: State) {
    state.memberDeleteLoading = true;
    state.memberDeleteError = undefined;
  },

  deleteMemberSuccess(state: State) {
    state.memberDeleteLoading = false;
    state.memberDeleteError = undefined;
  },

  deleteMemberFail(state: State, payload: { error: Error }) {
    state.memberDeleteLoading = false;
    state.memberDeleteError = payload.error;
  },

  updateMemberList(state: State, payload: { member: MemberListItem; vehicleList: VehicleListItem[] }) {
    const currentMember = state.memberList?.find((v) => v.id === payload.member.id);

    const updatedMemberListDeepClone = _.cloneDeep(payload.member);
    Object.assign(currentMember, updatedMemberListDeepClone);

    const currentVehicle = payload.vehicleList
      .find((v) => v.vehicleId === payload.member.vehicleId);

    if (currentVehicle) {
      currentVehicle.team = Sorter.sortByRank(currentVehicle.team);
    }
  },

  addMember(state: State) {
    state.loading = true;
  },

  addMemberSuccess(state: State, payload: { member: MemberListItem; vehicleList: VehicleListItem[] }) {
    if (state.memberList && payload.vehicleList) {
      state.memberList.push(payload.member);

      const currentVehicle = payload.vehicleList
        .find((v) => v.vehicleId === payload.member.vehicleId);

      if (currentVehicle) {
        const clonedTeam = _.cloneDeep(currentVehicle.team);
        clonedTeam.push(payload.member);
        currentVehicle.team = Sorter.sortByRank(clonedTeam);
      }
    }

    state.loading = false;
  },

  addMemberFail(state: State, payload: { error: Error }) {
    console.error('addMemberFail', payload);
    state.loading = false;
  },

  addMemberIntoVehicleTeam(state: State, payload: { member: MemberListItem; vehicleList: VehicleListItem[] }) {
    if (payload.vehicleList) {
      const currentVehicle = payload.vehicleList
        .find((v) => v.vehicleId === payload.member.vehicleId);

      if (currentVehicle) {
        const clonedTeam = _.cloneDeep(currentVehicle.team);
        clonedTeam.push(payload.member);
        currentVehicle.team = Sorter.sortByRank(clonedTeam);
      }
    }
  },

  // TODO: need?
  deleteMemberFromVehicleTeam(state: State, payload: { memberId: string; vehicleId: string; vehicleList: VehicleListItem[] }) {
    if (payload.vehicleList) {
      const currentVehicleIndex = payload.vehicleList
        .findIndex((v: VehicleListItem) => v.vehicleId === payload.vehicleId);

      if (currentVehicleIndex !== -1) {
        // eslint-disable-next-line no-param-reassign
        payload.vehicleList[currentVehicleIndex].team = payload.vehicleList[currentVehicleIndex].team
          .filter((v) => v.id !== payload.memberId);
      }
    }
  },

  // TODO: need?
  deleteVehicleTeamItem(state: State, payload: { memberId: string; vehicleId: string; vehicleList: VehicleListItem[] }) {
    if (payload.vehicleList) {
      const currentVehicleIndex = payload.vehicleList
        .findIndex((v: VehicleListItem) => v.vehicleId === payload.vehicleId);

      if (currentVehicleIndex !== -1) {
        // eslint-disable-next-line no-param-reassign
        payload.vehicleList[currentVehicleIndex].team = payload.vehicleList[currentVehicleIndex].team
          .filter((v) => v.id !== payload.memberId);
      }
    }

    if (state.memberList) {
      const currentMemberIndex = state.memberList.findIndex((v) => v.id === payload.memberId);

      if (currentMemberIndex !== -1) {
        state.memberList[currentMemberIndex] = {
          ...state.memberList[currentMemberIndex],
          vehicleId: '',
          vehicleName: '',
        };
      }
    }
  },

  updateVehicleTeamItem(state: State, payload: { member: MemberListItem; vehicleId: string; vehicleList: VehicleListItem[] }) {
    const currentVehicle = payload.vehicleList?.find((v: VehicleListItem) => v.vehicleId === payload.vehicleId);

    if (currentVehicle) {
      const currentMemberIndex = currentVehicle.team.findIndex((v: MemberListItem) => v.id === payload.member.id);

      if (currentMemberIndex !== -1) {
        currentVehicle.team[currentMemberIndex] = {
          ...currentVehicle.team[currentMemberIndex],
          ...payload.member,
        };
      }
    }
  },
};
