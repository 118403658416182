
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

export default defineComponent({
  name: 'rui-input',
  emits: ['changeFromInputComponent'],
  components: {
    RuiIcon,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(value: string) {
      this.$emit('changeFromInputComponent', value);
    },

    clear() {
      this.$emit('changeFromInputComponent', '');
    },
  },
});
