
import {defineComponent} from 'vue';

import RuiInput from '@/retinus-ui/components/input/RuiInput.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiAlert from '@/retinus-ui/components/alert/RuiAlert.vue';
import RuiAuthWrapper from '@/retinus-ui/components/auth/RuiAuthWrapper.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

import {ALERT_TEXT, AUTH_TEXT, BUTTON_TEXT, PLACEHOLDER_TEXT} from '@/app/core/constants';
import {BUTTON_TYPE, VALIDATE_HINT} from '@/retinus-ui/core/constants';
import Validator from '@/retinus-ui/core/validator';

import {mapActions} from 'vuex';
import {AuthModuleKey} from '@/app/data-flow/auth';

export default defineComponent({
  name: 'new-password',
  components: {
    RuiButton,
    RuiInput,
    RuiAlert,
    RuiAuthWrapper,
    RuiIcon,
  },
  data() {
    return {
      passwordFieldType: 'password',
      passwordConfirmFieldType: 'password',
      buttonTypeIcon: BUTTON_TYPE.icon,
      password: {
        password: '',
        confirm: '',
      },
      invalid: {
        password: false,
        confirm: false,
        coincidence: false,
      },
      hint: VALIDATE_HINT,
      formComplete: false,
      btnColor: BUTTON_TYPE.primary,
      title: AUTH_TEXT.newPassword,
      label: PLACEHOLDER_TEXT,
      alert: ALERT_TEXT,
      buttonText: BUTTON_TEXT,
      toLogin: AUTH_TEXT.returnToLogin,
      invalidField: false,
    };
  },
  methods: {
    ...mapActions(AuthModuleKey, ['newPassword']),

    changePassword(value: string) {
      this.invalid.password = false;
      this.invalid.coincidence = false;
      this.password.password = value;
      if (this.password.password === '') {
        this.passwordFieldType = 'password';
      }
    },

    changePasswordConfirm(value: string) {
      this.invalid.confirm = false;
      this.invalid.coincidence = false;
      this.password.confirm = value;
      if (this.password.confirm === '') {
        this.passwordConfirmFieldType = 'password';
      }
    },

    resetForm() {
      this.password.password = '';
      this.password.confirm = '';
      this.invalid.coincidence = false;
      this.passwordFieldType = 'password';
      this.passwordConfirmFieldType = 'password';
    },

    submitForm() {
      if (!Validator.passwordValid(this.password.password)) {
        this.invalid.password = true;
      }
      if (!Validator.passwordValid(this.password.confirm)) {
        this.invalid.confirm = true;
      }
      if (this.password.password !== this.password.confirm) {
        this.invalid.coincidence = true;
        return;
      }

      this.newPassword({password: this.password.password, token: this.$route.params.token})
        .then(() => {
          this.invalid.coincidence = false;
          this.formComplete = true;
        });
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    switchConfirmVisibility() {
      this.passwordConfirmFieldType = this.passwordConfirmFieldType === 'password' ? 'text' : 'password';
    },
  },
});
