
import {defineComponent} from 'vue';

import {mapGetters} from 'vuex';
import {GetterTypes} from '@/app/data-flow/types';
import {UserModuleKey} from '@/app/data-flow/user';

export default defineComponent({
  name: 'fire-profile-page',
  computed: {
    ...mapGetters(UserModuleKey, {
      userPhoto: GetterTypes.USER_PROFILE_PICTURE_BIG,
      userRole: GetterTypes.USER_PROFILE_ROLE,
      userName: GetterTypes.USER_PROFILE_NAME,
    }),
  },
});
