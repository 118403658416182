<template>
  <div :class="`rui-alert${checkType(type)}
                ${fixed ? 'rui-alert--fixed' : ''}
                ${hintMode ? 'rui-alert--hint' : ''}`">
    <rui-icon :icon="type"
              class-name="rui-ico"/>
    <span>{{ text }}</span>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

import {COLOR_TYPE} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'rui-alert',
  components: {
    RuiIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    hintMode: {
      type: Boolean,
      default: false,
    },
    type: String,
    fixed: Boolean,
  },
  methods: {
    checkType(type: string) {
      switch (type) {
        case COLOR_TYPE.danger:
          return '--danger';
        case COLOR_TYPE.warning:
          return '--warning';
        case COLOR_TYPE.success:
          return '--success';
        default:
          return '';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'alert';
</style>
