
import {defineComponent} from 'vue';
import FireLiveTrackerIcon from '@/app/components/live-tracker-icon/FireLiveTrackerIcon.vue';

export default defineComponent({
  name: 'fire-avatar',
  components: {
    FireLiveTrackerIcon,
  },
  props: {
    online: {
      type: Boolean,
      default: false,
    },
    photo: String,
    bigPhoto: {
      type: Boolean,
      default: false,
    },
  },
});
