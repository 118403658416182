<template>
  <div class="rui-card">
    <div class="rui-card__header"
         v-if="$slots.header">
      <slot name="header"/>
    </div>
    <div class="rui-card__body"
         :style="`max-height: ${bodyMaxHeight}`"
         v-if="$slots.body">
      <slot name="body"/>
    </div>
    <div class="rui-card__image"
         v-if="$slots.image">
      <slot name="image"/>
    </div>
    <div class="rui-card__footer"
         v-if="$slots.footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'rui-card-border',
  props: {
    bodyMaxHeight: String,
  },
});
</script>

<style lang="scss" scoped>
@import 'card-border';
</style>
