<template>
  <div class="rui-dynamic-input">
    <textarea class="rui-dynamic-input__control"
              @input="onChange($event.target)"
              ref="dynamicInput"
              v-bind="$attrs"
              :value="modelValue"/>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';

export default defineComponent({
  name: 'rui-dynamic-input',
  emits: ['grabText'],
  props: {
    modelValue: String,
  },
  setup() {
    const dynamicInput = ref(null);
    return {dynamicInput};
  },
  updated() {
    if (!this.modelValue) {
      (this.dynamicInput as unknown as HTMLElement).style.height = 'var(--rui-input-height)';
    }
  },
  methods: {
    onChange(target: HTMLTextAreaElement) {
      this.$emit('grabText', target.value);
      (this.dynamicInput as unknown as HTMLElement).style.height = `${target.scrollHeight}px`;
    },
  },
});
</script>
<style lang="scss" scoped>
@import 'input';
</style>
