<template>
  <div class="rui-notify">
    <div v-if="icon"
         :class="`rui-notify__ico rui-notify${checkType(type)}`">{{ text }}
    </div>
    <slot name="body"/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiTools from '@/retinus-ui/core/RuiTools';

export default defineComponent({
  name: 'rui-notify',
  props: {
    text: Number,
    type: String,
    icon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    checkType(type: string) {
      return RuiTools.setColorStyle(type);
    },
  },
});
</script>
<style lang="scss" scoped>
@import 'notify';
</style>
