<template>
  <rui-page-layout class="fire-page-spaces">
    <template v-slot:head>
      {{support}}
    </template>
    <template v-slot:body>
      <rui-alert v-if="messageSubmit"
                 :fixed="true"
                 :text="message"
                 type="success"/>
      <fire-support-form @changeInput="changeInput($event)"
                         :disableSubmit="disableFormSubmit()"
                         :descriptionValue="formDescription"
                         @submitForm="submitNote()"
                         @resetForm="resetNote()"
                         :subjectValue="formSubject"
                         @changeTextarea="changeTextarea($event)"/>
    </template>
  </rui-page-layout>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiPageLayout from '@/retinus-ui/components/layout/pageLayout/RuiPageLayout.vue';
import FireSupportForm from '@/app/views/support/components/FireSupportForm.vue';
import RuiAlert from '@/retinus-ui/components/alert/RuiAlert.vue';
import {mapActions} from 'vuex';
import {SupportModuleKey} from '@/app/data-flow/support';
import {ALERT_TEXT, SUPPORT} from '../../core/constants';

export default defineComponent({
  name: 'fire-support-page',
  components: {
    FireSupportForm,
    RuiPageLayout,
    RuiAlert,
  },
  data() {
    return {
      formDescription: '' as string,
      formSubject: '' as string,
      messageSubmit: false as boolean,
      buttonDisabled: false as boolean,
      message: '' as string,
      support: SUPPORT as string,
    };
  },
  methods: {
    ...mapActions(SupportModuleKey, ['addMessage']),

    changeInput(val: string): void {
      this.formSubject = val;
    },

    changeTextarea(val: string): void {
      this.formDescription = val;
    },

    disableFormSubmit(): boolean {
      return !(this.formDescription && this.formSubject) || this.buttonDisabled;
    },

    submitNote(): void {
      this.message = ALERT_TEXT.supportSuccess;

      if (this.formDescription && this.formSubject) {
        this.addMessage({
          subject: this.formSubject,
          description: this.formDescription,
        }).then(() => {
          this.messageSubmit = true;
          this.buttonDisabled = true;

          setTimeout(() => {
            this.messageSubmit = false;
            this.formSubject = '';
            this.formDescription = '';
            this.buttonDisabled = false;
          }, 2500);
        });
      }
    },

    resetNote() {
      this.formDescription = '';
      this.formSubject = '';
    },
  },
});
</script>

<style lang="scss" scoped>
@import '../../../styles/components/form';
</style>
