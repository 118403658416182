<template>
  <canvas id="heartrate" class="fire-graphic"></canvas>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Chart from 'chart.js';

export default defineComponent({
  props: {
    currentHeartRate: Array,
    timestamp: Array,
  },
  data() {
    return {
      myChart: Chart as unknown as Chart,
    };
  },
  methods: {
    createChart(chartId: string) {
      this.myChart = new Chart(chartId, {
        type: 'line',
        data: {
          labels: this.timestamp as Array<string>,
          datasets: [
            {
              label: 'Heart Rate',
              data: this.currentHeartRate as Array<number>,
              borderColor: '#71B2D9',
              borderWidth: 3,
              pointRadius: 1,
              lineTension: 0,
            },
          ],
        },
        options: {
          events: [],
          animation: {
            duration: 0,
          },
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  color: 'rgba(113, 178, 217, 0.2)',
                },
              },
            ],
          },
        },
      });
    },
  },
  mounted() {
    this.createChart('heartrate');
  },
  watch: {
    currentHeartRate() {
      this.myChart.destroy();
      this.createChart('heartrate');
    },
    timestamp() {
      this.myChart.destroy();
      this.createChart('heartrate');
    },
  },
});
</script>
