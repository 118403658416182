import * as imgProfileMin from '@/assets/img/mockPhotoMin.png';
import * as imgProfileBig from '@/assets/img/mockPhotoBig.jpg';

export const USER = {
  imgMin: imgProfileMin,
  imgBig: imgProfileBig,
  lastName: 'Saitama',
  firstName: 'S.',
  role: 'hero',
};

export const CO2Data = {
  type: 'line',
  data: {
    labels: [
      '0',
      '0:30',
      '1:00',
      '1:30',
      '2:00',
      '2:30',
      '3:00',
      '3:30',
      '4:00',
    ],
    datasets: [
      {
        label: 'ppm',
        data: [54, 54, 51, 46, 55, 55, 48, 53, 54],
        borderColor: '#71B2D9',
        lineTension: 0,
        borderWidth: 3,
        pointRadius: 1,
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    responsive: true,

    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            color: 'rgba(113, 178, 217, 0.2)',
          },
        },
      ],
    },
  },
};

export const VEHICLE_LIST_DATA = [
  {
    vehicleId: 'awf79504580efsezfs5048654',
    name: 'Truck 1',
    assignment: 'fire attack',
    par: 1,
    lastPar: '2018-12-10T13:49:51.141Z',
    team: [
      {
        id: 'advvfvdfba4tt4',
        vehicleId: 'awf79504580efsezfs5048654',
        vehicleName: 'Truck 1',
        fullName: 'S. Edwards',
        rank: 'Cpt',
        location: 'San Francisco. CA 12',
      },
    ],
  },
  {
    vehicleId: 'awf795ddd04580dvcsDZVtzgrr50dgrh48654',
    name: 'Truck 2',
    assignment: 'fire attack',
    par: 2,
    lastPar: '2018-12-10T13:49:51.141Z',
    team: [
      {
        id: 'advvfvddddfba4rdthrthdtnrdnydttyytt4',
        vehicleId: 'awf795ddd04580dvcsDZVtzgrr50dgrh48654',
        vehicleName: 'Truck 2',
        fullName: 'S. Ericsson',
        rank: 'lt',
        location: 'San Francisco. CA 123',
      },
    ],
  },
  {
    vehicleId: 'awf7ds95ddd045805048654',
    name: 'Truck 3',
    assignment: 'fire attack',
    par: 1,
    lastPar: '2018-12-10T13:49:51.141Z',
    team: [
      {
        id: 'advvfvdfba4tt4',
        vehicleId: 'awf7ds95ddd045805048654',
        vehicleName: 'Truck 3',
        fullName: 'S. Edwards',
        rank: 'cpt',
        location: 'San Francisco. CA 12',
      },
      {
        id: 'advvfvddddfbgergegvbbhhhhhhha4tt4',
        vehicleId: 'awf7ds95ddd045805048654',
        vehicleName: 'Truck 3',
        fullName: 'S. Ericsson',
        rank: 'lt',
        location: 'San Francisco. CA 123',
      },
      {
        id: 'advvfvddddfba4tt4seuuuuuuuuuuuuuugvse53s',
        vehicleId: 'awf7ds95ddd045805048654',
        vehicleName: 'Truck 3',
        fullName: 'S. Scizo2',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
    ],
  },
  {
    vehicleId: 'awf795045805048654t',
    name: 'Truck 4',
    assignment: 'fire attack',
    par: 1,
    lastPar: '2018-12-10T13:49:51.141Z',
    team: [
      {
        id: 'advvfvddddfi0sgrgesga4tt4',
        vehicleId: 'awf795045805048654t',
        vehicleName: 'Truck 4',
        fullName: 'S. Scizo3',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
    ],
  },
  {
    vehicleId: 'awf795ddd045805deye5udjmcm048654',
    name: 'Truck 5',
    assignment: 'fire attack',
    par: 2,
    lastPar: '2018-12-10T13:49:51.141Z',
    team: [
      {
        id: 'rzwefergergregsr',
        vehicleId: 'awf795ddd045805deye5udjmcm048654',
        vehicleName: 'Truck 5',
        fullName: 'S. Ericsson',
        rank: 'lt',
        location: 'San Francisco. CA 123',
      },
      {
        id: 'advvfvddddfubfzserea4tt4',
        vehicleId: 'awf795ddd045805deye5udjmcm048654',
        vehicleName: 'Truck 5',
        fullName: 'S. Scizo',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
      {
        id: 'advvfvddddyutrfba4tt4',
        vehicleId: 'awf795ddd045805deye5udjmcm048654',
        vehicleName: 'Truck 5',
        fullName: 'S. Scizo4',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
      {
        id: 'advvfvrrddddfbe56u5v6uuni7a4tt4',
        vehicleId: 'awf795ddd045805deye5udjmcm048654',
        vehicleName: 'Truck 5',
        fullName: 'S. Scizo5',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
      {
        id: 'advvfvddssddfba4tt4',
        vehicleId: 'awf795ddd045805deye5udjmcm048654',
        vehicleName: 'Truck 5',
        fullName: 'S. Scizo6',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
    ],
  },
  {
    vehicleId: 'awf7ds95ddd045ttrth805048654',
    name: 'Truck 6',
    assignment: 'fire attack',
    par: 1,
    lastPar: '2018-12-10T13:49:51.141Z',
    team: [
      {
        id: 'advvfvdfba4tt4',
        vehicleId: 'awf7ds95ddd045ttrth805048654',
        vehicleName: 'Truck 6',
        fullName: 'S. Edwards',
        rank: 'cpt',
        location: 'San Francisco. CA 12',
      },
      {
        id: 'advvfvddssddfba4ttygunugyuoinuh4',
        vehicleId: 'awf7ds95ddd045ttrth805048654',
        vehicleName: 'Truck 6',
        fullName: 'S. Ericsson',
        rank: 'lt',
        location: 'San Francisco. CA 123',
      },
      {
        id: 'advvfvdddfxfdddfba4tt4',
        vehicleId: 'awf7ds95ddd045ttrth805048654',
        vehicleName: 'Truck 6',
        fullName: 'S. Scizo',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
    ],
  },
  {
    vehicleId: 'awf795045805048x654',
    name: 'Truck 7',
    assignment: 'fire attack',
    par: 1,
    lastPar: '2018-12-10T13:49:51.141Z',
    team: [
      {
        id: 'advvfvdfba4tt4',
        vehicleId: 'awf795045805048x654',
        vehicleName: 'Truck 7',
        fullName: 'S. Edwards',
        rank: 'cpt',
        location: 'San Francisco. CA 12',
      },
      {
        id: 'advvfvddddf7777777777777777777777ba4tt4',
        vehicleId: 'awf795045805048x654',
        vehicleName: 'Truck 7',
        fullName: 'S. Ericsson',
        rank: 'lt',
        location: 'San Francisco. CA 123',
      },
      {
        id: 'advvfvddddfbyyyyyyyyyyyyyyyyyyyya4tt4',
        vehicleId: 'awf795045805048x654',
        vehicleName: 'Truck 7',
        fullName: 'S. Scizo',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
    ],
  },
  {
    vehicleId: 'awf795ddd04580erhe5hg5048654',
    name: 'Truck 8',
    assignment: 'fire attack',
    par: 2,
    lastPar: '2018-12-10T13:49:51.141Z',
    team: [
      {
        id: 'advvfvddddfba4rageree55555555555555555tt4',
        vehicleId: 'awf795ddd04580erhe5hg5048654',
        vehicleName: 'Truck 8',
        fullName: 'S. Ericsson',
        rank: 'lt',
        location: 'San Francisco. CA 123',
      },
      {
        id: 'advvfvddddfuba4ttys4',
        vehicleId: 'awf795ddd04580erhe5hg5048654',
        vehicleName: 'Truck 8',
        fullName: 'S. Scizo7',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
      {
        id: 'advvfvddddyutrfba4tt4',
        vehicleId: 'awf795ddd04580erhe5hg5048654',
        vehicleName: 'Truck 8',
        fullName: 'S. Scizo8',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
      {
        id: 'advvfvr5555555555yyyrddddfba4tt4',
        vehicleId: 'awf795ddd04580erhe5hg5048654',
        vehicleName: 'Truck 8',
        fullName: 'S. Scizo88',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
      {
        id: 'advvfvddsssy5by5yudu6ddfba4tt4',
        vehicleId: 'awf795ddd04580erhe5hg5048654',
        vehicleName: 'Truck 8',
        fullName: 'S. Scizo999',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
      {
        id: 'advvfvddsssy5bysvsdvsdv5yudu6ddfba4tt4',
        vehicleId: 'awf795ddd04580erhe5hg5048654',
        vehicleName: 'Truck 8',
        fullName: 'S. Scizo55',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
      {
        id: 'advvfvddsasdsdasdcassssy5bysvsdvsdv5yudu6ddfba4tt4',
        vehicleId: 'awf795ddd04580erhe5hg5048654',
        vehicleName: 'Truck 8',
        fullName: 'S. Scizo545',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
      {
        id: 'advvfvddsasaadsdasddcassssy5bysvsdvsdv5yudu6ddfba4tt4',
        vehicleId: 'awf795ddd04580erhe5hg5048654',
        vehicleName: 'Truck 8',
        fullName: 'S. Scizo776',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
      {
        id: 'advvfvddsasaadsdasddcassss5555yudu6ddfba4tt4',
        vehicleId: 'awf795ddd04580erhe5hg5048654',
        vehicleName: 'Truck 8',
        fullName: 'S. Scizo',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
      {
        id: '54y4e5y5yv',
        vehicleId: 'awf795ddd04580erhe5hg5048654',
        vehicleName: 'Truck 8',
        fullName: 'S. Scizo3',
        rank: 'Ff',
        location: 'San Francisco. CA 123dd',
      },
    ],
  },
];

export const MEMBER_LIST_DATA = [
  {
    id: 'advvfvddddfba4rageree55555555555555555tt4',
    vehicleId: 'awf795ddd04580erhe5hg5048654',
    vehicleName: 'Truck 8',
    fullName: 'S. Ericsson',
    rank: 'lt',
    location: 'San Francisco. CA 123',
  },
  {
    id: 'advvfvdfba4tt4',
    vehicleId: 'awf795045805048x654',
    vehicleName: 'Truck 7',
    fullName: 'S. Edwards',
    rank: 'cpt',
    location: 'San Francisco. CA 12',
  },
  {
    id: 'advvfvddddfuba4ttys4',
    fullName: 'S. Scizo7',
    rank: 'ff',
    vehicleId: '',
    vehicleName: '',
    location: 'San Francisco. CA 123',
  },
];

export const ROSTER_LIST_DATA = [
  {
    id: '60397b14ef2a990cbba822a6',
    // eslint-disable-next-line max-len
    photo: 'https://static.ngs.ru/news/2020/99/preview/8290b39b6601efa615afd4bbd93d82320a039883_720_405_c.png',
    currentLocation: '1875 Mission Street, CA 94301 San Francisco 111',
    liveTracker: true,
    rank: 'Cpt',
    dateOfRank: '2021-02-26T22:49:56+00:00',
    role: 'driver',
    firstName: 'Sabrina',
    lastName: 'Pugh',
    dateOfBirth: '1973-02-26T22:49:56+00:00',
    sex: 'female',
    age: 48,
    dateOfExperience: '2001-02-26T22:49:56+00:00',
    departmentLocation: '444 Fanchon Place, Inkerman, Utah, 4555',
    lastIncidentID: '60397b14ee0ea9ee8a03d22c',
    heartRate: 75, // bpm - number in card-border header.
    restingHeartRate: [
      56,
      60,
      59,
      59,
      58,
      62,
      59,
      61,
      57,
      57,
      60,
      63,
      60,
      62,
      70,
      65,
      59,
      56,
      59,
      60,
      64,
      64,
      61,
      56,
      58,
      58,
      58,
      60,
      60,
      63,
      59,
      61,
      62,
      61,
      60,
      60,
      62,
      58,
      58,
      60,
      64,
      59,
      54,
      53,
      52,
      50,
      55,
      56,
      62,
      58,
      56,
      59,
      63,
      69,
      66,
      66,
      66,
      62,
      66,
      66,
    ],
    activeHeartRate: [
      130,
      131,
      147,
      146,
      142,
      143,
      140,
      133,
      149,
      137,
      136,
      131,
      145,
      157,
      143,
      146,
      139,
      144,
      149,
      133,
      130,
      145,
      141,
      138,
      143,
      141,
      144,
      140,
      134,
      136,
      130,
      142,
      145,
      142,
      143,
      144,
      152,
      156,
      159,
      152,
      154,
      148,
      156,
      158,
      142,
      153,
      147,
      156,
      132,
      133,
      136,
      147,
      147,
      152,
      151,
      147,
      149,
      159,
      142,
      141,
    ],
    oxygen: 96.0, // vomax.
    temp: 82.4,
    latitude: -0.291828, // future.
    longitude: -138.151397, // future.
    scba: [
      {
        maskStatus: false,
        oxygenLevel: 'low',
      },
    ],
    hazmat: 'danger',
    smoke: 97.8139, // future.
    blood: 'AB+',
    injuries: 3,
    hazExposure: 7,
    healthScreen: 1,
    healthRecords: 4,
    currentBiometrics: [
      {
        id: '603943920872a1727e294cea',
        heartRate: 71,
        oxygen: 96,
        temp: 28,
        latitude: 123, // future.
        longitude: 456, // future.
        steps: 440, // future.
        altitude: 111.76302337646484, // future.
        pressure: 102746.5703125, // future.
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365586645, // future.
      },
      {
        id: '603943930872a1727e294ceb',
        heartRate: 72,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.59375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365587062,
      },
      {
        id: '603943930872a1727e294c',
        heartRate: 72,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.5625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365587199,
      },
      {
        id: '603943940872a1727e294ced',
        heartRate: 72,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.59375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365588204,
      },
      {
        id: '603943940872a1727e294cee',
        heartRate: 71,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.4921875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365588282,
      },
      {
        id: '603943950872a1727e294cef',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.453125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365589268,
      },
      {
        id: '603943960872a1727e294cf0',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.4375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365590291,
      },
      {
        id: '603943970872a1727e294cf1',
        heartRate: 68,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.4921875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365591388,
      },
      {
        id: '603943980872a1727e294cf2',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.59375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365592241,
      },
      {
        id: '603943990872a1727e294cf3',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6484375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365593298,
      },
      {
        id: '6039439a0872a1727e294cf4',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6796875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365594270,
      },
      {
        id: '6039439b0872a1727e294cf5',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365595312,
      },
      {
        id: '6039439c0872a1727e294cf6',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.65625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365596207,
      },
      {
        id: '6039439d0872a1727e294cf7',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6484375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365597362,
      },
      {
        id: '6039439e0872a1727e294cf8',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.53125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365598283,
      },
      {
        id: '6039439f0872a1727e294cf9',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.421875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365599431,
      },
      {
        id: '603943a00872a1727e294cfa',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365600336,
      },
      {
        id: '603943a10872a1727e294cfb',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.796875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365601256,
      },
      {
        id: '603943a20872a1727e294cfc',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.53125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365602271,
      },
      {
        id: '603943a30872a1727e294cfd',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.7109375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365603237,
      },
      {
        id: '603943a40872a1727e294cfe',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.421875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365604324,
      },
      {
        id: '603943a50872a1727e294cff',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.5546875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365605242,
      },
      {
        id: '603943a60872a1727e294d00',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.9296875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365606474,
      },
      {
        id: '603943a70872a1727e294d01',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.046875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365607274,
      },
      {
        id: '603943a80872a1727e294d02',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365608270,
      },
      {
        id: '603943a90872a1727e294d03',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.9296875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365609336,
      },
      {
        id: '603943aa0872a1727e294d04',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.0078125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365610262,
      },
      {
        id: '603943ab0872a1727e294d05',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365611194,
      },
      {
        id: '603943ac0872a1727e294d06',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.7578125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365612362,
      },
      {
        id: '603943ad0872a1727e294d07',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365613381,
      },
      {
        id: '603943ae0872a1727e294d08',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.6640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365614306,
      },
      {
        id: '603943af0872a1727e294d09',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.6171875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365615336,
      },
      {
        id: '603943b00872a1727e294d0a',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.8828125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365616302,
      },
      {
        id: '603943b10872a1727e294d0b',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.328125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365617277,
      },
      {
        id: '603943b20872a1727e294d0c',
        heartRate: 77,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.8984375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365618351,
      },
      {
        id: '603943b30872a1727e294d0d',
        heartRate: 77,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.1953125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365619274,
      },
      {
        id: '603943b40872a1727e294d0e',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.3359375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365620444,
      },
      {
        id: '603943b50872a1727e294d0f',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.40625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365621257,
      },
      {
        id: '603943b60872a1727e294d10',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.2734375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365622498,
      },
      {
        id: '603943b70872a1727e294d11',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.9765625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365623259,
      },
      {
        id: '603943b80872a1727e294d12',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.828125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365624331,
      },
      {
        id: '603943b90872a1727e294d13',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.6796875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365625365,
      },
      {
        id: '603943ba0872a1727e294d14',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.421875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365626377,
      },
      {
        id: '603943bb0872a1727e294d15',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.09375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365627261,
      },
      {
        id: '603943bc0872a1727e294d16',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.8671875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365628331,
      },
      {
        id: '603943bd0872a1727e294d17',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.7578125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365629202,
      },
      {
        id: '603943be0872a1727e294d18',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365630369,
      },
      {
        id: '603943bf0872a1727e294d19',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.5859375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365631264,
      },
      {
        id: '603943c00872a1727e294d1a',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.5390625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365632278,
      },
      {
        id: '603943c10872a1727e294d1b',
        heartRate: 68,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.3984375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365633200,
      },
      {
        id: '603943c20872a1727e294d1c',
        heartRate: 69,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.3359375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365634191,
      },
      {
        id: '603943c30872a1727e294d1d',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.296875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365635249,
      },
      {
        id: '603943c40872a1727e294d1e',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.203125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365636406,
      },
      {
        id: '603943c50872a1727e294d1f',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.234375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365637429,
      },
      {
        id: '603943c60872a1727e294d20',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.1875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365638348,
      },
      {
        id: '603943c70872a1727e294d21',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.1484375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365639221,
      },
      {
        id: '603943c80872a1727e294d22',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365640263,
      },
      {
        id: '603943c90872a1727e294d23',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.1328125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365641317,
      },
      {
        id: '603943ca0872a1727e294d24',
        heartRate: 74,
        temp: 30,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.15625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365642215,
      },
      {
        id: '603943cb0872a1727e294d25',
        heartRate: 75,
        temp: 50,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102742.5390625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365643378,
      },
    ],
  },
  {
    id: '60397b14ef2a990cbba8fgnbcfnfn22a6',
    photo: imgProfileMin,
    currentLocation: '1875 Mission Street, CA 94301 San Francisco 222',
    liveTracker: true,
    rank: 'Cpt',
    dateOfRank: '2021-02-26T22:49:56+00:00',
    role: 'driver',
    firstName: 'Steve',
    lastName: 'Edwards',
    dateOfBirth: '1973-02-26T22:49:56+00:00',
    sex: 'female',
    age: 48,
    dateOfExperience: '2001-02-26T22:49:56+00:00',
    departmentLocation: '675 Fanchon Place, Inkerman, Utah, 4444',
    lastIncidentID: '60397b14ee0ea9ee8a03d22c',
    heartRate: 155, // bpm - number in card-border header.
    oxygen: 39.6, // vomax.
    temp: 98.88,
    latitude: -0.291828, // future.
    longitude: -138.151397, // future.
    scba: [
      {
        maskStatus: false,
        oxygenLevel: 'low',
      },
    ],
    hazmat: 'danger',
    smoke: 97.8139, // future.
    blood: 'AB+',
    injuries: 3,
    hazExposure: 7,
    healthScreen: 1,
    healthRecords: 4,
    currentBiometrics: [
      {
        id: '603943920872a1727e294cea',
        heartRate: 71,
        oxygen: 96,
        temp: 28,
        latitude: 123, // future.
        longitude: 456, // future.
        steps: 440, // future.
        altitude: 111.76302337646484, // future.
        pressure: 102746.5703125, // future.
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365586645, // future.
      },
      {
        id: '603943930872a1727e294ceb',
        heartRate: 72,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.59375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365587062,
      },
      {
        id: '603943930872a1727e294c',
        heartRate: 72,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.5625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365587199,
      },
      {
        id: '603943940872a1727e294ced',
        heartRate: 72,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.59375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365588204,
      },
      {
        id: '603943940872a1727e294cee',
        heartRate: 71,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.4921875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365588282,
      },
      {
        id: '603943950872a1727e294cef',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.453125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365589268,
      },
      {
        id: '603943960872a1727e294cf0',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.4375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365590291,
      },
      {
        id: '603943970872a1727e294cf1',
        heartRate: 68,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.4921875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365591388,
      },
      {
        id: '603943980872a1727e294cf2',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.59375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365592241,
      },
      {
        id: '603943990872a1727e294cf3',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6484375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365593298,
      },
      {
        id: '6039439a0872a1727e294cf4',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6796875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365594270,
      },
      {
        id: '6039439b0872a1727e294cf5',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365595312,
      },
      {
        id: '6039439c0872a1727e294cf6',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.65625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365596207,
      },
      {
        id: '6039439d0872a1727e294cf7',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6484375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365597362,
      },
      {
        id: '6039439e0872a1727e294cf8',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.53125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365598283,
      },
      {
        id: '6039439f0872a1727e294cf9',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.421875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365599431,
      },
      {
        id: '603943a00872a1727e294cfa',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365600336,
      },
      {
        id: '603943a10872a1727e294cfb',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.796875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365601256,
      },
      {
        id: '603943a20872a1727e294cfc',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.53125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365602271,
      },
      {
        id: '603943a30872a1727e294cfd',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.7109375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365603237,
      },
      {
        id: '603943a40872a1727e294cfe',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.421875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365604324,
      },
      {
        id: '603943a50872a1727e294cff',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.5546875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365605242,
      },
      {
        id: '603943a60872a1727e294d00',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.9296875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365606474,
      },
      {
        id: '603943a70872a1727e294d01',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.046875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365607274,
      },
      {
        id: '603943a80872a1727e294d02',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365608270,
      },
      {
        id: '603943a90872a1727e294d03',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.9296875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365609336,
      },
      {
        id: '603943aa0872a1727e294d04',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.0078125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365610262,
      },
      {
        id: '603943ab0872a1727e294d05',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365611194,
      },
      {
        id: '603943ac0872a1727e294d06',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.7578125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365612362,
      },
      {
        id: '603943ad0872a1727e294d07',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365613381,
      },
      {
        id: '603943ae0872a1727e294d08',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.6640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365614306,
      },
      {
        id: '603943af0872a1727e294d09',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.6171875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365615336,
      },
      {
        id: '603943b00872a1727e294d0a',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.8828125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365616302,
      },
      {
        id: '603943b10872a1727e294d0b',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.328125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365617277,
      },
      {
        id: '603943b20872a1727e294d0c',
        heartRate: 77,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.8984375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365618351,
      },
      {
        id: '603943b30872a1727e294d0d',
        heartRate: 77,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.1953125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365619274,
      },
      {
        id: '603943b40872a1727e294d0e',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.3359375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365620444,
      },
      {
        id: '603943b50872a1727e294d0f',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.40625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365621257,
      },
      {
        id: '603943b60872a1727e294d10',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.2734375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365622498,
      },
      {
        id: '603943b70872a1727e294d11',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.9765625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365623259,
      },
      {
        id: '603943b80872a1727e294d12',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.828125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365624331,
      },
      {
        id: '603943b90872a1727e294d13',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.6796875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365625365,
      },
      {
        id: '603943ba0872a1727e294d14',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.421875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365626377,
      },
      {
        id: '603943bb0872a1727e294d15',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.09375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365627261,
      },
      {
        id: '603943bc0872a1727e294d16',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.8671875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365628331,
      },
      {
        id: '603943bd0872a1727e294d17',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.7578125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365629202,
      },
      {
        id: '603943be0872a1727e294d18',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365630369,
      },
      {
        id: '603943bf0872a1727e294d19',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.5859375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365631264,
      },
      {
        id: '603943c00872a1727e294d1a',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.5390625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365632278,
      },
      {
        id: '603943c10872a1727e294d1b',
        heartRate: 68,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.3984375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365633200,
      },
      {
        id: '603943c20872a1727e294d1c',
        heartRate: 69,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.3359375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365634191,
      },
      {
        id: '603943c30872a1727e294d1d',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.296875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365635249,
      },
      {
        id: '603943c40872a1727e294d1e',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.203125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365636406,
      },
      {
        id: '603943c50872a1727e294d1f',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.234375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365637429,
      },
      {
        id: '603943c60872a1727e294d20',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.1875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365638348,
      },
      {
        id: '603943c70872a1727e294d21',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.1484375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365639221,
      },
      {
        id: '603943c80872a1727e294d22',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365640263,
      },
      {
        id: '603943c90872a1727e294d23',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.1328125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365641317,
      },
      {
        id: '603943ca0872a1727e294d24',
        heartRate: 74,
        temp: 30,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.15625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365642215,
      },
      {
        id: '603943cb0872a1727e294d25',
        heartRate: 75,
        temp: 50,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102742.5390625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365643378,
      },
    ],
  },
  {
    id: '60397b14ef2a990cbba822dfbhgha6',
    photo: imgProfileMin,
    currentLocation: '1875 Mission Street, CA 94301 San Francisco',
    liveTracker: true,
    rank: 'Cpt',
    dateOfRank: '2021-02-26T22:49:56+00:00',
    role: 'driver',
    firstName: 'Sabrina2',
    lastName: 'Pugh',
    dateOfBirth: '1973-02-26T22:49:56+00:00',
    sex: 'female',
    age: 48,
    dateOfExperience: '2001-02-26T22:49:56+00:00',
    departmentLocation: '677 Fanchon Place, Inkerman, Utah, 4917',
    lastIncidentID: '60397b14ee0ea9ee8a03d22c',
    heartRate: 155, // bpm - number in card-border header.
    oxygen: 39.6, // vomax.
    temp: 98.88,
    latitude: -0.291828, // future.
    longitude: -138.151397, // future.
    scba: [
      {
        maskStatus: false,
        oxygenLevel: 'low',
      },
    ],
    hazmat: 'danger',
    smoke: 97.8139, // future.
    blood: 'AB+',
    injuries: 3,
    hazExposure: 7,
    healthScreen: 1,
    healthRecords: 4,
    currentBiometrics: [
      {
        id: '603943920872a1727e294cea',
        heartRate: 71,
        oxygen: 96,
        temp: 28,
        latitude: 123, // future.
        longitude: 456, // future.
        steps: 440, // future.
        altitude: 111.76302337646484, // future.
        pressure: 102746.5703125, // future.
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365586645, // future.
      },
      {
        id: '603943930872a1727e294ceb',
        heartRate: 72,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.59375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365587062,
      },
      {
        id: '603943930872a1727e294c',
        heartRate: 72,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.5625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365587199,
      },
      {
        id: '603943940872a1727e294ced',
        heartRate: 72,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.59375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365588204,
      },
      {
        id: '603943940872a1727e294cee',
        heartRate: 71,
        oxygen: 96,
        temp: 28,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.4921875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365588282,
      },
      {
        id: '603943950872a1727e294cef',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.453125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365589268,
      },
      {
        id: '603943960872a1727e294cf0',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.4375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365590291,
      },
      {
        id: '603943970872a1727e294cf1',
        heartRate: 68,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.4921875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365591388,
      },
      {
        id: '603943980872a1727e294cf2',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.59375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365592241,
      },
      {
        id: '603943990872a1727e294cf3',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6484375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365593298,
      },
      {
        id: '6039439a0872a1727e294cf4',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6796875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365594270,
      },
      {
        id: '6039439b0872a1727e294cf5',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365595312,
      },
      {
        id: '6039439c0872a1727e294cf6',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.65625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365596207,
      },
      {
        id: '6039439d0872a1727e294cf7',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.6484375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365597362,
      },
      {
        id: '6039439e0872a1727e294cf8',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.53125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365598283,
      },
      {
        id: '6039439f0872a1727e294cf9',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.421875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365599431,
      },
      {
        id: '603943a00872a1727e294cfa',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365600336,
      },
      {
        id: '603943a10872a1727e294cfb',
        heartRate: 71,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.796875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365601256,
      },
      {
        id: '603943a20872a1727e294cfc',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.53125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365602271,
      },
      {
        id: '603943a30872a1727e294cfd',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.7109375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365603237,
      },
      {
        id: '603943a40872a1727e294cfe',
        heartRate: 70,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.421875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365604324,
      },
      {
        id: '603943a50872a1727e294cff',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.5546875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365605242,
      },
      {
        id: '603943a60872a1727e294d00',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.9296875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365606474,
      },
      {
        id: '603943a70872a1727e294d01',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.046875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365607274,
      },
      {
        id: '603943a80872a1727e294d02',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365608270,
      },
      {
        id: '603943a90872a1727e294d03',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.9296875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365609336,
      },
      {
        id: '603943aa0872a1727e294d04',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102746.0078125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365610262,
      },
      {
        id: '603943ab0872a1727e294d05',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365611194,
      },
      {
        id: '603943ac0872a1727e294d06',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.7578125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365612362,
      },
      {
        id: '603943ad0872a1727e294d07',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365613381,
      },
      {
        id: '603943ae0872a1727e294d08',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.6640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365614306,
      },
      {
        id: '603943af0872a1727e294d09',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.6171875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365615336,
      },
      {
        id: '603943b00872a1727e294d0a',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.8828125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365616302,
      },
      {
        id: '603943b10872a1727e294d0b',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.328125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365617277,
      },
      {
        id: '603943b20872a1727e294d0c',
        heartRate: 77,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.8984375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365618351,
      },
      {
        id: '603943b30872a1727e294d0d',
        heartRate: 77,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.1953125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365619274,
      },
      {
        id: '603943b40872a1727e294d0e',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.3359375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365620444,
      },
      {
        id: '603943b50872a1727e294d0f',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.40625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365621257,
      },
      {
        id: '603943b60872a1727e294d10',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102745.2734375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365622498,
      },
      {
        id: '603943b70872a1727e294d11',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.9765625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365623259,
      },
      {
        id: '603943b80872a1727e294d12',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.828125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365624331,
      },
      {
        id: '603943b90872a1727e294d13',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.6796875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365625365,
      },
      {
        id: '603943ba0872a1727e294d14',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.421875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365626377,
      },
      {
        id: '603943bb0872a1727e294d15',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102744.09375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365627261,
      },
      {
        id: '603943bc0872a1727e294d16',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.8671875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365628331,
      },
      {
        id: '603943bd0872a1727e294d17',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.7578125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365629202,
      },
      {
        id: '603943be0872a1727e294d18',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.640625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365630369,
      },
      {
        id: '603943bf0872a1727e294d19',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.5859375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365631264,
      },
      {
        id: '603943c00872a1727e294d1a',
        heartRate: 73,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.5390625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365632278,
      },
      {
        id: '603943c10872a1727e294d1b',
        heartRate: 68,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.3984375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365633200,
      },
      {
        id: '603943c20872a1727e294d1c',
        heartRate: 69,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.3359375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365634191,
      },
      {
        id: '603943c30872a1727e294d1d',
        heartRate: 72,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.296875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365635249,
      },
      {
        id: '603943c40872a1727e294d1e',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.203125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365636406,
      },
      {
        id: '603943c50872a1727e294d1f',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.234375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365637429,
      },
      {
        id: '603943c60872a1727e294d20',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.1875,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365638348,
      },
      {
        id: '603943c70872a1727e294d21',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.1484375,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365639221,
      },
      {
        id: '603943c80872a1727e294d22',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365640263,
      },
      {
        id: '603943c90872a1727e294d23',
        heartRate: 76,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.1328125,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365641317,
      },
      {
        id: '603943ca0872a1727e294d24',
        heartRate: 74,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102743.15625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365642215,
      },
      {
        id: '603943cb0872a1727e294d25',
        heartRate: 75,
        temp: 28,
        oxygen: 96,
        latitude: 123,
        longitude: 456,
        steps: 440,
        altitude: 111.76302337646484,
        pressure: 102742.5390625,
        // watchId: 'b494172284ced643fddfdcff6ba6ced80ccf1e43',
        timestamp: 1614365643378,
      },
    ],
  },
];

export const CURRENT_INCIDENT_DATA = {
  incidentId: 'sdv234234eeee343afdvfv',
  incidentLocation: '1875 Mission Street, CA, 94103, San Francisco',
  incidentTotalPar: 1,
  newVehiclesId: ['465654656dfgfgxdf'],
};

export const ARRIVED_VEHICLE = {
  vehicleId: '465654656dfgfgxdf',
  name: 'Truck 9',
  assignment: 'fire attack',
  par: 0,
  lastPar: '',
  team: [
    {
      id: 'dgdtgrtgdvt',
      vehicleId: '465654656dfgfgxdf',
      vehicleName: 'Truck 9',
      fullName: 'I. Mask',
      rank: 'Cpt',
      location: 'San Francisco. CA 123',
    },
  ],
};

export const NOTE_LIST = [
  {
    noteId: 'hjifvf6t87yy',
    date: '2021-02-26T22:49:56+00:00',
    subject: 'Title 1',
    description: `Lorem ipsum dolor sit amet, consectetur adipisicing elit.
    Adipisci aliquam cupiditate deleniti eligendi eos et exercitationem
    maiores nemo neque optio possimus provident quibusdam quidem,
    quis quos sunt temporibus velit voluptates!`,
  },
  {
    noteId: 'hjifvf6545656t87yy',
    date: '2021-02-26T22:49:56+00:00',
    subject: 'Title 2',
    description: `2 Lorem ipsum dolor sit amet, consectetur adipisicing elit.
    Adipisci aliquam cupiditate deleniti eligendi eos et exercitationem
    maiores nemo neque optio possimus provident quibusdam quidem,
    quis quos sunt temporibus velit voluptates!`,
  },
];

export const SUPPORT_LIST = [
  {
    id: 'hjifvf6t87yy',
    date: '2021-02-26T22:49:56+00:00',
    subject: 'Title 1',
    description: `Lorem ipsum dolor sit amet,
    consectetur adipisicing elit.`,
  },
  {
    id: 'hjifvf6545656t87yy',
    date: '2021-02-26T22:49:56+00:00',
    subject: 'Title 2',
    description: `2 Lorem ipsum dolor sit amet, consectetur adipisicing elit.
    Adipisci aliquam cupiditate deleniti eligendi eos et exercitationem
    maiores nemo neque optio possimus provident quibusdam quidem,
    quis quos sunt temporibus velit voluptates!`,
  },
];

export const ANALYTICAL_LIST_DATA = {
  cardiacStrain: 123,
  currentCardioVar: [
    {
      baselineHR: 72.8,
      meanBaselineHR: 73,
      time: 1614365586645,
    },
  ],
  baselineCardioVar: [
    {
      baselineHR: 72.8,
      meanBaselineHR: 73,
      time: 1614365586645,
    },
  ],
  fitO2CorCoeff: 98,
  fitO2Scatter: [
    {
      x: 98,
      y: 86.7,
    },
    {
      x: 97,
      y: 87,
    },
    {
      x: 90,
      y: 83,
    },
  ],
  fitO2Line: [
    {
      x: 86,
      y: 96,
    },
    {
      x: 100,
      y: 100,
    },
  ],
  fitHRCorCoeff: 39,
  fitHRScatter: [
    {
      x: 89,
      y: 98,
    },
    {
      x: 97,
      y: 87,
    },
    {
      x: 90,
      y: 83,
    },
  ],
  fitHRLine: [
    {
      x: 88,
      y: 93,
    },
    {
      x: 100,
      y: 100,
    },
  ],
};

export const GOOGLE_MAPS_API_KEY = 'AIzaSyDcoUElkD41X2gA18YRmgZG5cX1JVDo3Mc';

export const ROSTER_LIST_ON_MAP = {
  mapCenter: {
    lat: 25.774,
    lng: -80.19,
  },
  roster: [
    {
      id: 'drththr56668678',
      lat: 25.774,
      lng: -80.19,
      photo: 'https://static.ngs.ru/news/2020/99/preview/8290b39b6601efa615afd4bbd93d82320a039883_720_405_c.png',
      fullName: 'Eric Samules',
      rank: 'Cpt',
    },
    {
      id: 'drththr5sfsfwe6dgerg668678',
      lat: 25.775,
      lng: -80.19,
      photo: 'https://static.ngs.ru/news/2020/99/preview/8290b39b6601efa615afd4bbd93d82320a039883_720_405_c.png',
      fullName: 'Sara Ericson',
      rank: 'FF',
    },
    {
      id: 'drththr5sfffsfwe6dgerg668678',
      lat: 25.776,
      lng: -80.19,
      photo: 'https://static.ngs.ru/news/2020/99/preview/8290b39b6601efa615afd4bbd93d82320a039883_720_405_c.png',
      fullName: 'Sara Ericson',
      rank: 'LT',
    },
  ],
};
