<template>
  <span :class="`rui-badge${checkType(type)}`">{{ text }}</span>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiTools from '@/retinus-ui/core/RuiTools';

export default defineComponent({
  name: 'rui-badge',
  props: {
    text: String,
    type: String,
  },
  methods: {
    checkType(type: string) {
      return RuiTools.setColorStyle(type);
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'badge';
</style>
