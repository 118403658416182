import axios from 'axios';

import {BASE_URL, TOKEN_KEY} from '@/app/core/constants';
import {User} from '@/app/core/interfaces';

export default class AuthDataProvider {
  // TODO: mock data.
  // login(user: User): Promise<User> {
  //   return new Promise((resolve, reject) => {
  //     setTimeout(() => resolve({
  //       token: 'fvsegvfrigerlhgbrlesgr',
  //     }), 0);
  //   });
  // }
  async login(user: User): Promise<User> {
    const res = await axios.post<User>(`${BASE_URL}/login`, user);
    return res.data;
  }

  // TODO: mock data.
  resetPassword(email: {email: string}): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(), 1000);
    });
  }

  // TODO: mock data.
  newPassword(user: {password: string; token: string}): Promise<void> {
    // const res = await axios.post<User>(`${BASE_URL}`, userSignUp);
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(), 1000);
    });
  }

  setupToken(token?: string): void {
    localStorage.setItem(TOKEN_KEY, token || '');
    axios.defaults.headers.common.Authorization = token;
  }

  clearToken(): void {
    localStorage.removeItem(TOKEN_KEY);
    axios.defaults.headers.common.Authorization = '';
  }
}
