<template>
  <div class="rui-auth__wrapper">
    <a class="rui-auth__logo"
       :href="poweredBy.link"
       rel="noopener"
       target="_blank">
      <rui-icon icon="logo"/>
    </a>
    <form class="rui-auth">
      <h1 class="rui-auth__title">{{ title }}</h1>
      <slot name="body"/>
      <div class="rui-auth__footer">
        <a class="rui-link"
           :href="poweredBy.link"
           rel="noopener"
           target="_blank">{{ poweredBy.name }}</a>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

import {POWERED_BY} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'rui-auth-wrapper',
  components: {RuiIcon},
  props: {
    title: String,
  },
  data() {
    return {
      poweredBy: POWERED_BY,
    };
  },
});
</script>

<style lang="scss">
@import 'auth';
</style>
