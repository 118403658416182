<template>
  <header class="rui-header">
    <div class="rui-header__nav">
      <rui-button :buttonType="buttonTypeIcon"
                  :disabled="disableNavbar"
                  @clickFromButtonComponent="onToggleNavigation()">
        <template v-slot:icon>
          <rui-icon :icon="isOpenNavigation ? 'menu-close' : 'menu-open'"
                    class="rui-toggle__ico"/>
        </template>
      </rui-button>
      <router-link class="rui-header__logo"
                   v-if="$slots.logo"
                   exact
                   :title="logoLinkText"
                   to="/">
        <div class="rui-sidebar-ico">
          <slot name="logo"/>
        </div>
      </router-link>
    </div>

    <nav class="rui-header__nav"
         v-if="userPhotoMin">
      <div class="rui-header__user">
        <img :src=userPhotoMin
             alt="Profile Photo"/>
      </div>
      <rui-button v-if="logoutButton"
                  :buttonType="buttonTypeIcon"
                  @clickFromButtonComponent="logout()">
        <template v-slot:icon>
          <rui-icon icon="logout"/>
        </template>
      </rui-button>
      <!--      TODO: Feature.-->
      <!--      <router-link class="rui-header__user"-->
      <!--                   to="/profile">-->
      <!--        <img :src=userPhotoMin-->
      <!--             alt="Profile Photo"/>-->
      <!--      </router-link>-->
    </nav>
  </header>

  <rui-navigation :is-open-navigation="isOpenNavigation"
                  :navigationMenu="navigationMenu"
                  @click.prevent="away()"/>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiNavigation from '@/retinus-ui/components/layout/navigation/RuiNavigation.vue';
import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';
import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

import {BUTTON_TYPE, HOME} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'rui-header',
  emits: ['clickLogout'],
  components: {
    RuiNavigation,
    RuiButton,
    RuiIcon,
  },
  props: {
    navigationMenu: {
      type: Array,
      required: true,
    },
    userPhotoMin: {
      type: String,
    },
    disableNavbar: {
      type: Boolean,
      default: false,
    },
    logoutButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenNavigation: false,
      logoLinkText: HOME,
      buttonTypeIcon: BUTTON_TYPE.icon,
    };
  },
  methods: {
    onToggleNavigation() {
      this.isOpenNavigation = !this.isOpenNavigation;
    },

    away() {
      this.isOpenNavigation = false;
    },

    logout() {
      this.$emit('clickLogout');
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'header';
</style>
