export default class RuiModalTools {
  public static focusTrap() {
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.querySelector('.rui-modal'); // TODO: change to ID.

    if (modal) {
      const firstFocusableElement = modal.querySelectorAll(focusableElements)[0];
      const focusableContent = modal.querySelectorAll(focusableElements);
      const lastFocusableElement = focusableContent[focusableContent.length - 1];

      document.addEventListener('keydown', (e) => {
        const isTabPressed = e.key === 'Tab';

        if (!isTabPressed) {
          return;
        }

        // If shift key pressed for shift + tab combination.
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            (lastFocusableElement as HTMLElement).focus();
            e.preventDefault();
          }
          // If tab key is pressed.
        } else {
          // eslint-disable-next-line no-lonely-if
          if (document.activeElement === lastFocusableElement) {
            (firstFocusableElement as HTMLElement).focus();
            e.preventDefault();
          }
        }
      });
      (firstFocusableElement as HTMLElement).focus();
    }
  }
}
