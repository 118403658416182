<template>
  <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="22">
<path d="M0 0H22V22H0V0Z" fill="#2A2A2A"/>
</mask>
<g mask="url(#mask0)">
</g>
<g clip-path="url(#clip0)">
<path d="M20.9917 15.5833H18.7L20.2583 13.75C20.9 12.925 21.0833 12.5583 21.0833 12.1C21.0833 11.275 20.4417 10.6333 19.525 10.6333C18.5167 10.6333 17.875 11.275 17.875 12.0083C17.875 12.1916 17.9667 12.2833 18.15 12.2833C18.3333 12.2833 18.425 12.1916 18.425 12.0083C18.5167 11.4583 18.975 11.0916 19.525 11.0916C20.1667 11.0916 20.625 11.4583 20.625 12.1C20.625 12.4666 20.4417 12.8333 19.9833 13.3833L18.3333 15.3083C18.15 15.4916 18.0583 15.5833 18.0583 15.7666C18.0583 15.95 18.2417 16.0416 18.425 16.0416H20.9917C21.175 16.0416 21.2667 15.95 21.2667 15.7666C21.2667 15.675 21.175 15.5833 20.9917 15.5833Z" fill="white"/>
<path d="M6.41667 11.6417C6.325 11.9167 6.23333 12.1917 6.14167 12.4667C6.05 12.7417 5.86667 13.0167 5.68333 13.2C5.5 13.3833 5.31667 13.475 5.04167 13.6583C4.76667 13.75 4.4 13.75 3.94167 13.75C3.48333 13.75 3.025 13.6583 2.75 13.5667C2.38333 13.3833 2.10833 13.1083 1.83333 12.7417C1.65 12.4667 1.46667 12.1 1.28333 11.6417C1.19167 11.1833 1.1 10.725 1.1 10.175C1.1 9.625 1.19167 9.16667 1.28333 8.70833C1.46667 8.25 1.65 7.79167 1.83333 7.51667C2.10833 7.15 2.38333 6.96667 2.75 6.78333C3.11667 6.6 3.48333 6.50833 3.94167 6.50833C4.30833 6.50833 4.675 6.6 4.95 6.69167C5.13333 6.69167 5.31667 6.875 5.5 7.05833C5.68333 7.24167 5.86667 7.425 5.95833 7.7C6.05 7.975 6.23333 8.25 6.23333 8.61667V8.8L7.24167 8.525V8.34167C7.24167 7.975 7.15 7.60833 6.96667 7.24167C6.78333 6.875 6.6 6.6 6.325 6.325C6.05 6.05 5.68333 5.86667 5.31667 5.68333C4.85833 5.59167 4.4 5.5 3.85 5.5C3.39167 5.5 2.84167 5.59167 2.38333 5.775C1.925 5.95833 1.46667 6.23333 1.1 6.69167C0.733333 7.05833 0.458333 7.60833 0.275 8.15833C0.0916667 8.8 0 9.44167 0 10.175C0 10.9083 0.0916667 11.55 0.275 12.1917C0.458333 12.7417 0.733333 13.2 1.1 13.6583C1.46667 14.025 1.83333 14.3917 2.38333 14.575C2.84167 14.7583 3.39167 14.85 3.94167 14.85C4.49167 14.85 4.95 14.7583 5.40833 14.575C5.775 14.3917 6.14167 14.2083 6.41667 13.9333C6.69167 13.6583 6.96667 13.2917 7.15 12.925C7.33333 12.5583 7.425 12.1 7.51667 11.7333V11.55H6.41667V11.6417ZM6.78333 7.33333C6.69167 7.15 6.6 6.96667 6.50833 6.875C6.6 6.96667 6.69167 7.15 6.78333 7.33333ZM5.04167 13.75C4.95 13.8417 4.76667 13.9333 4.58333 13.9333C4.76667 13.9333 4.95 13.8417 5.04167 13.75C5.225 13.75 5.40833 13.6583 5.5 13.5667C5.40833 13.6583 5.225 13.75 5.04167 13.75Z" fill="white"/>
<path d="M15.7666 6.69167C15.3999 6.325 15.0333 6.05 14.4833 5.775C14.0249 5.59167 13.4749 5.5 12.9249 5.5C12.3749 5.5 11.8249 5.59167 11.3666 5.775C10.9083 5.95833 10.4499 6.23333 10.0833 6.69167C9.7166 7.05833 9.4416 7.60833 9.25827 8.15833C8.98327 8.8 8.8916 9.44167 8.8916 10.175C8.8916 10.9083 8.98327 11.55 9.1666 12.1917C9.34994 12.7417 9.62493 13.2917 9.9916 13.6583C10.3583 14.025 10.8166 14.3917 11.2749 14.575C11.7333 14.7583 12.2833 14.85 12.8333 14.85C13.3833 14.85 13.9333 14.7583 14.3916 14.575C14.8499 14.3917 15.3083 14.025 15.6749 13.6583C16.0416 13.2917 16.3166 12.7417 16.4999 12.1917C16.6833 11.6417 16.7749 10.9083 16.7749 10.175C16.7749 9.44167 16.6833 8.8 16.4999 8.15833C16.4083 7.51667 16.1333 7.05833 15.7666 6.69167ZM14.4833 5.95833C14.4833 5.95833 14.5749 5.95833 14.5749 6.05L14.4833 5.95833C14.2083 5.86667 14.0249 5.775 13.7499 5.775C13.9333 5.775 14.2083 5.86667 14.4833 5.95833ZM15.5833 11.6417C15.4916 12.1 15.3083 12.4667 15.0333 12.8333C14.7583 13.1083 14.4833 13.3833 14.1166 13.5667C13.8416 13.6583 13.3833 13.75 12.9249 13.75C12.4666 13.75 12.0083 13.6583 11.6416 13.475C11.2749 13.2917 10.9999 13.0167 10.7249 12.7417C10.4499 12.4667 10.2666 12.0083 10.1749 11.55C10.0833 11.0917 9.9916 10.6333 9.9916 10.0833C9.9916 9.53333 10.0833 9.075 10.1749 8.61667C10.2666 8.15833 10.4499 7.79167 10.7249 7.51667C10.9999 7.24167 11.2749 6.96667 11.6416 6.78333C12.0083 6.6 12.4666 6.50833 12.9249 6.50833C13.3833 6.50833 13.8416 6.6 14.1166 6.78333C14.4833 6.96667 14.7583 7.15 15.0333 7.51667C15.3083 7.79167 15.4916 8.25 15.5833 8.61667C15.6749 9.075 15.7666 9.53333 15.7666 10.0833C15.7666 10.6333 15.7666 11.1833 15.5833 11.6417Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="21.2667" height="10.5417" fill="white" transform="translate(0 5.5)"/>
</clipPath>
</defs>
</template>
