import {State} from '@/app/data-flow/vehicles/state';
import {ActionContext} from 'vuex';
import {MemberListItem} from '@/app/core/interfaces';
import {GetterTypesFromIncidentList} from '@/app/data-flow/incident/types';
import {GetterTypesFromVehicles} from '@/app/data-flow/vehicles/types';
import {IncidentListModuleKey} from '@/app/data-flow/incident';
import {VehiclesModuleKey} from '@/app/data-flow/vehicles';
import Sorter from '@/app/core/sorter';
import MemberListDataProvider from '@/app/data-flow/members/MemberListDataProvider';

const memberListDataProvider = new MemberListDataProvider();

/**
 * Get Current IncidentId.
 */
const getterIncidentIdKey = `${IncidentListModuleKey}/${GetterTypesFromIncidentList.CURRENT_INCIDENT_ID}`;
const getterVehicleListKey = `${VehiclesModuleKey}/${GetterTypesFromVehicles.VEHICLE_LIST}`;

// eslint-disable-next-line import/prefer-default-export
export const actions = {
  loadMemberList(context: ActionContext<State, unknown>): Promise<void> {
    context.commit('loadMemberList');

    const incidentId = context.rootGetters[getterIncidentIdKey];

    return memberListDataProvider.getMemberList(incidentId)
      .then((response) => {
        const data = Sorter.sortByRank([...response]);
        context.commit('loadMemberListSuccess', {data});
      })
      .catch((error) => {
        context.commit('loadMemberListFail', {error});
      });
  },

  assignMember(context: ActionContext<State, unknown>, {member, oldVehicleId}:
    {member: MemberListItem; oldVehicleId: string }): Promise<void> {
    context.commit('updateVehicleMember');

    const moveMember = {
      newVehicleId: member.vehicleId,
      oldVehicleId,
    };

    const incidentId = context.rootGetters[getterIncidentIdKey];
    const vehicleList = context.rootGetters[getterVehicleListKey];

    return memberListDataProvider.moveMember(member.id, moveMember, incidentId)
      .then(() => {
        context.commit('updateVehicleMemberSuccess');
        context.commit('addMemberIntoVehicleTeam', {member, vehicleList});
        context.commit('deleteMemberFromVehicleTeam', {memberId: member.id, vehicleId: oldVehicleId, vehicleList});
        context.commit('updateMemberList', {member, vehicleList});
      })
      .catch((error) => {
        context.commit('updateVehicleMemberFail', {error});
      });
  },

  updateVehicleMember(
    context: ActionContext<State, unknown>,
    {member, vehicleId}: {
      member: MemberListItem;
      vehicleId: string;
    },
  ): Promise<void> {
    context.commit('updateVehicleMember');

    const incidentId = context.rootGetters[getterIncidentIdKey];
    const vehicleList = context.rootGetters[getterVehicleListKey];

    return memberListDataProvider.updateMember(member, incidentId)
      .then(() => {
        context.commit('updateVehicleMemberSuccess');
        context.commit('updateVehicleTeamItem', {member, vehicleId, vehicleList});
        context.commit('updateMemberList', {member, vehicleList});
      })
      .catch((error) => {
        context.commit('updateVehicleMemberFail', {error});
      });
  },

  deleteMember(
    context: ActionContext<State, unknown>,
    {memberId, vehicleId}: {
      memberId: string;
      vehicleId: string;
    },
  ): Promise<void> {
    context.commit('deleteMember');

    const incidentId = context.rootGetters[getterIncidentIdKey];
    const vehicleList = context.rootGetters[getterVehicleListKey];

    return memberListDataProvider.deleteMember(memberId, incidentId)
      .then(() => {
        context.commit('deleteMemberSuccess');
        context.commit('deleteVehicleTeamItem', {memberId, vehicleId, vehicleList});
      })
      .catch((error) => {
        context.commit('deleteMemberFail', {error});
      });
  },

  addMember(context: ActionContext<State, unknown>, {vehicleName, vehicleId, fullName, rank}:
    { vehicleName: string; vehicleId: string; fullName: string; rank: string }) {
    const newMember = {
      vehicleName,
      vehicleId,
      fullName,
      rank,
    };

    context.commit('addMember');

    const incidentId = context.rootGetters[getterIncidentIdKey];
    const vehicleList = context.rootGetters[getterVehicleListKey];

    memberListDataProvider.addMember(newMember, incidentId)
      .then((member) => {
        context.commit('addMemberSuccess', {member, vehicleList});
      })
      .catch((error) => {
        context.commit('addMemberFail', {error});
      });
  },
};
