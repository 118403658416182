<template>
  <div class="fire-avatar">
    <img class="fire-avatar__img"
         :class="{'fire-img-big': bigPhoto}"
         alt="Photo"
         :src="photo"/>
    <fire-live-tracker-icon :liveTracker="online"/>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import FireLiveTrackerIcon from '@/app/components/live-tracker-icon/FireLiveTrackerIcon.vue';

export default defineComponent({
  name: 'fire-avatar',
  components: {
    FireLiveTrackerIcon,
  },
  props: {
    online: {
      type: Boolean,
      default: false,
    },
    photo: String,
    bigPhoto: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'avatar';
</style>
