
import {defineComponent} from 'vue';

import {UNASSIGNED} from '@/app/core/constants';

export default defineComponent({
  name: 'fire-assignment-label',
  props: {
    text: String,
  },
  data() {
    return {
      unassigned: UNASSIGNED as string,
    };
  },
});
