<template>
  <mask
    id="mask0"
    mask-type="alpha"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="20"
    height="20"
  >
    <path d="M0 0H20V20H0V0Z" fill="#2A2A2A" />
  </mask>
  <g mask="url(#mask0)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 15.4167C5 17.9433 7.05583 20 9.58333 20C12.1108 20 14.1667 17.9433 14.1667 15.4167C14.1667 14.055 13.5475 12.7492 12.5 11.8817V2.91667C12.5 1.30833 11.1917 0 9.58333 0C7.975 0 6.66667 1.30833 6.66667 2.91667V11.8817C5.61917 12.7492 5 14.055 5 15.4167ZM7.5 2.91667C7.5 1.76833 8.435 0.833333 9.58333 0.833333C10.7317 0.833333 11.6667 1.76833 11.6667 2.91667V12.0833C11.6667 12.2142 11.7283 12.3375 11.8325 12.4158C12.7725 13.1233 13.3333 14.245 13.3333 15.4167C13.3333 17.4842 11.6508 19.1667 9.58333 19.1667C7.51583 19.1667 5.83333 17.4842 5.83333 15.4167C5.83333 14.245 6.39417 13.1233 7.33417 12.4158C7.43833 12.3375 7.5 12.2142 7.5 12.0833V2.91667ZM7.50014 15.4166C7.50014 16.5657 8.43514 17.4999 9.58347 17.4999C10.7318 17.4999 11.6668 16.5657 11.6668 15.4166C11.6668 14.4099 10.9493 13.5682 9.99946 13.375V4.58337C9.99946 4.35337 9.81279 4.1667 9.58279 4.1667C9.35279 4.1667 9.16612 4.35337 9.16612 4.58337V13.3752C8.21694 13.569 7.50014 14.4104 7.50014 15.4166ZM9.58279 14.1667L9.57401 14.1666C8.88918 14.1717 8.33347 14.7306 8.33347 15.4166C8.33347 16.1057 8.8943 16.6666 9.58347 16.6666C10.2726 16.6666 10.8335 16.1057 10.8335 15.4166C10.8335 14.7302 10.2772 14.1712 9.5919 14.1666L9.58279 14.1667ZM14.5826 3.33339H16.2493C16.4793 3.33339 16.6659 3.14672 16.6659 2.91672C16.6659 2.68672 16.4793 2.50006 16.2493 2.50006H14.5826C14.3526 2.50006 14.1659 2.68672 14.1659 2.91672C14.1659 3.14672 14.3526 3.33339 14.5826 3.33339ZM16.2493 6.66679H14.5826C14.3526 6.66679 14.1659 6.48013 14.1659 6.25013C14.1659 6.02013 14.3526 5.83346 14.5826 5.83346H16.2493C16.4793 5.83346 16.6659 6.02013 16.6659 6.25013C16.6659 6.48013 16.4793 6.66679 16.2493 6.66679ZM14.5826 9.99997H16.2493C16.4793 9.99997 16.6659 9.81331 16.6659 9.58331C16.6659 9.35331 16.4793 9.16665 16.2493 9.16665H14.5826C14.3526 9.16665 14.1659 9.35331 14.1659 9.58331C14.1659 9.81331 14.3526 9.99997 14.5826 9.99997Z"
      fill="white"
    />
  </g>
</template>
