import {State} from '@/app/data-flow/vehicles/state';
import {ParState, VehicleListItem} from '@/app/core/interfaces';

// eslint-disable-next-line import/prefer-default-export
export const mutations = {
  updateVehiclePar(state: State) {
    state.parUpdateLoading = true;
    state.parUpdateError = undefined;
  },
  updateVehicleParSuccess(state: State, payload: { vehicleId: string; par: number }) {
    state.parUpdateLoading = false;
    state.parUpdateError = undefined;

    if (state.vehicleList) {
      const currentVehicleIndex = state.vehicleList.findIndex((v) => v.vehicleId === payload.vehicleId);
      state.vehicleList[currentVehicleIndex].par = payload.par;
    }
  },
  updateVehicleParFail(state: State, payload: { error: Error }) {
    state.parUpdateLoading = false;
    state.parUpdateError = payload.error;
  },

  loadVehicleList(state: State) {
    state.loading = true;
    state.error = undefined;
  },
  loadVehicleListSuccess(state: State, payload: { data: VehicleListItem[] }) {
    state.loading = false;
    state.error = undefined;
    state.vehicleList = payload.data;
  },
  loadVehicleListFail(state: State, payload: { error: Error }) {
    state.loading = false;
    state.error = payload.error;
    state.vehicleList = undefined;
  },

  updateVehicle(state: State) {
    state.vehicleUpdateLoading = true;
    state.vehicleUpdateError = undefined;
  },
  updateVehicleSuccess(state: State) {
    state.vehicleUpdateLoading = false;
    state.vehicleUpdateError = undefined;
  },
  updateVehicleFail(state: State, payload: { error: Error }) {
    state.vehicleUpdateLoading = false;
    state.vehicleUpdateError = payload.error;
  },

  deleteVehicle(state: State) {
    state.vehicleUpdateLoading = true;
    state.vehicleUpdateError = undefined;
  },
  deleteVehicleSuccess(state: State) {
    state.vehicleUpdateLoading = false;
    state.vehicleUpdateError = undefined;
  },
  deleteVehicleFail(state: State, payload: { error: Error }) {
    state.vehicleUpdateLoading = false;
    state.vehicleUpdateError = payload.error;
  },

  updateVehicleListItem(state: State, payload: { vehicle: VehicleListItem }) {
    if (state.vehicleList) {
      const currentVehicleIndex = state.vehicleList.findIndex((v) => v.vehicleId === payload.vehicle.vehicleId);
      state.vehicleList[currentVehicleIndex] = payload.vehicle;
    }
  },

  deleteVehicleListItem(state: State, payload: { vehicleId: string }) {
    state.vehicleList = state.vehicleList?.filter((v) => v.vehicleId !== payload.vehicleId);
  },

  setParState(state: State, payload: { parState: ParState }) {
    state.parState = payload.parState;
  },

  addVehicle(state: State) {
    state.loading = true;
  },
  addVehicleSuccess(state: State, payload: { vehicle: VehicleListItem }) {
    // eslint-disable-next-line no-multi-assign,no-param-reassign
    const newVehicle = {
      ...payload.vehicle,
      arrived: true,
    };
    if (state.vehicleList) {
      state.vehicleList.push(newVehicle);
    }

    state.loading = false;
  },
  addVehicleFail(state: State, payload: { error: Error }) {
    console.error('addVehicleFail', payload);
    state.loading = false;
  },

  onRemoveHighlightFromVehicleSuccess(state: State, payload: { vehicle: VehicleListItem }) {
    if (payload.vehicle?.hasOwnProperty('arrived')) {
      // eslint-disable-next-line no-param-reassign
      delete payload.vehicle.arrived;
    }
  },

  loadArrivedVehicle(state: State) {
    state.vehicleArrivedLoading = true;
    state.vehicleArrivedError = undefined;
  },
  loadArrivedVehicleSuccess(state: State) {
    state.vehicleArrivedLoading = false;
    state.vehicleArrivedError = undefined;
  },
  loadArrivedVehicleListFail(state: State, payload: { error: Error }) {
    state.vehicleArrivedLoading = false;
    state.vehicleArrivedError = payload.error;
  },
};
