
import {defineComponent} from 'vue';

import RuiButton from '@/retinus-ui/components/button/RuiButton.vue';

export default defineComponent({
  name: 'rui-tabs',
  props: {
    tabList: Array,
    activeTabClass: String,
  },
  components: {
    RuiButton,
  },
});
