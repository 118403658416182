
import {Options, Vue} from 'vue-class-component';

import {mapActions, mapGetters} from 'vuex';
import {IncidentListModuleKey} from '@/app/data-flow/incident';
import {VehiclesModuleKey} from '@/app/data-flow/vehicles';
import {GetterTypesFromIncidentList} from '@/app/data-flow/incident/types';
import {GetterTypesFromRosters, RostersModuleKey} from '@/app/data-flow/rosters';
import {MembersModuleKey} from '@/app/data-flow/members';
import {AuthModuleKey, GetterTypesFromAuth} from '@/app/data-flow/auth';
import {GeoModuleKey} from '@/app/data-flow/geo';
import {GetterTypes} from '@/app/data-flow/types';

import FireHeader from '@/app/components/layout/header/FireHeader.vue';
import FireSignIn from '@/app/components/auth/FireLogin.vue';
import {IncidentListItem} from '@/app/core/interfaces';
import * as _ from 'lodash';

@Options({
  components: {
    FireHeader,
    FireSignIn,
  },
  data() {
    return {
      loggedIn: this.loggedInState as string,
    };
  },
  computed: {
    ...mapGetters({
      routerParams: GetterTypes.GET_PARAMS,
    }),
    ...mapGetters(RostersModuleKey, {
      rosterListState: GetterTypesFromRosters.ROSTER_LIST,
    }),
    ...mapGetters(IncidentListModuleKey, {
      currentIncidentState: GetterTypesFromIncidentList.CURRENT_INCIDENT,
    }),
    ...mapGetters(AuthModuleKey, {
      loggedInState: GetterTypesFromAuth.LOGGED_IN_STATE,
    }),
  },
  methods: {
    ...mapActions(IncidentListModuleKey, ['loadIncidentCurrent']),
    ...mapActions(VehiclesModuleKey, ['loadVehicleList']),
    ...mapActions(MembersModuleKey, ['loadMemberList']),
    ...mapActions(RostersModuleKey, ['loadRosterList']),
    ...mapActions(GeoModuleKey, ['loadRosterListOnMap']),

    watchLoggedInState(val: string): void {
      this.loggedIn = val;

      if (this.loggedIn) {
        this.rosterTask();
        this.incidentTask();
      }
    },

    rosterTask() {
      setTimeout(() => {
        this.loadRosterList()
          .then(() => (this.loggedIn ? this.rosterTask() : null));
      }, 1000);
    },

    incidentTask() {
      setTimeout(() => {
        const old = this.currentIncidentState;
        this.loadIncidentCurrent()
          .then((newIncident: IncidentListItem) => {
            if (!_.isEqual(old, newIncident)) {
              return Promise.all([
                this.loadMemberList(),
                this.loadVehicleList(),
                this.loadRosterListOnMap(),
              ]);
            }

            return Promise.resolve();
          })
          .then(() => (this.loggedIn ? this.incidentTask() : null));
      }, 1000);
    },
  },
  watch: {
    loggedInState: 'watchLoggedInState',
  },
  beforeMount() {
    this.watchLoggedInState(this.loggedInState);
  },
})
export default class App extends Vue {
}
