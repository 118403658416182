<template>
  <div class="rui-dropdown"
       @click="toggleMenu()">
    <div class="rui-dropdown__label">
      <span>{{ optionValue ? optionValue : 'rank' }}</span>
      <rui-icon icon="arrow-down"
                class="rui-ico"
                :class="{ 'is-open': isOpen }"/>
    </div>
    <ul v-if="isOpen"
        class="rui-dropdown__menu">
      <li v-for="(option, index) in options"
          :key="index"
          :class="{ 'is-active': option === selectedOption }"
          @click="setCurrentSelectedOption(option)">
        {{ option }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

export default defineComponent({
  name: 'rui-dropdown',
  components: {RuiIcon},
  emits: ['selectOption'],
  props: {
    options: Array,
    selectedOption: String,
  },
  data() {
    return {
      isOpen: false,
      changedOption: '',
      optionValue: this.selectedOption,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },

    setCurrentSelectedOption(option: string) {
      this.optionValue = option;
      this.$emit('selectOption', option);
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'dropdown';
</style>
