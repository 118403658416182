<template>
  <div class="fire-card__wrapper">
    <!--bpm-->
    <fire-roster-health-card v-if="rosterHeartRate"
                             title="bpm"
                             :level="rosterMaxHeartRate"
                             icon="heartRate">
      <template v-slot:body>
        <heart-rate :currentHeartRate="rosterHeartRate"
                    :timestamp="rosterTimestamp"/>
      </template>
    </fire-roster-health-card>

    <!--oxygen-->
    <fire-roster-health-card v-if="rosterOxygen"
                             title="vomax"
                             :level="rosterMinOxygen"
                             icon="oxygen">
      <template v-slot:body>
        <oxygen :data="rosterOxygen"
                :timestamp="rosterTimestamp"/>
      </template>
    </fire-roster-health-card>

    <!--temp-->
    <fire-roster-health-card v-if="rosterTemp"
                             title="temp"
                             :level="rosterMaxTemp"
                             icon="temperature">
      <template v-slot:body>
        <temperature :data="rosterTemp"
                     :timestamp="rosterTimestamp"/>
      </template>
    </fire-roster-health-card>

    <!--scba-->
    <fire-roster-health-card v-if="roster.scba"
                             title="scba"
                             :level="roster.scba.oxygenLevel"
                             icon="scba">
      <template v-slot:body>
        <fire-scba-graph :roster="roster"/>
      </template>
    </fire-roster-health-card>

    <!--hazmat-->
    <fire-roster-health-card v-if="roster.hazmat"
                             title="hazmat"
                             :level="roster.hazmat"
                             icon="hazmat">
      <template v-slot:body>
        <!--TODO: delete after DEMO-->
        <fire-hazmat-graph class="fire-svg"/>
      </template>
    </fire-roster-health-card>

    <!--PPM-->
    <fire-roster-health-card v-if="roster.smoke"
                             title="ppm"
                             :level="+roster.smoke"
                             icon="smoke">
      <template v-slot:body>
        <CO2/>
      </template>
    </fire-roster-health-card>
  </div>

<!--  <fire-analytics @updateGraphics="updateGraphics"></fire-analytics>-->
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import {mapActions, mapGetters} from 'vuex';
import {GetterTypesFromRosters, RostersModuleKey} from '@/app/data-flow/rosters';
import {AnalyticsModuleKey} from '@/app/data-flow/analytic';

import {ALERT_TEXT} from '@/app/core/constants';

import HeartRate from '@/app/views/vital-watch/components/graphics/HeartRate.vue';
import Oxygen from '@/app/views/vital-watch/components/graphics/Oxygen.vue';
import Temperature from '@/app/views/vital-watch/components/graphics/Temperature.vue';
import CO2 from '@/app/views/vital-watch/components/graphics/CO2.vue';
import FireScbaGraph from '@/app/views/vital-watch/components/graphics/scba/FireScbaGraph.vue';
import FireHazmatGraph from '@/app/views/vital-watch/components/graphics/FireHazmatGraph.vue';
import FireRosterHealthCard from '@/app/views/vital-watch/components/roster-health/components/roster-health-card/FireRosterHealthCard.vue';

// import FireAnalytics from '@/app/views/vital-watch/roster-health/FireAnalytics.vue';

export default defineComponent({
  name: 'fire-roster-health',
  props: {
    roster: Object,
  },
  components: {
    // FireAnalytics,
    HeartRate,
    Oxygen,
    Temperature,
    CO2,
    FireHazmatGraph,
    FireScbaGraph,
    FireRosterHealthCard,
  },
  data() {
    return {
      message: ALERT_TEXT,
    };
  },
  computed: {
    ...mapGetters(RostersModuleKey, {
      rosterTemp: GetterTypesFromRosters.ROSTER_TEMP_LIST,
    }),
    ...mapGetters(RostersModuleKey, {
      rosterHeartRate: GetterTypesFromRosters.ROSTER_HEARTRATE_LIST,
    }),
    ...mapGetters(RostersModuleKey, {
      rosterOxygen: GetterTypesFromRosters.ROSTER_OXYGEN_LIST,
    }),
    ...mapGetters(RostersModuleKey, {
      rosterTimestamp: GetterTypesFromRosters.ROSTER_TIMESTAMP_LIST,
    }),
    ...mapGetters(RostersModuleKey, {
      rosterMaxHeartRate: GetterTypesFromRosters.ROSTER_MAX_HEARTRATE,
    }),
    ...mapGetters(RostersModuleKey, {
      rosterMaxTemp: GetterTypesFromRosters.ROSTER_MAX_TEMP,
    }),
    ...mapGetters(RostersModuleKey, {
      rosterMinOxygen: GetterTypesFromRosters.ROSTER_MIN_OXYGEN,
    }),
  },
  methods: {
    ...mapActions(AnalyticsModuleKey, ['loadAnalyticData']),

    updateGraphics() {
      this.loadAnalyticData({watchId: this.roster?.watchId});
    },
  },
});
</script>

<style lang="scss" scoped>
@import "roster-health";
</style>
