<template>
  <footer class="rui-footer">
    <a class="rui-footer__info"
       :href="poweredBy.link"
       target="_blank">
      <rui-icon icon="logo"
                class-name="rui-footer__logo"/>
      <span>{{ poweredBy.name }}</span>
    </a>
  </footer>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

import {POWERED_BY} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'rui-footer',
  components: {
    RuiIcon,
  },
  data() {
    return {
      poweredBy: POWERED_BY,
    };
  },
});
</script>

<style lang="scss" scoped>
@import 'footer';
</style>
