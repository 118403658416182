
import {defineComponent} from 'vue';

import RuiTools from '@/retinus-ui/core/RuiTools';

export default defineComponent({
  name: 'rui-notify',
  props: {
    text: Number,
    type: String,
    icon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    checkType(type: string) {
      return RuiTools.setColorStyle(type);
    },
  },
});
