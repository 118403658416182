import * as _ from 'lodash';

import {ActionContext} from 'vuex';
import {RosterOnMap} from '@/app/core/interfaces';
import {IncidentListModuleKey} from '@/app/data-flow/incident';
import {GetterTypesFromIncidentList} from '@/app/data-flow/incident/types';
import GeoDataProvider from '@/app/data-flow/geo/GeoDataProvider';

const geoDataProvider = new GeoDataProvider();

/**
 * Get Current IncidentId.
 */
const getterKey = `${IncidentListModuleKey}/${GetterTypesFromIncidentList.CURRENT_INCIDENT_ID}`;

export const GeoModuleKey = 'GeoModule';

export const GetterTypesFromGeo = {
  ROSTER_LIST_ON_MAP: 'ROSTER_LIST_ON_MAP',
  ROSTER_LIST_ON_MAP_LOADING_STATE: 'ROSTER_LIST_ON_MAP_LOADING_STATE',
  ROSTER_LIST_ON_MAP_ERROR_STATE: 'ROSTER_LIST_ON_MAP_ERROR_STATE',
};

export interface State {
  loading: boolean;
  error?: Error;
  rosterListOnMap?: RosterOnMap;
}

const initialState = (): State => ({
  loading: false,
  error: undefined,
  rosterListOnMap: undefined,
});

const getters = {
  [GetterTypesFromGeo.ROSTER_LIST_ON_MAP_LOADING_STATE](state: State) {
    return state.loading;
  },
  [GetterTypesFromGeo.ROSTER_LIST_ON_MAP_ERROR_STATE](state: State) {
    return state.error;
  },
  [GetterTypesFromGeo.ROSTER_LIST_ON_MAP](state: State) {
    return state.rosterListOnMap;
  },
};

const actions = {
  loadRosterListOnMap(context: ActionContext<State, unknown>) {
    context.commit('loadRosterListOnMap');

    const incidentId = context.rootGetters[getterKey];

    return geoDataProvider
      .getRostersMapData(incidentId)
      .then((data) => {
        context.commit('loadRosterListOnMapSuccess', {data});
      })
      .catch((error) => {
        context.commit('loadRosterListOnMapFail', {error});
        return error;
      });
  },
};

const mutations = {
  loadRosterListOnMap(state: State) {
    state.loading = true;
    state.error = undefined;
  },

  loadRosterListOnMapSuccess(state: State, payload: { data: RosterOnMap }) {
    state.loading = false;
    state.error = undefined;

    if (!_.isEqual(state.rosterListOnMap, payload.data)) {
      state.rosterListOnMap = payload.data;
    }
  },

  loadRosterListOnMapFail(state: State, payload: { error: Error }) {
    state.loading = false;
    state.error = payload.error;
    state.rosterListOnMap = undefined;
  },
};

export const GeoModule = {
  namespaced: true,
  getters,
  actions,
  mutations,
  state: initialState,
};
