
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';
import RuiFooter from '@/retinus-ui/components/layout/footer/RuiFooter.vue';

export default defineComponent({
  name: 'rui-navigation',
  props: ['navigationMenu', 'isOpenNavigation'],
  components: {
    RuiIcon,
    RuiFooter,
  },
  methods: {
    checkCurrentPath(path: string) {
      return this.$route.path.includes(path);
    },
    navigateToLink(link: string) {
      if (!this.checkCurrentPath(link)) {
        this.$router.push(link);
      }
    },
  },
});
