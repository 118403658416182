import { USER } from '@/app/core/mockData';

export const UserModuleKey = 'UserModule';

// TODO: redo everything to get a user object.
export const GetterTypesFromUser = {
  USER_PROFILE_PICTURE_MIN: 'USER_PROFILE_PICTURE_MIN',
  USER_PROFILE_PICTURE_BIG: 'USER_PROFILE_PICTURE_BIG',
  USER_PROFILE_ROLE: 'USER_PROFILE_ROLE',
  USER_PROFILE_NAME: 'USER_PROFILE_NAME',
};

export interface State {
  profileImgMin: unknown;
  profileImgBig: unknown;
  profileRole: string;
  profileName: string;
}

const initialState = (): State => ({
  profileImgMin: USER.imgMin,
  profileImgBig: USER.imgBig,
  profileRole: USER.role,
  profileName: USER.firstName,
});

const getters = {
  [GetterTypesFromUser.USER_PROFILE_PICTURE_MIN](state: State) {
    return state.profileImgMin;
  },
  [GetterTypesFromUser.USER_PROFILE_PICTURE_BIG](state: State) {
    return state.profileImgBig;
  },
  [GetterTypesFromUser.USER_PROFILE_ROLE](state: State) {
    return state.profileRole;
  },
  [GetterTypesFromUser.USER_PROFILE_NAME](state: State) {
    return state.profileName;
  },
};

export const UserModule = {
  namespaced: true,
  getters,
  state: initialState,
};
