
import {defineComponent} from 'vue';

import RuiIcon from '@/retinus-ui/components/icons/RuiIcon.vue';

import {POWERED_BY} from '@/retinus-ui/core/constants';

export default defineComponent({
  name: 'rui-footer',
  components: {
    RuiIcon,
  },
  data() {
    return {
      poweredBy: POWERED_BY,
    };
  },
});
