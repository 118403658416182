
import { defineComponent } from 'vue';

import OnSceneTools from '@/app/views/on-scene/OnSceneTools';

export default defineComponent({
  name: 'fire-member-list',
  emits: ['clickFromComponent'],
  props: {
    data: {
      type: Array,
      required: true,
    },
    vehicleId: {
      type: String,
      required: true,
    },
  },
  methods: {
    checkRank(rank: string) {
      return OnSceneTools.checkRank(rank);
    },
    onClick(value: string) {
      this.$emit('clickFromComponent', value);
    },
  },
});
